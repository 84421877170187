import React, { useEffect, useState } from "react";
import SIdebar from "../../../component/sidebar/SIdebar";
import Header from "../../../component/header/Header";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";

function AddListini() {
  let navigate = useNavigate();

  const [catalog, setCatalog] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { response, request } = useRequest();

  const { request: catalogRequest, response: catalogResponse } = useRequest();

  useEffect(() => {
    catalogRequest("GET", "catalog");
  }, []);

  useEffect(() => {
    if (catalogResponse) {
        setCatalog(
            catalogResponse.data?.catalog?.results.map((role) => {
          return {
            value: role.id_catalog,
            label: role.description,
          };
        })
      );
    }
  }, [catalogResponse]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success(response.message);
        navigate(-1);
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    request("POST", "add-price-lists", data);
  };

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">ANAGRAFICA</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" onClick={() => navigate(-1)}>
                    LISTINI
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" >
                  INSERISCI
                    </a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Content */}
          <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="pageContent">
              <div className="themeTabs my-4">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="data-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#data-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="data-tab-pane"
                      aria-selected="true"
                    >
                      DATI CONTATTI
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="data-tab-pane"
                    role="tabpanel"
                    aria-labelledby="data-tab"
                    tabIndex={0}
                  >
                    <div className="tabH-full">
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>catalogo</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="col-lg-7">
                                  <Controller
                                    control={control}
                                    name="id_catalog"
                                    rules={{ required: true, minLength: 1 }}  
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <Select
                                        // menuPosition="fixed"
                                        options={catalog}
                                        placeholder="Select Role"
                                        className={`basic-multi-select ${
                                          errors.id_catalog && "is-invalid"
                                        }`}
                                        classNamePrefix="select"
                                        onChange={(val) => onChange(val.value)}
                                      />
                                    )}
                                  />
                                  {errors.id_catalog?.type === "required" && (
                                    <div className="invalid-feedback">
                                      The catalogo field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Increase</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    pattern="^\d+(?:\.\d{1,2})?$"
                                    min="0" max="10000000000" step="0.25"
                                    className={`form-control ${
                                      errors.increase && "is-invalid"
                                    }`}
                                    name="increase"
                                    id="increase"
                                    {...register("increase", {
                                      required: true,
                                    })}
                                  />
                                  {errors.increase?.type === "required" && (
                                    <div className="invalid-feedback">
                                      The increase field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>locked price</label>
                              </div>
                              <div className="col-lg-7">
                              <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="locked_price"
                                    {...register("locked_price")}
                                  />
                             
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>active</label>
                              </div>
                              <div className="col-lg-7">
                              <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="active"
                                    {...register("active")}
                                  />
                            
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>price</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  pattern="^\d+(?:\.\d{1,2})?$"
                                  min="0" max="10000000000" step="0.25"
                                  type="number"
                                  className={`form-control ${
                                    errors.price && "is-invalid"
                                  }`}
                                  name=""
                                  id="price"
                                  {...register("price", {
                                    required: true,
                                  })}
                                />
                                {errors.price?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The price field is required.
                                  </div>
                                )}
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>description</label>
                              </div>
                              <div className="col-lg-7">
                                <textarea
                                  className={`form-control ${
                                    errors.description && "is-invalid"
                                  }`}
                                  {...register("description", {
                                    required: true,
                                  })}
                                  cols="30"
                                  rows="5"
                                ></textarea>
                                {errors.description?.type === "required" && (
                                  <div className="invalid-feedback">
                                    the description field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-md-4 mt-3 text-center text-md-start">
                      <button className="btn btn-pill btn-yellow">SALVA</button>
                      <button
                        className="btn btn-pill btn-blue ms-2 ms-md-3"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        ANNULLA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddListini;
