import React from "react";
import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
const CUSTOMERROUTES = [
  "/clienti",
  "/puntovendita",
  "/addClienti",
  "/editClienti",
  "/puntovendita/add",
  "/puntovendita/edit/:id",
  "/articoli",
  "/articoli/add",
  "/articoli/edit",
  "/catalogo",
  "/catalogo/add",
  "/catalogo/edit",
  "/listini",
  "/listini/add",
  "/listini/edit",
  "/stampaCatalogo",
  "/stampacatalogofile",
  "/fornitori",
  "/addfornitori",
  "/fornitori/edit",
  "/fornitori/contatti/editcontatti",
  "/agents",
  "/agents/edit",
  "/agents/add",
  "/agents/contatti/addcontatti",
  "/agents/contatti/editcontatti",
  "/gruppiprodotto",
  "/categorie",
];
const Magazzino = ["/riordino", "/riordino/add"];

const AMMINISTRAZIONE = [
  "/ordinidivendita", 
  "/ordinidivenditaelenco",
  "/ordinidivenditaelenco2",
  "/documentiattivi",
  "/documentiattiviadd",
  "/documentiattiviedit",
  "/documentopassivo/add",
  "/documentopassivo",
  "/documentopassivo/Edit"

];


function Header({ setuserID }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const { pathname } = useLocation();

  const [warehouse, setWrehouse] = useState([]);
  const [warehousesID, setWrehouesID] = useState();
  const [warehouseslabel, setWrehoueslabel] = useState();
  const { request: warehouseRequest, response: warehouseResponse } =
    useRequest();
  const { request, response } = useRequest();
  useEffect(() => {
    warehouseRequest("GET", "get-all-warehouse");
    request("GET", "user-data");
  }, []);


  useEffect(() => {
    if (response) {
      setWrehouesID(response.data?.department?.warehouse.id_warehouse);
      setWrehoueslabel(response.data?.department?.warehouse.warehouse_name);
    }
  }, [response]);
  
    useEffect(() => {
    if (warehouseResponse) {
      setWrehouse(
        warehouseResponse.data?.map((role) => {
          return {
            value: role.warehouse_code,
            label: role.warehouse_name,
          };
        })
      );
    }
  }, [warehouseResponse]);
  const [toggleNavbar, setToggleNavbar] = useState(false);

  useEffect(() => {
    if (toggleNavbar) {
      document.body.classList.add("scroll-off");
    } else {
      document.body.classList.remove("scroll-off");
    }
  }, [toggleNavbar]);

  return (
    <>
      <div
        className="overlay"
        style={toggleNavbar ? null : { display: "none" }}
        onClick={() => setToggleNavbar(!toggleNavbar)}
      ></div>
      <header id="header">
        <nav className="navbar navbar-expand-lg p-0">
          <a className="navbar-brand" href="/">
            <img src="/img/logo.png" alt="" />
          </a>

          <div className="selectHead">
            <Select
              menuPosition="fixed"
              value={{ label: warehouseslabel, value: warehouseslabel }}
              options={warehouse}
              classNamePrefix="select"
              onChange={(val) => {
                setuserID(val.value);
                setWrehoueslabel(val.label);
              }}
            />
          </div>

          <button
            className={
              toggleNavbar ? `navbar-toggler menu-opened` : `navbar-toggler`
            }
            onClick={() => setToggleNavbar(!toggleNavbar)}
            type="button"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={
              toggleNavbar
                ? `collapse navbar-collapse menu-show`
                : `collapse navbar-collapse`
            }
          >
            <ul
              className={
                pathname === "/clienti" ||
                "/addClienti" ||
                "/editClienti" ||
                "/puntovendita" ||
                "/puntovendita/add" ||
                "/puntovendita/edit/:id" ||
                "/catalogo" ||
                "/articoli" ||
                "/articoli/add" ||
                "/articoli/edit" ||
                "/catalogo/add" ||
                "/catalogo/edit" ||
                "/listini" ||
                "/listini/add" ||
                "/listini/edit" ||
                "/stampaCatalogo" ||
                "/stampacatalogofile" ||
                "/fornitori" ||
                "/addfornitori" ||
                "/addfornitori" ||
                "/fornitori/edit" ||
                "/fornitori/contatti/editcontatti" ||
                "/agents" ||
                "/agents/edit" ||
                "/agents/add" ||
                "/agents/contatti/addcontatti" ||
                "/agents/contatti/editcontatti" ||
                "/gruppiprodotto" ||
                "/categorie" ||
                " /riordino"
                  ? "navbar-nav ms-md-auto   "
                  : ""
              }
            >
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 27.04 27.29"
                    >
                      <path
                        fill="currentColor"
                        d="M27.04,14.81c.04,6.86-5.48,12.45-12.34,12.49S2.26,21.81,2.22,14.95c0-.54,.03-1.08,.1-1.62,.4-2.62-.02-2.2,6.52-.24,2.02,.6,5.84,1.71,5.84,1.71,.12,0-2.37-10.12-2.79-11.54-.26-.9,1.81-.83,2.79-.83,6.83,0,12.37,5.54,12.37,12.37h0Z"
                      />
                      <path
                        fill="currentColor"
                        d="M24.55,14.82c.03,5.48-4.38,9.94-9.85,9.98-5.48,.03-9.94-4.38-9.98-9.85,0-.43,.02-.87,.08-1.3,.32-2.09-.01-1.76,5.2-.19,1.61,.49,4.67,1.37,4.67,1.37,.1,0-1.89-8.08-2.23-9.21-.21-.72,1.44-.66,2.23-.66,5.46,0,9.88,4.42,9.88,9.88h0Z"
                      />
                      <path
                        fill="currentColor"
                        d="M.34,8.51l11.25,3.48c.08,.02,.16-.02,.18-.1,0-.02,0-.05,0-.07L9.02,.39c-.07-.27-.34-.44-.61-.38h-.04C4.52,1.23,1.45,4.14,.03,7.9c-.09,.25,.05,.52,.3,.6h.02Z"
                      />
                      <path
                        fill="currentColor"
                        d="M2.93,9.22l8.7,2.68c.06,.02,.12-.01,.14-.07,0-.02,0-.04,0-.06L9.64,2.93c-.05-.21-.26-.34-.47-.29h-.03c-2.97,.94-5.34,3.19-6.45,6.1-.07,.19,.04,.4,.23,.47h0Z"
                      />
                      <circle
                        fill="currentColor"
                        cx="14.62"
                        cy="21.56"
                        r="1.27"
                      />
                      <circle
                        fill="currentColor"
                        cx="19.89"
                        cy="12.29"
                        r="1.27"
                      />
                      <circle
                        fill="currentColor"
                        cx="7.96"
                        cy="7.59"
                        r="1.27"
                      />
                      <path
                        fill="currentColor"
                        d="M18.36,20.29c0,.16,.06,.32,.17,.44,.21,.22,.27,.06,.94,.06"
                      />
                      <path
                        fill="currentColor"
                        d="M20.6,17.87c.16,0,.32-.06,.45-.17,.22-.21,.05-.28,.05-.94"
                      />
                      <path
                        fill="currentColor"
                        d="M17.33,15.69c.08,0,.52,.04,.45-.17-.17-.49,.05-.28,.05-.94"
                      />
                      <path
                        fill="currentColor"
                        d="M9.79,14.58c0,.16,.06,.32,.16,.44,.21,.22,1.15,.12,1.82,.12"
                      />
                      <path
                        fill="currentColor"
                        d="M11.82,18.21c.2,0,.4-.05,.57-.17,.35-.33,.53-.81,.47-1.28"
                      />
                      <path
                        fill="currentColor"
                        d="M7.82,18.58c.12,0,.8,.05,.7-.22-.26-.62,.09-.35,.09-1.21"
                      />
                      <path
                        fill="currentColor"
                        d="M16.49,6.87c0,.16,.06,.32,.16,.44,.21,.22,.27,.06,.94,.06"
                      />
                      <path
                        fill="currentColor"
                        d="M19.45,9.02c.08,0,.12,.35,.28,.2,.22-.21,.22-.65,.22-1.31"
                      />
                      <path
                        fill="currentColor"
                        d="M15.13,9.78c.08,0,.51,.04,.45-.17-.16-.49,.05-.27,.05-.94"
                      />
                      <path
                        fill="currentColor"
                        d="M7.28,4.07c0,.16,.06,.32,.16,.44,.21,.22,.27,.06,.94,.06"
                      />
                      <path
                        fill="currentColor"
                        d="M4.98,7.42c.08,0,.51,.04,.45-.17-.16-.49,.05-.27,.05-.94"
                      />
                    </svg>
                  </span>
                  LABORATORIO
                </a>
              </li>

              <li className={CUSTOMERROUTES.includes(pathname) ? "active" : ""}>
                <a
                  // className={
                  //   CUSTOMERROUTES.includes(pathname) ? "active" : ""
                  // }
                  href="/clienti"
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 21.09 25.31"
                    >
                      <path
                        fill="currentColor"
                        d="M18.98,0H2.11V2.11H18.98V0ZM2.11,25.31H18.98v-2.11H2.11v2.11ZM18.98,4.22H2.11c-1.16,0-2.11,.95-2.11,2.11v12.65c0,1.16,.95,2.11,2.11,2.11H18.98c1.16,0,2.11-.95,2.11-2.11V6.33c0-1.16-.95-2.11-2.11-2.11Zm-8.44,2.9c1.31,0,2.37,1.06,2.37,2.37s-1.06,2.37-2.37,2.37-2.37-1.06-2.37-2.37h0c0-1.31,1.06-2.37,2.37-2.37Zm5.27,10.81H5.27v-1.58c0-1.76,3.51-2.64,5.27-2.64s5.27,.88,5.27,2.64v1.58Z"
                      />
                    </svg>
                  </span>
                  ANAGRAFICA
                </a>
              </li>
              <li
                className={
                  Magazzino.includes(pathname) ? " nav item active" : ""
                }
              >
                <a className="nav-link " href="/riordino">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 19.05 25.4"
                    >
                      <path
                        fill="currentColor"
                        d="M19.05,9.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.47h-3.17V1.59C15.87,.71,15.16,0,14.29,0H4.76c-.88,0-1.59,.71-1.59,1.59v1.59H0c0,2.01,1.28,3.8,3.17,4.47v1.88H0c0,2.01,1.27,3.81,3.17,4.48v1.88H0c0,2.07,1.35,3.9,3.32,4.52,.74,3.42,4.12,5.6,7.54,4.86,2.43-.53,4.33-2.43,4.86-4.86,1.97-.62,3.32-2.45,3.32-4.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.48Zm-9.52,11.11c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Z"
                      />
                    </svg>
                  </span>
                  MAGAZZINO
                </a>
              </li>

              <li   className={
                  AMMINISTRAZIONE.includes(pathname) ? " nav item active" : ""
                }>
                <a className="nav-link" href="/ordinidivendita">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 19.05 25.4"
                    >
                      <path
                        fill="currentColor"
                        d="M19.05,9.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.47h-3.17V1.59C15.87,.71,15.16,0,14.29,0H4.76c-.88,0-1.59,.71-1.59,1.59v1.59H0c0,2.01,1.28,3.8,3.17,4.47v1.88H0c0,2.01,1.27,3.81,3.17,4.48v1.88H0c0,2.07,1.35,3.9,3.32,4.52,.74,3.42,4.12,5.6,7.54,4.86,2.43-.53,4.33-2.43,4.86-4.86,1.97-.62,3.32-2.45,3.32-4.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.48Zm-9.52,11.11c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Z"
                      />
                    </svg>
                  </span>
                  AMMINISTRAZIONE
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#!">
                  <span className="icon">
                    <svg viewBox="0 0 31.74 21.17">
                      <path
                        fill="currentColor"
                        d="M31.56,9.78C28.59,3.83,22.53,.05,15.87,0,9.22,.05,3.16,3.83,.19,9.78c-.26,.51-.26,1.1,0,1.61,2.97,5.95,9.03,9.73,15.68,9.78,6.65-.05,12.71-3.83,15.68-9.78,.25-.5,.24-1.1,0-1.61Zm-15.68,8.74c-4.38,0-7.94-3.55-7.94-7.94,0-4.38,3.55-7.94,7.94-7.94,4.38,0,7.94,3.55,7.94,7.94s-3.55,7.94-7.94,7.94h0Zm0-13.23c-.47,0-.94,.08-1.4,.21,.86,1.17,.61,2.82-.56,3.69-.93,.68-2.2,.68-3.13,0-.76,2.81,.9,5.71,3.72,6.48,2.81,.76,5.71-.9,6.48-3.72,.76-2.81-.9-5.71-3.72-6.48-.45-.12-.92-.18-1.39-.18h0Z"
                      />
                    </svg>
                  </span>
                  CONTROLLO
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#!">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                      />
                    </svg>
                  </span>
                  CONFIGURAZIONE
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
