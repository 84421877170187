import React, { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import swal from "sweetalert";

const Areamanager = () => {
  const [ aream, setAream]=useState([]);
  const [show, setShow] = useState(false);
  const [agent, setagent] = useState([]);
  const [comm, setComm] = useState([]);
  const [id_agent, setId_agent] = useState('');
  const [ id_area_comm, setId_area_comm] = useState('');
  
  const { request, response } = useRequest();
  const { request: agentRequest, response: agentResponse } = useRequest();
  const { request: commRequest, response: commResponse } = useRequest();
  const { request: modalRequest, response: modalResponse } = useRequest();
  const { request: deleteRequest, response: deleteResponse } = useRequest();
  useEffect(() => {
    request("GET", "get-all-area-manager");
    agentRequest("GET", "get-agent-area-manager");
    commRequest("GET", "get-area-area-manager");
  }, []);

    useEffect(() => {
      if (response) {
          setAream(response.data.areaManagerData);
      }},[response])
  
  useEffect(() => {
    if (modalResponse) {
      if (modalResponse.status === "SUCCESS") {
        toast.success(modalResponse.message);
        setShow(false)
        setId_agent('')
        setId_area_comm('')
        request("GET", "get-all-area-manager");
      } else {
        toast.error(modalResponse.message);
        setShow(true)
      }
    }
  }, [modalResponse]);

  useEffect(() => {
    if (agentResponse) {
      setagent(
        agentResponse.data?.agentData?.map((c) => {
          return {
            value: c.id_agent,
            label: c.naming,
          };
        })
      );
    }
  }, [agentResponse]);

  useEffect(() => {
    if (commResponse) {
      setComm(
        commResponse.data?.CommercialAreaData?.map((c) => {
          return {
            value: c.id_area_comm,
            label: c.description,
          };
        })
      );
    }
  }, [commResponse]);

  useEffect(() => {
    if (deleteResponse) {
        request("GET", "get-all-area-manager");
    }},[deleteResponse])

  const deleteHandler = (area) => {
    // deleteRequest("GET", `delete-area-manager/${area.id_agent}`)
      swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this ORARI data!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
       deleteRequest("GET", `delete-area-manager/${area.id_agent}`)

      swal("Poof! Your ORARI data has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Your ORARI data is safe!");
    }
  });
    request("GET", "get-all-area-manager");
  };


  // swal({
  //   title: "Are you sure?",
  //   text: "Once deleted, you will not be able to recover this ORARI data!",
  //   icon: "warning",
  //   buttons: true,
  //   dangerMode: true,
  // }).then((willDelete) => {
  //   if (willDelete) {
  //      deleteRequest("GET", `delete-area-manager/${area.id_agent}`)

  //     swal("Poof! Your ORARI data has been deleted!", {
  //       icon: "success",
  //     });
  //   } else {
  //     swal("Your ORARI data is safe!");
  //   }
  // });

  
  const onModalSubmit = (e) => {
    e.preventDefault();
    modalRequest("POST","add-area-manager",{id_agent,id_area_comm})
  };

  return (
    <div
      className="tab-pane fade"
      id="general-tab-pane"
      role="tabpanel"
      aria-labelledby="general-tab"
      tabIndex={0}
    >
      <button
        className="btn btn-sm btn-primary ms-3"
        onClick={() => setShow(true)}
      >Add</button>

      <div className="themeTable customerTable">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              {/* <th>ID</th> */}
              <th>Nome Dell'agente</th>
              <th>Area Commerciale</th>   
              <th />
            </tr>
          </thead>
          <tbody>
            {aream.length > 0 &&
              aream.map((area) => (
                <tr 
                key={area?.id_agent}
                >
                  <td data-th="name">{area.agentData.naming}</td>
                  <td data-th="surname">{area.commercialAreaData.description}</td>
                  <td data-th="ACTION">
                      <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={() => deleteHandler(area)}
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={onModalSubmit}>
          <Modal.Header>
            <Modal.Title>generale</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-3 ">
                      <label>agent</label>
                    </div>
                    <div className="col-lg-7">
                      <Select
                        options={agent}
                        name='id_agent'
                        placeholder="Description"
                        className={`basic-multi-select `}
                        classNamePrefix="select"
                        onChange={(e) => setId_agent(e.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-3 ">
                      <label>commercial Area </label>
                    </div>
                    <div className="col-lg-7">
                      <Select
                        options={comm}
                        name='id_area_comm'
                        placeholder="Description"
                        className={`basic-multi-select `}
                        classNamePrefix="select"
                        onChange={(e) => setId_area_comm(e.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-pill btn-yellow">SALVA</button>
            <button
              className="btn btn-pill btn-blue ms-2 ms-md-3"
              onClick={(e) => {
                e.preventDefault();
                setShow(false);
              }}
            >
              ANNULLA
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default Areamanager;
