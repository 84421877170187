import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AmministrazioneSideBar from "../../../component/sidebar/AmministrazioneSideBar";
import Header from "../../../component/header/Header";
import Pagination from "../../../component/Pagination/Pagination";
import axios from "axios";
import { toast } from "react-toastify";
import useRequest from "../../../hooks/useRequest";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Moment from "moment";
import { useForm, Controller } from "react-hook-form";

const AddDocumentiattivi = () => {
  let navigate = useNavigate();

  const [vatDate, setVatDate] = useState(new Date());
  const [agents, setAgents] = useState([]);

  const [doctype, setdoctype] = useState([]);
  const [paymenttypes, setpaymenttypes] = useState([]);
  const [sellingorder, setsellingorder] = useState([]);


  const { request, response } = useRequest();
  const { request: requestAgent, response: responseAgent } = useRequest();
  const { request: requestDoctype, response: responseDoctype } = useRequest();
  const { request: requestpaymenttypes, response: responsepaymenttypes } =
    useRequest();
  const { request: requestSellingorder, response: responseSellingorder } =
    useRequest();
  const { request: requestDelivery, response: responseDelivery } = useRequest();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValue,
    reset,
  } = useForm();

  useEffect(() => {
    requestpaymenttypes("GET", "payment-types");
    requestSellingorder("GET", "selling-orders");
    requestDoctype("GET", "doc-type")
  }, []);


  useEffect(() => {
    if (responseDoctype) {
      setdoctype(
        responseDoctype.data?.docs?.map((doc) => {
          return {  
            value: doc.id_doc_type,
            label: doc.descrizione,
          };
        })
      );
    }
  }, [responseDoctype]);

  useEffect(() => {
    if (responsepaymenttypes) {
      if(Object.keys(responsepaymenttypes?.data).length>0){
        setpaymenttypes(
          responsepaymenttypes.data?.map((pay) => {
            return {
              value: pay.id_payment_type,
              label: pay.descrizione,
            };
          })
        );
      }
    }
  }, [responsepaymenttypes]);

  useEffect(() => {
    if (responseSellingorder) {
      if(responseSellingorder.status=="SUCCESS" ){
        setsellingorder(
          responseSellingorder.data?.map((sell) => {
            return {
              value: sell.id_selling_order,
              label: sell.id_selling_order,
            };
          })
        );
      }
     
    }
  }, [responseSellingorder]);


  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success(response.message);
        navigate("/documentopassivo");
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  const onSubmit = (data) => {

    request("POST", "add-passive-document", data);
  };

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <AmministrazioneSideBar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">AMMINISTRAZIONE</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">CICLO ATTIVO</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/documentiattivi">DOCUMENTI ATTIVI</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">ADD DOCUMENTI ATTIVI</a>
                  </li>
                  {/* <li className="breadcrumb-item active" aria-current="page">
                    SLM00001
                  </li> */}
                </ol>
              </nav>
            </div>
          </div>

          <div className="pageContent">
            <div className="themeTabs my-4">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="data-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#data-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="data-tab-pane"
                    aria-selected="true"
                  >
                    Nuovo documento attivo
                  </button>
                </li>
              </ul>

              <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                <div className="tabH-full">
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Stato</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.state && "is-invalid"
                              }`}
                              name="state"
                              id="state"
                              {...register("state", {
                                required: true,
                              })}
                            />
                            {errors.state?.type === "required" && (
                              <div className="invalid-feedback">
                                Stato field is required.
                              </div>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Tipo di Pagamento</label>
                          </div>
                          <div className="col-lg-7">
                            <Controller
                              control={control}
                              rules={{ required: true, minLength: 1 }}
                              name="id_payment_type"
                              render={({ field: { onChange, value, ref } }) => (
                                <Select
                                  // menuPosition="fixed"
                                  options={paymenttypes}
                                  classNamePrefix="select"
                                  placeholder="Select"
                                  className={`basic-multi-select ${
                                    errors.id_payment_type && "is-invalid"
                                  }`}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                />
                              )}
                            />
                            {errors.id_payment_type?.type === "required" && (
                              <div className="invalid-feedback">
                                Modalità Di Pagamento field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Numero del documento</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.number && "is-invalid"
                              }`}
                              name=" number"
                              id=" number"
                              {...register("number", {
                                required: true,
                              })}
                            />
                            {errors.number?.type === "required" && (
                              <div className="invalid-feedback">
                                Numero Del Documento field is required.
                              </div>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Tipo Di Documento</label>
                          </div>
                          <div className="col-lg-7">
                            <Controller
                              control={control}
                              rules={{ required: true, minLength: 1 }}
                              name="id_doc_type"
                              render={({ field: { onChange, value, ref } }) => (
                                <Select
                                  // menuPosition="fixed"
                                  options={doctype}
                                  classNamePrefix="select"
                                  placeholder="Select"
                                  className={`basic-multi-select ${
                                    errors.id_provider && "is-invalid"
                                  }`}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                />
                              )}
                            />
                            {errors.id_doc_type?.type === "required" && (
                              <div className="invalid-feedback">
                                Seleziona un PV field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
               
                  
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Documento Di Nota</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.note_document && "is-invalid"
                              }`}
                              name="note_document"
                              id="note_document"
                              {...register("note_document", {
                                required: true,
                              })}
                            />
                            {errors.note_document?.type === "required" && (
                              <div className="invalid-feedback">
                                Documento Di Nota field is required.
                              </div>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {errors.recap_notes?.type === "required" && (
                              <div className="invalid-feedback">
                                Note Di Riepilogofield is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Programma note</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.note_timetable && "is-invalid"
                              }`}
                              name="note_timetable"
                              id="note_timetable"
                              {...register("note_timetable", {
                                required: true,
                              })}
                            />
                            {errors.note_timetable?.type === "required" && (
                              <div className="invalid-feedback">
                               Programma note field is required.
                              </div>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Intrastat</label>
                          </div>
                          <div className="col-lg-7">
                          <input
                                  className="form-check-input checklg"
                                  type="checkbox"
                                  defaultValue=""
                                  id="intrastat"
                                  {...register("intrastat")}
                                />
                          
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Calcolo totale</label>
                          </div>
                          <div className="col-lg-7">
                          <input
                                  className="form-check-input checklg"
                                  type="checkbox"
                                  defaultValue=""
                                  id="total_calculation"
                                  {...register("total_calculation")}
                                />
                            {errors.total_calculation?.type === "required" && (
                              <div className="invalid-feedback">
                            Calcolo totale field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Totale Imponibile</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.taxable_total && "is-invalid"
                              }`}
                              step="0.01"
                              name="taxable_total"
                              id="taxable_total"
                              {...register("taxable_total", {
                                required: true,
                              })}
                            />
                            {errors.taxable_total?.type === "required" && (
                              <div className="invalid-feedback">
                               Totale Imponibile field is required.
                              </div>
                            )}
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label> Codice documento passivo</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.tax_total && "is-invalid"
                              }`}
                              name="tax_total"
                              id="passive_doc_code"
                              {...register("passive_doc_code", {
                                required: true,
                              })}
                            />
                            {errors.passive_doc_code?.type === "required" && (
                              <div className="invalid-feedback">
                            Codice documento passivo field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                  <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>
totale fiscale</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.tax_total && "is-invalid"
                              }`}
                              name="tax_total"
                              id="tax_total"
                              {...register("tax_total", {
                                required: true,
                              })}
                            />
                            {errors.tax_total?.type === "required" && (
                              <div className="invalid-feedback">
                            Totale Fiscale field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Pagare</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.to_pay && "is-invalid"
                              }`}
                              name="to_pay"
                              id="to_pay"
                              {...register("to_pay", {
                                required: true,
                              })}
                            />
                            {errors.to_pay?.type === "required" && (
                              <div className="invalid-feedback">
                                Pagare field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Assegnato</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.assigned && "is-invalid"
                              }`}
                              name="assigned"
                              id="assigned"
                              {...register("assigned", {
                                required: true,
                              })}
                            />
                            {errors.assigned?.type === "required" && (
                              <div className="invalid-feedback">
                                Assegnato field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>
nome del file</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.file_name && "is-invalid"
                              }`}
                              name="file_name"
                              id="file_name"
                              {...register("file_name", {
                                required: true,
                              })}
                            />
                            {errors.file_name?.type === "required" && (
                              <div className="invalid-feedback">
                               Nome Del File field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                
                
                </div>
                <div className="mt-md-4 mt-3 text-center text-md-start">
                  <button className="btn btn-pill btn-yellow">SALVA</button>
                  <button
                    className="btn btn-pill btn-blue ms-2 ms-md-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    ANNULLA
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDocumentiattivi;
