import React, { useEffect, useState } from "react";
import SIdebar from "../../../component/sidebar/SIdebar";
import Header from "../../../component/header/Header";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Orari from "./Orari";
import ChartOfAccount from "./ChartOfAccount";

function EditArticoli() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [showHeading, setShowHeading] = useState(0);
  const [provider, setProvider] = useState([]);
  const [show, setShow] = useState(false);
  const [descrizione, setdescrizione] = useState();
  const [tipo, setTipo] = useState();
  const [codice, setCodice] = useState(false);
  const [charts, setCharts] = useState([]);
  const [activeSelectId, setActiveSelectId] = useState(state.id_coa_active);
  const [passiveSelectId, setPassiveSelectId] = useState(state.id_coa_passive);


  const Id = state.id_article;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { response, request } = useRequest();

  const { response: providertimeResponse, request: providertimeRequest } =
    useRequest();
  const { request: countryRequest, response: countryResponse } = useRequest();
  const { request: providerRequest, response: providerResponse } = useRequest();
  const { request: listRequest, response: listResponse } = useRequest();
  const { request: addChartRequest, response: addChartResponse } = useRequest();
  const { request: chartsRequest, response: chartResponse } = useRequest();

  useEffect(() => {
    // providertimeRequest("GET", "provider-timetables");
    countryRequest("GET", "country");
    providerRequest("GET", "providers");
    chartsRequest("GET", "chart-of-accounts");

    reset({
      id_provider: state.provider?.id_provider,
      // id_coa_active: state.id_coa_active,
      // id_coa_passive: state.id_coa_passive,
      id_article: state.id_article,
      id_delivery_rules: state.id_delivery_rules,
      active: state.active,
      additional_costs: state.active,
      article_code: state.article_code,
      birth_country: state.birth_country,
      bred_country: state.bred_country,
      butchered_country: state.butchered_country,
      ce_stamp: state.ce_stamp,
      description: state.description,
      dissected_country: state.dissected_country,
      min_order: state.min_order,
      note_lab: state.note_lab,
      pieces_for_package: state.pieces_for_package,
      product_lab: state.product_lab,
      purchase_price: state.purchase_price,
      unite_measure: state.unite_measure,
      unite_measure_reorder: state.unite_measure_reorder,
    });
    setShowHeading(localStorage.getItem('active'))
  }, []);

  // useEffect(() => {
  //   if (providertimeResponse) {
  //       setProvidertime(
  //       providertimeResponse.data?.map((time) => {
  //         return {
  //           value: time.id_delivery_rules,
  //           label: time.s,
  //         };
  //       })
  //     );
  //   }
  // }, [providertimeResponse]);

  useEffect(() => {
    if (providerResponse) {
      setProvider(
        providerResponse.data?.results?.map((provider) => {
          return {
            value: provider.id_provider,
            label: provider.description,
          };
        })
      );
    }
  }, [providerResponse]);

  useEffect(() => {
    if (chartResponse) {
      setCharts(
        chartResponse.data?.map((list) => {
          return {
            value: list.idpianoconti,
            label: list.descrizione,
          };
        })
      );
    }
  }, [chartResponse]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success("Cliente aggiunto correttamente");
        navigate(-1);
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  useEffect(() => {
    if (addChartResponse) {
      if (addChartResponse.status === "SUCCESS") {
        toast.success("Elenco clienti aggiunto correttamente");
        setShow(false);
        chartsRequest("GET", "chart-of-accounts");
      } else {
        toast.error(response.message);
      }
    }
  }, [addChartResponse]);

  const onSubmit = (data) => {
    request("POST", "edit-article", {
      ...data,
      id_coa_active: activeSelectId,
      id_coa_passive: passiveSelectId,
    });
  };

  const onModelSubmit = (e) => {
    e.preventDefault();
    if (!descrizione || !tipo || !codice) {
      toast.error("All field are required.");
    } else {
      addChartRequest("POST", "add-chart-of-accounts", {
        descrizione,
        tipo,
        codice,
      });
    }
  };

  return (
    <>
      <Header />
      {/* Overlay */}
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">ANAGRAFICA</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">CATALOGO</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/articoli">ARTICOLI</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    SLM00001
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Content */}
          <div className="pageContent">
            <div className="themeTabs my-4">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      showHeading == 0 ? "nav-link  active" : "nav-link"
                    }
                    
                    id="data-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#data-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="data-tab-pane"
                    aria-selected="false"
                    onClick={()=>{
                      localStorage.setItem("active",0)
                    }}
                  >
                   DATI CLIENTE
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                     className={
                      showHeading == 1 ? "nav-link  active" : "nav-link"
                    }
                    id="point-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#point-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="point-tab-pane"
                    aria-selected="false"
                    onClick={()=>{
                      localStorage.setItem("active",1)
                    }}
                  >
                    ORARI
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                   className={
                    showHeading == 2 ? "nav-link  active" : "nav-link"
                  }
                    id="floor-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#floor-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="floor-tab-pane"
                    aria-selected="true"
                    onClick={()=>{
                      localStorage.setItem("active",2)
                    }}
                  >
                    Chart Of Account
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                className={
                  showHeading == 0
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                  id="data-tab-pane"
                  role="tabpanel"
                  aria-labelledby="data-tab"
                  tabIndex={0}
                >
                  <div className="tabH-full">
                    <form method="post" onSubmit={handleSubmit(onSubmit)}>
                      <div className="tabH-full">
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>fornitore</label>
                                </div>
                                <div className="col-lg-7">
                                  <Controller
                                    control={control}
                                    rules={{ required: true, minLength: 1 }}
                                    name="id_provider"
                                    render={({
                                      field: { onChange, value, ref },
                                    }) => (
                                      <Select
                                        value={provider.find(
                                          (c) => c.value === value
                                        )}
                                        // menuPosition="fixed"
                                        options={provider}
                                        classNamePrefix="select province"
                                        placeholder="select "
                                        className={`basic-multi-select ${
                                          errors.id_provider && "is-invalid"
                                        }`}
                                        onChange={(e) => onChange(e.value)}
                                      />
                                    )}
                                  />
                                  {errors.id_provider?.type === "required" && (
                                    <div className="invalid-feedback">
                                      The fornitore field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>CODICE ARTICOLI</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.article_code && "is-invalid"
                                    }`}
                                    name="article_code"
                                    id="article_code"
                                    {...register("article_code", {
                                      required: true,
                                    })}
                                  />
                                  {errors.article_code?.type === "required" && (
                                    <div className="invalid-feedback">
                                      CODICE ARTICOLI field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>misura unita</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.unite_measure && "is-invalid"
                                    }`}
                                    name="unite_measure"
                                    id="unite_measure"
                                    {...register("unite_measure", {
                                      required: true,
                                    })}
                                  />
                                  {errors.unite_measure?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      misura unita field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>paese macellato</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.butchered_country && "is-invalid"
                                    }`}
                                    name="butchered_country"
                                    id="butchered_country"
                                    {...register("butchered_country", {
                                      required: true,
                                    })}
                                  />
                                  {errors.butchered_country?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      paese macellato field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              {/* <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>coa active</label>
                                </div>
                                <div className="col-lg-7">
                                  <Controller
                                    control={control}
                                    name="id_coa_active"
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Select
                                        value={charts.find(
                                          (c) => c.value === value
                                        )}
                                        options={charts}
                                        placeholder="Select"
                                        className={`basic-multi-select `}
                                        classNamePrefix="select"
                                        onChange={(e) => onChange(e.value)}
                                      />
                                    )}
                                  />
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              {/* <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>coa passive</label>
                                </div>
                                <div className="col-lg-7">
                                  <Controller
                                    control={control}
                                    name="id_coa_passive"
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Select
                                        value={charts.find(
                                          (c) => c.value === value
                                        )}
                                        options={charts}
                                        placeholder="Select"
                                        className={`basic-multi-select `}
                                        classNamePrefix="select"
                                        onChange={(e) => onChange(e.value)}
                                      />
                                    )}
                                  />
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>ordine minimo</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      errors.min_order && "is-invalid"
                                    }`}
                                    name="min_order"
                                    id="min_order"
                                    {...register("min_order", {
                                      required: true,
                                    })}
                                  />
                                  {errors.min_order?.type === "required" && (
                                    <div className="invalid-feedback">
                                      ordine minimo field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label> paese sezionato</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.dissected_country && "is-invalid"
                                    }`}
                                    name="dissected_country"
                                    id="dissected_country"
                                    {...register("dissected_country", {
                                      required: true,
                                    })}
                                  />
                                  {errors.dissected_country?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      paese sezionato field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          {/* <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>regole di consegna</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_delivery_rules"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                    
                                      value={providertime.find(
                                        (c) => console.log(c.value) === value
                                      )}
                                      options={providertime}
                                      classNamePrefix="select"
                                      placeholder="Select"
                                      className={`basic-multi-select ${
                                        errors.id_province && "is-invalid"
                                      }`}
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.id_delivery_rules?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The regione field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>costi aggiuntivi</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="PRIVATO"
                                      {...register("additional_costs")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="PRIVATO"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>prezzo d'acquisto</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    step="0.01"
                                    className={`form-control ${
                                      errors.purchase_price && "is-invalid"
                                    }`}
                                    name="purchase_price"
                                    id="purchase_price"
                                    {...register("purchase_price", {
                                      required: true,
                                    })}
                                  />
                                  {errors.purchase_price?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      prezzo d'acquisto field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>pezzi per confezione</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      errors.pieces_for_package && "is-invalid"
                                    }`}
                                    name=""
                                    id="street"
                                    {...register("pieces_for_package", {
                                      required: true,
                                    })}
                                  />
                                  {errors.pieces_for_package?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      pezzi per confezione field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0"></div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>paese allevato</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.bred_country && "is-invalid"
                                    }`}
                                    name="bred_country"
                                    id="bred_country"
                                    {...register("bred_country", {
                                      required: true,
                                    })}
                                  />
                                  {errors.bred_country?.type === "required" && (
                                    <div className="invalid-feedback">
                                      paese allevato field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>ce timbro</label>
                                </div>
                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.ce_stamp && "is-invalid"
                                    }`}
                                    name="ce_stamp"
                                    id="ce_stamp"
                                    {...register("ce_stamp", {
                                      required: true,
                                    })}
                                  />
                                  {errors.ce_stamp?.type === "required" && (
                                    <div className="invalid-feedback">
                                      ce timbro field is required.
                                    </div>
                                  )}
                                </div>
                                {/* <div className="col-lg-1">
                                  <button
                                    className="btn -pill btn-yellow"
                                    type="button"
                                    onClick={() => setShow(true)}
                                  >
                                    Nuovo
                                  </button>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>laboratorio del prodotto</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="PRIVATO"
                                      {...register("product_lab")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="product_lab"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>ATTIVO</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="active"
                                      {...register("active")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="PRIVATO"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>unisci misura riordina</label>
                                </div>
                                <div className="col-lg-7  ">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.unite_measure_reorder &&
                                      "is-invalid"
                                    }`}
                                    name="unite_measure_reorder"
                                    id="unite_measure_reorder"
                                    {...register("unite_measure_reorder", {
                                      required: true,
                                    })}
                                  />
                                  {errors.unite_measure_reorder?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      unisci misura riordina field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>Paese di nascita</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.birth_country && "is-invalid"
                                    }`}
                                    name="birth_country"
                                    id="birth_country"
                                    {...register("birth_country", {
                                      required: true,
                                    })}
                                  />
                                  {errors.birth_country?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      Paese di nascita field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>DESCRIPTION</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.description && "is-invalid"
                                    }`}
                                    name=""
                                    id="description"
                                    {...register("description", {
                                      required: true,
                                    })}
                                  />
                                  {errors.description?.type === "required" && (
                                    <div className="invalid-feedback">
                                      description field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                           
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>nota laboratorio</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.note_lab && "is-invalid"
                                    }`}
                                    name="note_lab"
                                    id="note_lab"
                                    {...register("note_lab", {
                                      required: true,
                                    })}
                                  />
                                  {errors.note_lab?.type === "required" && (
                                    <div className="invalid-feedback">
                                      nota laboratorio field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-md-4 mt-3 text-center text-md-start">
                        <button className="btn btn-pill btn-yellow">
                          SALVA
                        </button>
                        <button
                          className="btn btn-pill btn-blue ms-2 ms-md-3"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          ANNULLA
                        </button>
                      </div>
                    </form>
                    <Modal show={show} onHide={() => setShow(false)}>
                      <form onSubmit={onModelSubmit}>
                        <Modal.Header closeButton>
                          <Modal.Title>generale</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row g-lg-0">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="row align-items-center">
                                  <div className="col-lg-2 text-lg-end">
                                    <label>descrizione</label>
                                  </div>
                                  <div className="col-lg-5">
                                    <input
                                      type="text"
                                      className={"form-control"}
                                      name=""
                                      id="description"
                                      onChange={(e) => {
                                        setdescrizione(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row g-lg-0">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="row align-items-center">
                                  <div className="col-lg-2 text-lg-end">
                                    <label>codice</label>
                                  </div>
                                  <div className="col-lg-5">
                                    <input
                                      type="text"
                                      className={"form-control"}
                                      name=""
                                      id="codice"
                                      onChange={(e) => {
                                        setCodice(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row g-lg-0">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="row align-items-center">
                                  <div className="col-lg-2 text-lg-end ">
                                    <label>tipo</label>
                                  </div>
                                  <div className="col-lg-5">
                                    <input
                                      type="text"
                                      className={"form-control"}
                                      name=""
                                      id="setTipo"
                                      onChange={(e) => {
                                        setTipo(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button className="btn btn-pill btn-yellow">
                            SALVA
                          </button>
                        </Modal.Footer>
                      </form>
                    </Modal>

                    {/* <DatiCliented/> */}
                  </div>
                </div>
                <Orari Id={Id} />
                <ChartOfAccount
                  activeSelectId={activeSelectId}
                  setActiveSelectId={setActiveSelectId}
                  passiveSelectId={passiveSelectId}
                  setPassiveSelectId={setPassiveSelectId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditArticoli;
