import React, { useEffect, useState } from "react";
import SIdebar from "../../component/sidebar/SIdebar";
import Header from "../../component/header/Header";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Contatti from "./Contatti";
function Editfornitori() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [country, setCountry] = useState([]);
  const [provincia, setProvincia] = useState([]);
  const [showHeading, setShowHeading] = useState(0);


  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { response, request } = useRequest();
  const { request: countryRequest, response: countryResponse } = useRequest();
  const { request: provinceRequest, response: provinceResponse } = useRequest();
  const { request: paymentTypeRequest, response: paymentTypeResponse } =
    useRequest();

  useEffect(() => {
    countryRequest("GET", "country");
    provinceRequest("GET", "provinces");
    paymentTypeRequest("GET", "payment-type");

    if (!state) {
      navigate("/fornitori");
    }
    reset({
      description: state.description,
      provider_code: state.provider_code,
      fiscal_code: state.fiscal_code,
      vat: state.vat,
      is_carrier: state.is_carrier,
      vat_exemption: state.vat_exemption,
      intrastat: state.intrastat,
      id_country: state.address?.country?.id_country,
      id_province: state.address?.province?.id_province,
      street: state.address?.street,
      zip_code: state.address?.zip_code,
      place: state.address?.place,
      // id_payment_type: state.payment_type.id_payment_type,
      id_address: state.address.id_address,
      id_provider: state.id_provider,
    });
    setShowHeading(localStorage.getItem('active'))
  }, []);

  useEffect(() => {
    if (countryResponse) {
      setCountry(
        countryResponse?.data?.country?.map((c) => {
          return {
            value: c.id_country,
            label: c.nome,
          };
        })
      );
    }
  }, [countryResponse]);

  useEffect(() => {
    if (provinceResponse) {
      setProvincia(
        provinceResponse?.data?.provinces?.map((province) => {
          return {
            value: province.id_province,
            label: province.nomeprovincia,
          };
        })
      );
    }
  }, [provinceResponse]);
  
  // useEffect(() => {
  //   if (paymentTypeResponse) {
  //     setPaymentType(
  //       paymentTypeResponse?.data?.map((paymenttype) => {
  //         return {
  //           value: paymenttype.id_payment_type,
  //           label: paymenttype.descrizione,
  //         };
  //       })
  //     );
  //   }
  // }, [paymentTypeResponse]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success("Cliente aggiunto correttamente");
        navigate("/fornitori");
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    request("POST", "edit-provider", data);
  };

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">ANAGRAFICA</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/fornitori">FORNITORI</a>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="pagesearch">
              <div className="pagesearch-block">
                <input type="text" placeholder="" name="" id="" />
                <button>
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
          {/* Page Content */}

          <div className="pageContent">
            <div className="themeTabs my-4">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      showHeading == 0 ? "nav-link  active" : "nav-link"
                    }
                    id="data-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#data-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="data-tab-pane"
                    aria-selected="true"
                    onClick={()=>{
                      localStorage.setItem("active",0)
                    }}
                  >
                    Nuovo fornitore
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                     className={
                      showHeading == 1 ? "nav-link  active" : "nav-link"
                    }
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="contact-tab-pane"
                    aria-selected="false"
                    onClick={()=>{
                      localStorage.setItem("active",1)
                    }}
                  >
                    CONTATTI
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className={
                    showHeading == 0
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="data-tab-pane"
                  role="tabpanel"
                  aria-labelledby="data-tab"
                  tabIndex={0}
                >
                  <form method="post" onSubmit={handleSubmit(onSubmit)}>
                    <div className="tabH-full">
                      <div className="row g-lg-0"></div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>DENOMINAZIONE</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.description && "is-invalid"
                                    }`}
                                    name="name"
                                    id="name"
                                    {...register("description", {
                                      required: true,
                                    })}
                                  />
                                  {errors.description?.type === "required" && (
                                    <div className="invalid-feedback">
                                      The DENOMINAZIONE field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>CODICE FORNITORE</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.provider_code && "is-invalid"
                                  }`}
                                  name="provider_code"
                                  id="provider_code"
                                  {...register("provider_code", {
                                    required: true,
                                  })}
                                />
                                {errors.provider_code?.type === "required" && (
                                  <div className="invalid-feedback">
                                    CODICE FORNITORE field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>CODICE FISCALE</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.fiscal_code && "is-invalid"
                                  }`}
                                  name=""
                                  id="fiscal_code"
                                  {...register("fiscal_code", {
                                    required: true,
                                  })}
                                />
                                {errors.fiscal_code?.type === "required" && (
                                  <div className="invalid-feedback">
                                    CODICE FISCALE field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>ATTIVO</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="active"
                                    {...register("active")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="active"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>I.V.A</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.vat && "is-invalid"
                                    }`}
                                    name=""
                                    id="vat"
                                    {...register("vat", {
                                      required: true,
                                    })}
                                  />
                                  {errors.vat?.type === "required" && (
                                    <div className="invalid-feedback">
                                      i.v.a field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>NAZIONE</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_country"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      value={country.find(
                                        (c) => c.value === value
                                      )}
                                      options={country}
                                      classNamePrefix="select"
                                      placeholder="Select Nazione"
                                      className={`basic-multi-select ${
                                        errors.id_country && "is-invalid"
                                      }`}
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.id_country?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The NAZIONE field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0"></div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>PROVINCIA</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_province"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      value={provincia.find(
                                        (c) => c.value === value
                                      )}
                                      options={provincia}
                                      classNamePrefix="select"
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.id_province?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The province field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label> VETTORE</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="is_carrier"
                                    {...register("is_carrier")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="is_carrier"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>STRADA</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.id_address && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("street", {
                                    required: true,
                                  })}
                                />
                                {errors.street?.type === "required" && (
                                  <div className="invalid-feedback">
                                    STRADA field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>CAP</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.zip_code && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("zip_code", {
                                    required: true,
                                  })}
                                />
                                {errors.zip_code?.type === "required" && (
                                  <div className="invalid-feedback">
                                    CAP field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0"></div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>POSTO</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.place && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("place", {
                                    required: true,
                                  })}
                                />
                                {errors.place?.type === "required" && (
                                  <div className="invalid-feedback">
                                    POSTO field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>ESENZIONE IVA</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="vat_exemption"
                                    {...register("vat_exemption")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="vat_exemption"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        {/* <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Tipo di pagamento</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_payment_type"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                    value={paymentType.find(
                                        (c) => c.value === value
                                    )}
                                      options={paymentType}
                                      classNamePrefix="select province"
                                      placeholder="select "
                                      className={`basic-multi-select ${
                                        errors.id_payment_type && "is-invalid"
                                      }`}
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.id_payment_type?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The Tipo di pagamento field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>INTRASTAT</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="intrastat"
                                    {...register("intrastat")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="PRIVATO"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row g-lg-0"></div>
                      <div className="row g-lg-0">
                        {/* <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>attivo</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="active"
                                    {...register("active")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="active"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="mt-md-4 mt-3 text-center text-md-start">
                      <button className="btn btn-pill btn-yellow">SALVA</button>
                      <button
                        className="btn btn-pill btn-blue ms-2 ms-md-3"
                        onClick={() => {
                          navigate("/fornitori");
                        }}
                      >
                        ANNULLA
                      </button>
                    </div>
                  </form>
                </div>
                <Contatti id_provider={state.id_provider} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editfornitori;
