// export const API = {
//   PORT:
//     process.env.NODE_ENV === "development"
//       ? "http://192.168.235.200:5002/api/v1"
//       : "http://192.168.235.200:5002/api/v1",
//   Base_Url: "192.168.235.200:5002/",
// };

export const API = {
  PORT:
    process.env.NODE_ENV === "production"
      ? "https://hqfgestbe.hqfsw.com/api/v1"
      : "https://hqfgestbe.hqfsw.com/api/v1",
  Base_Url: "https://hqfgestbe.hqfsw.com/",
};
