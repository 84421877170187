import React, { useEffect, useState } from "react";
import useRequest from "../../../../hooks/useRequest";
import { Link, useSearchParams } from "react-router-dom";

const Contatti = (id_agent) => {
  const [searchParams] = useSearchParams();
  const [showHeading, setShowHeading] = useState("");
  const [contacts, setContacts] = useState([]);
  const { request, response } = useRequest();

  useEffect(() => {
    request("GET", "contact-agent");
    setShowHeading(localStorage.getItem('active'))

  }, []);
  console.log(showHeading, "showHeadingsdfasdfaafsd");
  useEffect(() => {
    if (response) {
      setContacts(response.data);
    }
    // setShowHeading(true)
  }, [response]);

  return (
    <div
      className={
        showHeading == 1 ? "tab-pane fade show active" : "tab-pane fade"
      }
      // className="tab-pane fade show active"
      id="point-tab-pane"
      role="tabpanel"
      aria-labelledby="point-tab"
      tabIndex={0}
    >
      <Link
        to="/agents/contatti/addcontatti"
        state={id_agent}
        // setShowHeading={setShowHeading}

        className="btn btn-sm btn-primary ms-3"
      >
        Add
      </Link>
      <div className="themeTable customerTable">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>role</th>
              <th>name</th>
              <th>surname</th>
              <th>email</th>

              <th>phone number</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {contacts.length > 0 &&
              contacts.map((contact) => (
                <tr key={contact?.id_contact}>
                  <td data-th="ID">{contact?.id_contact}</td>
                  <td data-th="role">{contact?.role}</td>
                  <td data-th="name">{contact?.name}</td>
                  <td data-th="surname">{contact?.surname}</td>
                  <td data-th="email">{contact?.mail}</td>
                  <td data-th="phone_number">{contact?.phone}</td>
                  <td data-th="ACTION">
                    <Link
                      to={`/agents/contatti/editcontatti`}
                      className="btn btn-sm btn-primary"
                      state={contact}
                    >
                      <i className="fas fa-pencil-alt" />
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Contatti;
