import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SIdebar from "../../component/sidebar/SIdebar";
import Header from "../../component/header/Header";
import { useForm } from "react-hook-form";
import useRequest from "../../hooks/useRequest";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { debounce } from "lodash";

const searchQueryHandler = (name = "") => {
  return `get-all-product-categories?name=${name}`;
};

function Categorie() {
  const {
    formState: { errors },
  } = useForm();

  const [searchQuery, setSearchQuery] = useState("");
  const [micro, setMicro] = useState([]);
  const [showcata, setShowCata] = useState(false);
  const [showMicro, setShowMicro] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [MacID, setMacID] = useState("");
  const [dissAddmicro, setDissAddmicro] = useState(false);
  const [showAddmicro, setShowAddmicro] = useState(false);
  const [microDiscription, setMicroDiscription] = useState("");
  const [macroID, setMacroID] = useState("");
  const [Showdisscatagory, setShowDisscatagory] = useState(false);
  const [showAddCata, setShowAddCata] = useState(false);
  const [showdissSub, setShowdissSub] = useState(false);
  const [showAddSub, setShowAddSub] = useState(false);
  const [catagoryDiscription, setcatagoryDiscription] = useState("");
  const [subDiscription, setSubDiscription] = useState("");
  const [cataID, setCataID] = useState("");
  const [subID, setSubID] = useState("");
  const [macID, setmacID] = useState("");
  const [catID, setCatID] = useState("");
  const [subID1, setSubID1] = useState("");

  const { request, response } = useRequest();
  const { response: addmacroResponse, request: addmacroRequest } = useRequest();
  const { response: editmacroResponse, request: editmacroRequest } =
    useRequest();
  const { response: editcataResponse, request: editcataRequest } = useRequest();
  const { request: addcateRequest, response: addcateResponse } = useRequest();
  const { request: addSubRequest, response: addSubResponse } = useRequest();
  const { request: editSubcateRequest, response: editSubcateResponse } =
    useRequest();
  useEffect(() => {
    request("GET", searchQueryHandler(searchQuery));
  }, []);

  useEffect(() => {
    if (response) {
      setMicro(response.data.productCategoryData.results);
    }
  }, [response]);

  useEffect(() => {
    if (addmacroResponse) {
      if (addmacroResponse.status === "SUCCESS") {
        setMicroDiscription("");
        setMacID("");
        setShowAddmicro(false);
        request("GET", "get-all-product-categories");
      } else {
        toast.error(addmacroResponse.message);
      }
    }
  }, [addmacroResponse]);

  useEffect(() => {
    if (editmacroResponse) {
      if (editmacroResponse.status === "SUCCESS") {
        setMicroDiscription("");
        setMacID("");
        setMacroID("");
        setShowMicro(false);
        request("GET", "get-all-product-categories");
      } else {
        toast.error(editmacroResponse.message);
      }
    }
  }, [editmacroResponse]);

  useEffect(() => {
    if (addcateResponse) {
      if (addcateResponse.status === "SUCCESS") {
        setcatagoryDiscription("");
        setMacID("");
        setShowAddCata(false);
        setShowAddmicro(false);
        request("GET", "get-all-product-categories");
      } else {
        toast.error(addcateResponse.message);
      }
    }
  }, [addcateResponse]);

  useEffect(() => {
    if (editcataResponse) {
      if (editcataResponse.status === "SUCCESS") {
        setMicroDiscription("");
        setMacroID("");
        setCataID("");
        setShowCata(false);
        request("GET", "get-all-product-categories");
      } else {
        toast.error(editcataResponse.message);
      }
    }
  }, [editcataResponse]);

  useEffect(() => {
    if (addSubResponse) {
      if (addSubResponse.status === "SUCCESS") {
        setcatagoryDiscription("");
        setMacID("");
        setCataID("");
        setShowAddSub(false);
        request("GET", "get-all-product-categories");
      } else {
        toast.error(addSubResponse.message);
      }
    }
  }, [addSubResponse]);

  useEffect(() => {
    if (editSubcateResponse) {
      if (editSubcateResponse.status === "SUCCESS") {
        setSubDiscription("");
        setMacID("");
        setCataID("");
        setShowSub(false);
        request("GET", "get-all-product-categories");
      } else {
        toast.error(editSubcateResponse.message);
      }
    }
  }, [editSubcateResponse]);

  const onSearchHandler = () => {
    request("GET", searchQueryHandler(searchQuery));
  };
  const onEditMicroModalSubmit = (e) => {
    e.preventDefault();
    if (!microDiscription) {
      toast.error("The description field is required.");
    } else {
      editmacroRequest("POST", "edit-macro-category", {
        description: microDiscription,
        prefix: microDiscription.slice(0, 3),
        id_macro_cat: macroID,
      });
    }
  };

  const onMicroModalSubmit = (e) => {
    e.preventDefault();
    if (!microDiscription) {
      toast.error("The description field is required.");
    } else {
      addmacroRequest("POST", "add-macro-cata", {
        description: microDiscription,
        prefix: microDiscription.slice(0, 3),
      });
    }
  };

  const onCataModalSubmit = (e) => {
    e.preventDefault();
    if (!catagoryDiscription) {
      toast.error("The description field is required.");
    } else {
      addcateRequest("POST", `add-cate-catalog`, {
        description: catagoryDiscription,
        id_macro_cat: macroID,
      });
    }
  };

  const onEditcataModalSubmit = (e) => {
    e.preventDefault();
    if (!catagoryDiscription) {
      toast.error("The description field is required.");
    } else {
      editcataRequest("POST", `edit-cate-catalog`, {
        description: catagoryDiscription,
        id_macro_cat: macroID,
        id_category_catalog: cataID,
      });
    }
  };

  const onSubModalSubmit = (e) => {
    e.preventDefault();
    if (!subDiscription) {
      toast.error("The description field is required.");
    } else {
      addSubRequest("POST", `add-sub-category`, {
        description: subDiscription,
        id_category_catalog: cataID,
      });
    }
  };

  const onEditSubModalSubmit = (e) => {
    e.preventDefault();
    if (!subDiscription) {
      toast.error("The description field is required.");
    } else {
      editSubcateRequest("POST", `edit-sub-category`, {
        description: subDiscription,
        id_category_catalog: cataID,
        id_sub_cat: subID,
      });
    }
  };
  // const MouseOver = (event) => {
  //   event.target.style.background = "#ffcb00";
  // };
  // const MouseOut = (event) => {
  //   event.target.style.background = "";
  // };

  const handleChangeWithSearch = debounce((searchQuery) => {
    request("GET", searchQueryHandler(searchQuery));
  }, 500);

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">ANAGRAFICA</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Categorie</Link>
                  </li>
                </ol>
              </nav>
              {/* <button
                // onClick={() => {
                //   setShow(true);
                // }}
                className="btn btn-sm btn-primary ms-3"
              >
                <i className="far fa-plus" />
              </button> */}
            </div>
            <div className="pagesearch">
              <div className="pagesearch-block">
                {/* <input
                  type="text"
                  placeholder="MACROCATEGORY DENOMINAZIONE"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                /> */}

                <input
                  type="text"
                  placeholder="Search nome della macrocategoria"
                  value={searchQuery}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchQuery(e.target.value);
                    handleChangeWithSearch(e.target.value);
                  }}
                />

                <button onClick={onSearchHandler}>
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
          {/* Page Content */}
          <div className="pageContent">
            <div className="container">
              <div className="row">
                <div className="col-md-2">
                  <div>
                    <button
                      className="btn -pill btn-yellow mb-2"
                      disabled={dissAddmicro === false}
                      onClick={() => {
                        setShowAddmicro(true);
                      }}
                    >
                      new macrocat
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn -pill btn-yellow mb-2"
                      disabled={Showdisscatagory === false}
                      onClick={() => {
                        setShowAddCata(true);
                      }}
                    >
                      New Category
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn -pill btn-yellow "
                      disabled={showdissSub === false}
                      onClick={(e) => {
                        setShowAddSub(true);
                      }}
                    >
                      New Subcat.
                    </button>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="themeTable customerTable">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>CODE</th>
                          <th>PREFIX</th>
                          <th>DENOMINAZIONE</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {micro.length > 0 &&
                          micro.map((data, index) => {
                            return (
                              <>
                                <tr
                                  className={
                                    data.id_macro_cat === macID
                                      ? "table table-striped table-bordered  active"
                                      : ""
                                  }
                                  onClick={() => {
                                    setDissAddmicro(true);
                                    setMacroID(data.id_macro_cat);
                                    setShowDisscatagory(true);
                                    setShowdissSub(false);
                                    setmacID(data.id_macro_cat);
                                    setSubID1("");
                                    setCatID("");
                                  }}
                                >
                                  {console.log(
                                    macID,
                                    "sahdflkdflahsdlkjhflasdflkas"
                                  )}
                                  <td data-th="CODE">
                                    <h4>M:{data.id_macro_cat}</h4>
                                  </td>
                                  <td data-th="PREFIX">
                                    <h4>{data.prefix}</h4>
                                  </td>
                                  <td data-th="	DENOMINAZIONE">
                                    <h4>{data.description}</h4>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        setShowMicro(true);
                                        setMacroID(data.id_macro_cat);
                                        setMicroDiscription(data.description);
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-pencil-alt" />
                                    </button>
                                  </td>
                                </tr>

                                {data.categoryData.length > 0 &&
                                  data.categoryData.map((catData, catIndex) => {
                                    return (
                                      <>
                                        <tr
                                          className={
                                            catData.id_category_catalog ===
                                            catID
                                              ? "table table-striped table-bordered  active"
                                              : ""
                                          }
                                          onClick={() => {
                                            setDissAddmicro(true);
                                            setShowDisscatagory(false);
                                            setShowdissSub(true);
                                            setMacroID(data.id_macro_cat);
                                            setCataID(
                                              catData.id_category_catalog
                                            );
                                            setmacID("");
                                            setCatID(
                                              catData.id_category_catalog
                                            );
                                            // setSubID1
                                            setSubID1("");
                                            setmacID("");
                                            // setCatID("")
                                          }}
                                        >
                                          <td data-th="CODE">
                                            <h5>
                                              C:{catData.id_category_catalog}
                                            </h5>
                                          </td>
                                          <td />
                                          <td data-th="	DENOMINAZIONE">
                                            <h5> {catData.description}</h5>
                                          </td>
                                          <td>
                                            <button
                                              className="btn btn-sm btn-primary"
                                              onClick={() => {
                                                setShowCata(true);
                                                setcatagoryDiscription(
                                                  catData.description
                                                );
                                                setCataID(
                                                  catData.id_category_catalog
                                                );
                                                setMacroID(
                                                  catData.id_macro_cat
                                                );
                                              }}
                                            >
                                              <i className="fas fa-pencil-alt" />
                                            </button>
                                          </td>
                                        </tr>

                                        {catData.subCategoryData.length > 0 &&
                                          catData.subCategoryData.map(
                                            (subCatData, subCatIndex) => (
                                              <>
                                                <tr
                                                  className={
                                                    subCatData.id_sub_cat ===
                                                    subID1
                                                      ? "table table-striped table-bordered  active"
                                                      : ""
                                                  }
                                                  onClick={() => {
                                                    setDissAddmicro(true);
                                                    setMacroID(
                                                      data.id_macro_cat
                                                    );
                                                    setShowDisscatagory(false);
                                                    setShowdissSub(false);
                                                    setSubID1(
                                                      subCatData.id_sub_cat
                                                    );
                                                    // setmacID("")
                                                    setCatID("");
                                                    setmacID("");
                                                    // setCatID("")
                                                  }}
                                                >
                                                  <td  data-th="CODE">
                                                    <h6>
                                                      S:{subCatData.id_sub_cat}
                                                    </h6>
                                                  </td>
                                                  <td />
                                                  <td data-th="DENOMINAZIONE">
                                                    <h6>
                                                      {subCatData.description}
                                                    </h6>
                                                  </td>
                                                  <td>
                                                    <button
                                                      className="btn btn-sm btn-primary"
                                                      onClick={() => {
                                                        setShowSub(true);
                                                        setSubDiscription(
                                                          subCatData.description
                                                        );
                                                        setSubID(
                                                          subCatData.id_sub_cat
                                                        );
                                                        setCataID(
                                                          subCatData.id_category_catalog
                                                        );
                                                      }}
                                                    >
                                                      {" "}
                                                      <i className="fas fa-pencil-alt" />
                                                    </button>
                                                  </td>
                                                </tr>
                                              </>
                                            )
                                          )}
                                      </>
                                    );
                                  })}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-2" />
              </div>
            </div>
          </div>

          <Modal
            show={showMicro}
            onHide={() => setShowMicro(false)}
            backdrop="static"
            keyboard={false}
          >
            <form onSubmit={onEditMicroModalSubmit}>
              <Modal.Header>
                <Modal.Title>generale</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-3 ">
                          <label>Description</label>
                        </div>
                        <div className="col-lg-7">
                          <input
                            type="text"
                            className={`form-control `}
                            // defaultValue={}
                            defaultValue={microDiscription}
                            name="description"
                            id="Description"
                            onChange={(e) => {
                              setMicroDiscription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-pill btn-yellow">SALVA</button>
                <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowMicro(false);
                    setMacroID("");
                    setMicroDiscription("");
                  }}
                >
                  ANNULLA
                </button>
              </Modal.Footer>
            </form>
          </Modal>

          <Modal
            show={showcata}
            onHide={() => setShowCata(false)}
            backdrop="static"
            keyboard={false}
          >
            <form onSubmit={onEditcataModalSubmit}>
              <Modal.Header>
                <Modal.Title>generale</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-3 ">
                          <label>Description</label>
                        </div>
                        <div className="col-lg-7">
                          <input
                            type="text"
                            className={`form-control `}
                            defaultValue={catagoryDiscription}
                            name="description"
                            id="Description"
                            onChange={(e) => {
                              setcatagoryDiscription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-pill btn-yellow">SALVA</button>
                <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCata(false);
                  }}
                >
                  ANNULLA
                </button>
              </Modal.Footer>
            </form>
          </Modal>

          <Modal
            show={showSub}
            onHide={() => setShowSub(false)}
            backdrop="static"
            keyboard={false}
          >
            <form onSubmit={onEditSubModalSubmit}>
              <Modal.Header>
                <Modal.Title>generale</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-3 ">
                          <label>Description</label>
                        </div>
                        <div className="col-lg-7">
                          <input
                            type="text"
                            className={`form-control `}
                            name="description"
                            value={subDiscription}
                            id="Description"
                            onChange={(e) => {
                              setSubDiscription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-pill btn-yellow">SALVA</button>
                <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowSub(false);
                  }}
                >
                  ANNULLA
                </button>
              </Modal.Footer>
            </form>
          </Modal>
          <Modal
            show={showAddmicro}
            onHide={() => setShowAddmicro(false)}
            backdrop="static"
            keyboard={false}
          >
            <form onSubmit={onMicroModalSubmit}>
              <Modal.Header>
                <Modal.Title>generale</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-3 ">
                          <label>Description</label>
                        </div>
                        <div className="col-lg-7">
                          <input
                            type="text"
                            className={`form-control `}
                            name="description"
                            id="Description"
                            onChange={(e) => {
                              setMicroDiscription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-pill btn-yellow">SALVA</button>
                <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAddmicro(false);
                  }}
                >
                  ANNULLA
                </button>
              </Modal.Footer>
            </form>
          </Modal>
          <Modal
            show={showAddCata}
            onHide={() => setShowAddCata(false)}
            backdrop="static"
            keyboard={false}
          >
            <form onSubmit={onCataModalSubmit}>
              <Modal.Header>
                <Modal.Title>generale</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-3 ">
                          <label>Description</label>
                        </div>
                        <div className="col-lg-7">
                          <input
                            type="text"
                            className={`form-control `}
                            name="description"
                            id="Description"
                            onChange={(e) => {
                              setcatagoryDiscription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-pill btn-yellow">SALVA</button>
                <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAddCata(false);
                    setDissAddmicro(false);
                    setMacroID("");
                    setShowDisscatagory(false);
                    setShowdissSub(false);
                  }}
                >
                  ANNULLA
                </button>
              </Modal.Footer>
            </form>
          </Modal>

          <Modal
            show={showAddSub}
            onHide={() => setShowAddSub(false)}
            backdrop="static"
            keyboard={false}
          >
            <form onSubmit={onSubModalSubmit}>
              <Modal.Header>
                <Modal.Title>generale</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-3 ">
                          <label>Description</label>
                        </div>
                        <div className="col-lg-7">
                          <input
                            type="text"
                            className={`form-control `}
                            name="description"
                            id="Description"
                            onChange={(e) => {
                              setSubDiscription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-pill btn-yellow">SALVA</button>
                <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAddSub(false);
                    setDissAddmicro(false);
                    setMacroID("");
                    setCataID("");
                    setShowDisscatagory(false);
                    setShowdissSub(false);
                  }}
                >
                  ANNULLA
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Categorie;
