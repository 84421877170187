import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SIdebar from "../../component/sidebar/SIdebar";
import Header from "../../component/header/Header";
import Pagination from "../../component/Pagination/Pagination";
import useRequest from "../../hooks/useRequest";
import { debounce } from "lodash";

const searchQueryHandler = (page, per_page, name = "") => {
  return `stores?page=${page}&per_page=${per_page}&name=${name}`;
};

function PuntoVendita() {
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [stores, setStores] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { request, response } = useRequest();

  useEffect(() => {
    request("GET", searchQueryHandler(1, perPage, searchQuery));
  }, []);

  useEffect(() => {
    if (response) {
      setStores(response.data.results);
      setTotalDocuments(response.data?.total_records);
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setStores([]);
    setPage(selected + 1);
    request("GET", searchQueryHandler(selected + 1, perPage, searchQuery));
  };

  const onSearchHandler = () => {
    request("GET", searchQueryHandler(1, perPage, searchQuery));
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    request("GET", searchQueryHandler(1, event.target.value, searchQuery));
    setPage(1);

    setPerPage(event.target.value);
  };

  const handleChangeWithSearch = debounce((searchQuery) => {
    request("GET", searchQueryHandler(1, perPage, searchQuery));
  }, 500);

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">ANAGRAFICA</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/puntovendita">PUNTI VENDITA</Link>
                  </li>
                </ol>
              </nav>
              <Link
                to="/puntovendita/add"
                className="btn btn-sm btn-primary ms-3"
              >
                <i className="far fa-plus" />
              </Link>
            </div>
            <div className="pagesearch">
              <div className="pagesearch-block">
                {/* <input
                  type="text"
                  placeholder="DENOMINAZIONE"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                /> */}
                <input
                  type="text"
                  placeholder="DENOMINAZIONE"
                  value={searchQuery}
                  // onChange={(e) =>

                  //  }
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchQuery(e.target.value);
                    handleChangeWithSearch(e.target.value);
                  }}
                />

                {/* 
                <button onClick={onSearchHandler}>
                  <i className="fas fa-search" />
                </button> */}
              </div>
            </div>
          </div>
          {/* Page Content */}
          <div className="pageContent">
            <div className="themeTable customerTable">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>DENOMINAZIONE</th>
                    <th>CLIENTE</th>
                    <th>INDIRIZZO</th>
                    <th>PROVINCIA</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {stores.length > 0 &&
                    stores.map((store, index) => (
                      <tr key={store.id_selling_point}>
                        <td data-th="ID">{store.id_selling_point}</td>
                        <td
                          data-th="DENOMINAZIONE"
                          style={{ textAlign: "left" }}
                        >
                          {store.description}
                        </td>
                        <td data-th="CLIENTE">
                          {store?.customer.name}{" "}
                          {store.customer.surname ? store.customer.surname : ""}
                        </td>
                        <td data-th="INDIRIZZO">
                          {store?.customer?.address?.place}
                        </td>
                        <td data-th="PROVINCIA">
                          {store.customer.address.province.nomeprovincia}
                        </td>
                        <td data-th="ACTION">
                          <Link
                            to={`/puntovendita/edit/${store.id_selling_point}`}
                            state={store}
                            className="btn btn-sm btn-primary"
                          >
                            <i className="fas fa-pencil-alt" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {perPage !== 0 && (
              <Pagination
                page={page}
                totalRecords={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                defaultPerPage={10}
                perPageChangeHandler={perPageChangeHandler}
                currentDocLength={stores.length}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PuntoVendita;
