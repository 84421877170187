import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";

const minutesOfDay = (m) => {
  return m.minutes() + m.hours() * 60;
};

const TIME_SLOTS = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "23:59",
];

const Timetable = ({ Id }) => {
  const [timetables, setTimetables] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  const { request, response } = useRequest();
  const { request: updateRequest, response: updateResponse } = useRequest();
  const { request: deleteRequest, response: deleteResponse } = useRequest();

  const {
    register,
    unregister,
    handleSubmit,
    reset,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    request("GET", `timetables/${Id}`);
  }, []);

  useEffect(() => {
    if (response) {
      const updatedData = response.data?.timeTableData.map(
        (timetable, index) => {
          const idx = TIME_SLOTS.indexOf(timetable.start_time);
          // const startTimeKey = TIME_SLOTS.slice(idx + 1);

          return {
            ...timetable,
            id: index,
            startTimeKey: idx + 1,
          };
        }
      );
      setTimetables(updatedData);
      setLastIndex(updatedData.length);

      let updates = {};
      updatedData.forEach((timetable) => {
        let subUpdates = {
          // [`${timetable.id}.id_customer_hours`]: timetable.id_customer_hours,
          [`${timetable.id}.delivery_day`]: timetable.delivery_day,
          [`${timetable.id}.start_time`]: timetable.start_time,
          [`${timetable.id}.end_time`]: timetable.end_time,
          
        };
        updates = { ...updates, ...subUpdates };
      });

      reset({ ...updates });
    }
  }, [response]);

  useEffect(() => {
    if (updateResponse) {
      if (updateResponse.status === "SUCCESS") {
        request("GET", `timetables/${Id}`);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  }, [updateResponse]);

  useEffect(() => {
    if (deleteResponse) {
      if (deleteResponse.status === "SUCCESS") {
        // request("GET", `timetables/${Id}`);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  }, [deleteResponse]);

  const submitHandler = (data) => {
    const time_tables = [];
    let isErrorMain = false;
    for (let i = 0; i < timetables.length; i++) {
      const item = timetables[i];
      const id = item.id;

      let startTime = data[id]["start_time"];
      let endTime = data[id]["end_time"];

      const startTimeFormatted = minutesOfDay(
        moment().set({ h: startTime.split(":")[0], m: startTime.split(":")[1] })
      );

      const endTimeFormatted = minutesOfDay(
        moment().set({ h: endTime.split(":")[0], m: endTime.split(":")[1] })
      );

      if (endTimeFormatted <= startTimeFormatted) {
        setError(id + ".end_time", {
          type: "manual",
        });
        isErrorMain = true;
      } else {
        clearErrors(id + ".end_time");
      }

      const selectedDay = data[id]["delivery_day"];

      time_tables.push({
        start_time: startTime,
        end_time: endTime,
        delivery_day: selectedDay,
        id_customer_hours: item.id_customer_hours,
      });

      startTime = moment().set({
        h: startTime.split(":")[0],
        m: startTime.split(":")[1],
      });

      endTime = moment().set({
        h: endTime.split(":")[0],
        m: endTime.split(":")[1],
      });

      let selectedDays = [];

      if (selectedDay !== 0) {
        for (let i = 0; i < timetables.length; i++) {
          const item = timetables[i];

          if (item.id === id) {
            continue;
          }

          const itemDay = data[item.id]["delivery_day"];

          if (itemDay === 0 || itemDay === selectedDay) {
            selectedDays.push(item);
          }
        }
      } else {
        selectedDays = [...timetables].filter((t) => t.id !== id);
      }

      let isError = false;

      for (let i = 0; i < selectedDays.length; i++) {
        const item = selectedDays[i];

        let itemSTime = data[item.id]["start_time"];
        itemSTime = moment().set({
          h: itemSTime.split(":")[0],
          m: itemSTime.split(":")[1],
        });

        let itemETime = data[item.id]["end_time"];
        itemETime = moment().set({
          h: itemETime.split(":")[0],
          m: itemETime.split(":")[1],
        });

        if (startTime.isBetween(itemSTime, itemETime, undefined, "[)")) {
          isError = true;
          isErrorMain = true;
          setError(id + ".start_time", {
            type: "manual1",
          });
        } else {
          clearErrors(id + ".start_time");
        }

        if (
          endTime.isBetween(itemSTime, itemETime, undefined, "(]") ||
          (startTime.isBefore(itemSTime) && endTime.isAfter(itemETime))
        ) {
          isError = true;
          isErrorMain = true;
          setError(id + ".end_time", {
            type: "manual1",
          });
        } else {
          clearErrors(id + ".end_time");
        }

        if (isError) {
          break;
        }
      }
    }

    if (isErrorMain) {
      return;
    }

    updateRequest("POST", "add-update-timetable", {
      time_tables,
      id_selling_point: Id,
    });
  };

  const addNewRow = () => {
    const oldData = [...timetables];

    oldData.push({
      id: lastIndex,
      delivery_day: 0,
      start_time: "00:00",
      end_time: "00:30",
      id_customer_hours: null,
      startTimeKey: 1,
    });

    setValue(`${lastIndex}.delivery_day`, 0);
    setValue(`${lastIndex}.start_time`, "00:00");
    setValue(`${lastIndex}.end_time`, "00:30");
    setValue(`${lastIndex}.id_customer_hours`, null);

    timeHandler(lastIndex, null, null, "0");
    setLastIndex((prev) => prev + 1);
    setTimetables(oldData);
  };

  const deleteHandler = (id, id_customer_hours) => {
    if (id_customer_hours) {
      deleteRequest("POST", `delete-timetable/${id_customer_hours}`);
    }
    unregister([
      `${id}.delivery_day`,
      `${id}.start_time`,
      `${id}.end_time`,
      `${id}.id_customer_hours`,
    ]);

    setTimetables((prev) => prev.filter((timetable) => timetable.id !== id));

    // if (id === 0 || id_customer_hours === null) {
    // SetdayOpApi(false);
    // newOpt(false);
    // }
  };

  const timeHandler = (key, where, val, weekName) => {
    let startTime, endTime;
    if (!weekName) {
      if (where == "start_time") {
        startTime = val;
        endTime = getValues(key + ".end_time");

        //updating startTimeKey

        const index = timetables.findIndex((t) => t.id === key);
        let newTimetable = [...timetables];
        newTimetable[index] = {
          ...newTimetable[index],
          startTimeKey: TIME_SLOTS.indexOf(val) + 1,
        };
        setTimetables(newTimetable);
      } else {
        startTime = getValues(key + ".start_time");
        endTime = val;
      }
    } else {
      startTime = getValues(key + ".start_time");
      endTime = getValues(key + ".end_time");
    }

    const startTimeFormatted = minutesOfDay(
      moment().set({ h: startTime.split(":")[0], m: startTime.split(":")[1] })
    );

    const endTimeFormatted = minutesOfDay(
      moment().set({ h: endTime.split(":")[0], m: endTime.split(":")[1] })
    );

    if (endTimeFormatted <= startTimeFormatted) {
      // setError(key + ".end_time", {
      //   type: "manual",
      // });
      // return;

      const index = TIME_SLOTS.findIndex((t) => t === startTime);

      setValue(key + ".end_time", TIME_SLOTS[index + 1]);
    } else {
      clearErrors(key + ".end_time");
    }

    const selectedDay = !weekName
      ? getValues(key + ".delivery_day")
      : +weekName;
    for (let i = 0; i < timetables.length; i++) {
      const itemId = timetables[i].id;
      clearErrors(itemId + ".start_time");
      clearErrors(itemId + ".end_time");
    }
    // if (selectedDay !== 0) {
    // const allDays = []
    let selectedDays = [];

    if (selectedDay !== 0) {
      for (let i = 0; i < timetables.length; i++) {
        const item = timetables[i];

        if (item.id == key) {
          continue;
        }

        const itemDay = getValues(item.id + ".delivery_day");

        if (itemDay == 0 || itemDay == selectedDay) {
          selectedDays.push(item);
        }
      }
    } else {
      selectedDays = [...timetables].filter((t) => t.id != key);
    }

    let isError = false;

    startTime = moment().set({
      h: startTime.split(":")[0],
      m: startTime.split(":")[1],
    });

    endTime = moment().set({
      h: endTime.split(":")[0],
      m: endTime.split(":")[1],
    });

    for (let i = 0; i < selectedDays.length; i++) {
      const item = selectedDays[i];

      let itemSTime = getValues(item.id + ".start_time");
      itemSTime = moment().set({
        h: itemSTime.split(":")[0],
        m: itemSTime.split(":")[1],
      });

      let itemETime = getValues(item.id + ".end_time");
      itemETime = moment().set({
        h: itemETime.split(":")[0],
        m: itemETime.split(":")[1],
      });

      //undefined, "[]"
      if (startTime.isBetween(itemSTime, itemETime, undefined, "[)")) {
        isError = true;
        setError(key + ".start_time", {
          type: "manual1",
        });
      } else {
        clearErrors(key + ".start_time");
      }

      if (
        endTime.isBetween(itemSTime, itemETime, undefined, "(]") ||
        (startTime.isBefore(itemSTime) && endTime.isAfter(itemETime))
      ) {
        isError = true;
        setError(key + ".end_time", {
          type: "manual1",
        });
      } else {
        clearErrors(key + ".end_time");
      }

      if (isError) {
        break;
      }

      // if (startTime.isBefore(itemSTime) && endTime.isAfter(itemETime)) {
      //   isError = true;
      //   setError(key + ".end_time", {
      //     type: "manual2",
      //   });
      // } else {
      //   clearErrors(key + ".end_time");
      // }
    }
    // }
  };

  return (
    <div
      className="tab-pane fade"
      id="point-tab-pane"
      role="tabpanel"
      aria-labelledby="point-tab"
      tabIndex={0}
    >
      <div className="themeTable customerTable">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div>
            <button
              type="button"
              className="btn btn-sm btn-primary ms-3"
              onClick={addNewRow}
            >
              Add
            </button>
            <button type="submit" className="btn btn-sm btn-primary ms-3">
              Save
            </button>
          </div>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                {/* <th style={{ width: "15%" }}>ID</th> */}
                <th style={{ width: "15%" }}>Delivery day</th>
                <th style={{ width: "30%" }}>Start time</th>
                <th style={{ width: "30%" }}>End time</th>
                <th style={{ width: "10%" }} />
              </tr>
            </thead>
            <tbody>
              {timetables.length > 0 &&
                timetables.map((timetable) => (
                  <tr key={`timetable_${timetable.id}`}>
                    <td data-th="delivery">
                      <select
                        className={`form-control ${
                          errors[timetable.id]?.delivery_day && "is-invalid"
                        }`}
                        {...register(`${timetable.id}.delivery_day`, {
                          required: true,
                          minLength: 1,
                        })}
                        onChange={(e) =>
                          timeHandler(
                            timetable.id,
                            null,
                            null,
                            e.target.value.toString()
                          )
                        }
                      >
                        <>
                          <option value={0}>All</option>
                          <option value={1}>Monday</option>
                          <option value={2}>Tuesday</option>
                          <option value={3}>Wednesday</option>
                          <option value={4}>Thursday</option>
                          <option value={5}>Friday</option>
                          <option value={6}>Saturday</option>
                          <option value={7}>Sunday</option>
                        </>
                      </select>
                      {errors[timetable.id]?.delivery_day?.type ===
                        "required" && (
                        <div className="invalid-feedback">
                          The delivery date field is required.
                        </div>
                      )}
                    </td>

                    <td data-th="start_time">
                      <select
                        className={`form-control ${
                          errors[timetable.id]?.start_time && "is-invalid"
                        }`}
                        {...register(`${timetable.id}.start_time`, {
                          required: true,
                          minLength: 1,
                        })}
                        onChange={(e) =>
                          timeHandler(
                            timetable.id,
                            "start_time",
                            e.target.value
                          )
                        }
                      >
                        {TIME_SLOTS.slice(0, TIME_SLOTS.length - 1).map(
                          (time, idx) => (
                            <option value={time} key={time}>
                              {time}
                            </option>
                          )
                        )}
                      </select>
                      {errors[timetable.id]?.start_time?.type ===
                        "required" && (
                        <div className="invalid-feedback">
                          The start time field is required.
                        </div>
                      )}
                      {errors[timetable.id]?.start_time?.type === "manual1" && (
                        <div className="invalid-feedback">
                          Start time is already used in some other place. Please
                          use another.
                        </div>
                      )}
                    </td>

                    <td data-th="end_time">
                      <select
                        className={`form-control ${
                          errors[timetable.id]?.end_time && "is-invalid"
                        }`}
                        {...register(`${timetable.id}.end_time`, {
                          required: true,
                          minLength: 1,
                        })}
                        onChange={(e) =>
                          timeHandler(timetable.id, "end_time", e.target.value)
                        }
                      >
                        {
                          // TIME_SLOTS.slice(timetable.startTimeKey)
                          TIME_SLOTS.filter(
                            (_, id) => timetable.startTimeKey <= id
                          ).map((time, idx) => (
                            <option value={time} key={time}>
                              {time}
                            </option>
                          ))
                        }
                      </select>
                      {errors[timetable.id]?.end_time?.type === "required" && (
                        <div className="invalid-feedback">
                          The end time field is required.
                        </div>
                      )}
                      {errors[timetable.id]?.end_time?.type === "manual" && (
                        <div className="invalid-feedback">
                          The end time should be greater than start time.
                        </div>
                      )}
                      {errors[timetable.id]?.end_time?.type === "manual1" && (
                        <div className="invalid-feedback">
                          The end time is already used in some other place.
                          Please use another.
                        </div>
                      )}
                      {errors[timetable.id]?.end_time?.type === "manual2" && (
                        <div className="invalid-feedback">
                          Overlapping Error
                        </div>
                      )}
                    </td>
                    <td data-th="ACTION">
                      <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={() =>
                          deleteHandler(
                            timetable.id,
                            timetable.id_customer_hours
                          )
                        }
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
};

export default Timetable;
