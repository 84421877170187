import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MagazzinosideBar from "../../../component/sidebar/MagazzinosideBar";
import Header from "../../../component/header/Header";
import Pagination from "../../../component/Pagination/Pagination";
import useRequest from "../../../hooks/useRequest";
import Loading from "../../../component/Loading/Loading";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { toast } from "react-toastify";


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { debounce } from "lodash";
const searchQueryHandler = (
  page,
  per_page,
  article_name = "",
  provider_name = "",
  id_warehouse = "",
  sortingName = "",
  sortOrderName = "",
  checkbox,
  reorder
) => {
  return `get-all-tiding-up-data?page=${page}&per_page=${per_page}&article_name=${article_name}&provider_name=${provider_name}&id_warehouse=${id_warehouse}&sort_by=${sortingName}&order_by=${sortOrderName}&checkbox=${checkbox}&reorder=${reorder}`;
};

const Units = [
  {value:"PZ",label:"Pezzi"},{value:"KG",label:"Kilogrammi"},{value:"CT",label:"Cartoni"}
]

const defaultSearchOrderObj = {
  article_name: "asc",
  suplier_number: "asc",
  um: "asc",
  expiring_date: "asc"
  , quantity: "asc"
  , ai_reorder: "asc",
  operative_reorder: "asc",
  stock_target: "asc",
  data_arrivo: "asc",
  promo: "asc",
  instock: "asc",
  incoming: "asc",
  outgoing: "asc"
}

function Riordino() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    clearErrors,
  } = useForm();

  let username = localStorage.getItem("warehouse_code");
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [agents, setAgents] = useState([]);
  const [agents1, setAgents1] = useState([]);
  const [searchArticle, setSearchArticle] = useState("");
  const [searchProvider, setSearchProvider] = useState("");
  const [sortOrderObj, setSortOrderObj] = useState(defaultSearchOrderObj);
  const [checkedFilter, setCheckedFilter] = useState(false);
  const [reorderFilter, setReorderFilter] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(new Date());

  const TH = [
    {
      "name": "Articolo",
      "width": "16%",
      key: 'article_name'
    },
    {
      "name": "Fornitore",
      "width": "16%",
      key: 'suplier_number'
    },
    {
      "name": "Um Purchase",
      "width": "4%",
      key: 'um'

    },
    {
      "name": "Scadenza",
      "width": "7%",
      key: 'expiring_date'

    },
    {
      "name": "Quantity",
      "width": "5%",
      key: 'quantity'

    },
    {
      "name": "Riordina",
      "width": "5%",
      key: 'suplier_ai_reordernumber'

    },
    {
      "name": "Riordino operativo",
      "width": "5%",
      key: 'operative_reorder'

    },
    {
      "name": "Stock ottimo",
      "width": "5%",
      key: 'stock_target'

    },
    {
      "name": "Consegna",
      "width": "7%",
      key: 'data_arrivo'

    },
    {
      "name": "Promo",
      "width": "5%",
      key: 'promo'

    },
    {
      "name": "In stock",
      "width": "5%",
      key: 'instock'

    },
    {
      "name": "In arrivo",
      "width": "5%",
      key: 'incoming'

    },
    {
      "name": "In uscita",
      "width": "5%",
      key: 'outgoing'

    }
  ]
  const [indexId, setIndex] = useState([]);
  const [userId, setuserID] = useState(username);
  const [sortingName, setSortingName] = useState('')
  const [show, setShow] = useState(false);
  const [sortOrderName, setSortOrderName] = useState('asc')
  const [sortOrderCheckBoxName, setSortOrderCheckBoxName] = useState('desc')
  const [modelData, setModelData] = useState([])
  
  const { request, response } = useRequest();
  const { request: userDatarequest, response: userDataresponse } = useRequest();
  const { request: modelDatarequest, response: modelDataresponse } = useRequest();

  useEffect(() => {
    setPage(1);
    request(
      "GET",
      searchQueryHandler(1, perPage, searchArticle, searchProvider, userId, sortingName, sortOrderName, checkedFilter, reorderFilter)
    );
  }, [userId, checkedFilter, reorderFilter]);
  

  useEffect(() => {
    if (response) {
      setTotalDocuments(response.data?.reorder_data?.total_records);
      if(response?.data?.reorder_data?.results.length > 0){
        setAgents(
          response?.data?.reorder_data?.results.map((e, index) => {
            return { ...e, id: index };
          })
        );
        setAgents1(
          response?.data?.reorder_data?.results.map((e, index) => {
            return { ...e, id: index };
          })
        );
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setAgents([]);
    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        searchArticle,
        searchProvider,
        userId,
        sortingName,
        sortOrderName,
        checkedFilter,
        reorderFilter
      )
    );
  };

  const onSearchArticleHandler = () => {
    request(
      "GET",
      searchQueryHandler(1, perPage, searchArticle, searchProvider, userId, sortingName, sortOrderName, checkedFilter, reorderFilter)
    );
    setPage(1);
  };
  const onProviderSearchHandler = () => {
    request(
      "GET",
      searchQueryHandler(1, perPage, searchArticle, searchProvider, userId, sortingName, sortOrderName, checkedFilter, reorderFilter)
    );
    setPage(1);
  };
  const perPageChangeHandler = (event) => {
    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        searchArticle,
        searchProvider,
        userId,
        sortingName,
        sortOrderName,
        checkedFilter,
        reorderFilter
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };
  const isCheck = (e, id) => {
    const newTableRow = [...agents];
    const newTableRow1 = [...agents1];
    const index = agents.findIndex((data) => data.id === id.id);
    newTableRow[index].checkbox = e.target.checked;
    const index1 = agents1.findIndex((data) => data.id === id.id);
    newTableRow1[index1].checkbox = e.target.checked;
    setAgents(newTableRow);
    setIndex(newTableRow);
    setAgents1(newTableRow1);
  };

  const ModelBoxOpen = (e, agent) => {
    setModelData(agent)
    setShow(true)
    const newAgents = agents.filter((item, index) => item.id == agent.id)
    reset({
      article_name: newAgents[0]?.article_name,
      suplier_number: newAgents[0]?.suplier_number,
      um_purchase: newAgents[0]?.um_reorder,
      um: newAgents[0]?.um,
      quantity: newAgents[0]?.quantity,
      price:newAgents[0]?.purchase_price,
      promo:newAgents[0]?.promo,
      note: newAgents[0]?.note,
      pieces_for_packages:newAgents[0]?.pieces_for_packages
    })
    // console.log(newAgents)
  }

  useEffect(()=>{
      if(modelDataresponse){
        if(modelDataresponse.status == "SUCCESS"){
            toast.success(modelDataresponse.message)
            setShow(false)
             request(
      "GET",
      searchQueryHandler(1, perPage, searchArticle, searchProvider, userId, sortingName, sortOrderName, checkedFilter, reorderFilter)
    );
        }else if(modelDataresponse.status == "ERROR"){
          toast.error(modelDataresponse.message)
        }

      }
  },[modelDataresponse])

  const handleCheckSort = (sortOrder,name) => {
    let OneArray = []
   const newArray = agents.map((val) => {
       if(val.checkbox == true){
          return val
       }
    }).filter(Boolean);
    const newArray1 = agents.map((val1) => {
      if(val1.checkbox == false){
         return val1
      }
   }).filter(Boolean);
   if(sortOrder == "asc"){
     OneArray = newArray.concat(newArray1)
     setSortOrderCheckBoxName("desc");
   }else{
    OneArray =newArray1.concat(newArray)
    setSortOrderCheckBoxName("asc");
   }
   setAgents(OneArray)
  }

  const handleSort = (sortOrder, name) => {
    if(name == "suplier_number"){
      request("GET", searchQueryHandler(1, perPage, searchArticle, searchProvider, userId,name, sortOrderName, checkedFilter, reorderFilter));
            setSortOrderObj(prev => {
      return { ...prev, [name]: sortOrder }
    })

    if (sortOrderName == "asc") {
      setSortOrderName("desc");
    } else {
      setSortOrderName("asc");
    }
    }else{
    let arrayToSort = agents.map((val) => {
      return { id: val.id, key: val[name] }
    });

    let filterArray = arrayToSort.filter((newVal)=> newVal.key == null)
    let unfilterArray = arrayToSort.filter((newVal)=> newVal.key !== null)
    let sortedArray = []
    let date = moment(arrayToSort[0].key, "DD-MM-YYYY")
    if (!isNaN(arrayToSort[0].key)) {
        sortedArray = unfilterArray.sort((a, b) => {
          if (sortOrder === "asc") {
            return a.key - b.key
          } else {
            return b.key - a.key
          }
        })
      sortedArray = [...sortedArray,...filterArray]
    } else if (date.isValid()) {
      // console.log("type date")
      sortedArray = arrayToSort.sort((a, b) => {
        a = +moment(a.key, "DD/MM/YYYY").format('YYYYMMDD')
        b = +moment(b.key, "DD/MM/YYYY").format('YYYYMMDD')

        if (sortOrder === "asc") {
          return a - b
        } else {
          return b - a
        }
      })
    } else {
      sortedArray = arrayToSort.sort().reverse()      
    }
    let sortedAgent = sortedArray.map((val) => {
      let result = {};
      agents.forEach((subVal) => {
        if (subVal.id === val.id) {
          result = subVal
        }
      })
      return result
    })

    setAgents(sortedAgent)
    setSortOrderObj(prev => {
      return { ...prev, [name]: sortOrder }
    })

    if (sortOrderName == "asc") {
      setSortOrderName("desc");
    } else {
      setSortOrderName("asc");
    }
    }

  };
  const handleChangeWithSearch = debounce((searchArticle) => {
    request("GET", searchQueryHandler(1, perPage, searchArticle, searchProvider, userId, sortingName, sortOrderName, checkedFilter, reorderFilter));
  }, 500);

  const handleChangeWithSearchProvider = debounce((searchProvider) => {
    request("GET", searchQueryHandler(1, perPage, searchArticle, searchProvider, userId, sortingName, sortOrderName, checkedFilter, reorderFilter));
  }, 500);

  const onSubmit = (data) => {
     const sendData = {
      id_supplier:modelData.id_supplier,
      id_article:modelData.id_article,
      purchase_price:data.price,
      um_purchase:modelData.role,
      // quantity:(modelData.quantity == null) ? 0 : modelData.quantity,
      quantity:data.quantity ? data.quantity : 0,
      missing_quantity:data.quantity,
      arrival_date:modelData.data_arrivo,
      um_purchase:data.um_purchase,
      note:data.note
     }
     modelDatarequest("POST", "reorder", sendData);
  }

  return (
    <>
      <Header setuserID={setuserID} />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <MagazzinosideBar />
        <div className="dashboard-content">
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Magazzino</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Riordino</Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="pagesearch mr-2">
              <div className="pagesearch-block" style={{marginRight:"20px"}}>
                <div className="row">
                  <div className="col-md-6" style={{marginRight:"20px"}}>
                    <input
                      type="checkbox"
                      checked={checkedFilter}
                      onChange={(e) => { setCheckedFilter(checkedFilter ? false : true)}}
                      style={{width: "20px", height: "20px"}}
                    />
                  </div>
                  <div className="col-md-6">
                    <button type="button" className="btn btn-primary btn-sm" style={{width:"110px", height:"35px"}} onClick={(e) => { setReorderFilter(reorderFilter !== 0 ? 0 : null)}}>
                      Riordino operativo
                    </button>
                  </div>
                </div>
              </div>
              <div className="pagesearch-block">
                <input
                  type="text"
                  placeholder="Search Articolo"
                  value={searchArticle}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchArticle(e.target.value);
                    handleChangeWithSearch(e.target.value);
                  }}
                />
                <button onClick={onSearchArticleHandler}>
                  <i className="fas fa-search" />
                </button>
              </div>
              <div className="pagesearch-block ">
                <input
                  type="text"
                  placeholder="Search fornitore"
                  value={searchProvider}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchProvider(e.target.value);
                    handleChangeWithSearch(e.target.value);
                  }}
                />
                <button onClick={onProviderSearchHandler}>
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
          {/* Page Content */}
          <div className="pageContent">
            <div className="themeTable customerTable">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    {/* <th style={{ width: "16%" , textAlign: "center" }}>
                      <input
                        type="checkbox"
                        class="btn btn-lg btn-toggle"
                        id="customSwitch1"
                        data-toggle="button" aria-pressed="false" autocomplete="off"
                        onChange={(e) => handleSort(e)}
                      />
                      <a
                        onClick={() => {
                          handleSort(sortOrderName);
                        }}
                      >
                        {" "}
                        {sortOrderName === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : sortOrderName === "desc" ? (
                          <i class="fas fa-arrow-down"></i>
                        ) : (
                          ""
                        )}
                      </a>
                    </th>
                    {
                      TH.map((item,index)=>{
                        const thData = TH[index]
                         return(
                          <>
                    <th style={{ width:thData.width, textAlign: "right" }}>
                    <a
                        onClick={() => {
                          handleSort(sortOrderObj[item],item);
                        }}
                        className="changeLeftShow"
                      >
                        {" "}
                        {sortOrderObj[item] === 'asc'? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        ) 
                        
                        }
                      </a>
                      {thData.name}
                    </th>
                          </>
                         )

                      })
                    } */}
                    <th
                      // onClick={() => {
                      //   handleCheckSort(sortOrderCheckBoxName,"checkValue");
                      // }}
                      style={{ width: "3%", textAlign: "center" }}>
                      {/* <input
                        type="checkbox"
                        class="btn btn-lg btn-toggle"
                        id="customSwitch1"
                        data-toggle="button" aria-pressed="false" autocomplete="off"
                        onChange={(e) => handleSort(e)}
                      /> */}
                      <a

                      >
                        {" "}
                        {sortOrderCheckBoxName === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : sortOrderCheckBoxName === "desc" ? (
                          <i class="fas fa-arrow-down"></i>
                        ) : (
                          ""
                        )}
                      </a>
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "article_name");
                      }} style={{ width: "16%", textAlign: "right" }}>

                      <a

                        className="changeLeftShow"
                      >
                        {" "}
                        {sortOrderObj['article_name'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // : (
                          //   ""
                          // )
                        }
                      </a>
                      Articolo
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "suplier_number");
                      }}
                      style={{ width: "16%", textAlign: "right" }}>
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['suplier_number'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          //  : (
                          //   ""
                          // )
                        }
                      </a>
                      Fornitore
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "um");
                      }}
                      style={{ width: "4%", textAlign: "right" }}>
                      {" "}
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['um'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // (
                          //   ""
                          // )
                        }
                      </a>
                      Um Purchase
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "expiring_date");
                      }}
                      style={{ width: "7%", textAlign: "right" }}>
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['expiring_date'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // : (
                          //   ""
                          // )
                        }
                      </a>
                      Scadenza
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "quantity");
                      }}
                      style={{ width: "5%", textAlign: "right" }}>
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['quantity'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          //  : (
                          //   ""
                          // )
                        }
                      </a>
                      Quantity</th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "ai_reorder");
                      }}
                      style={{ width: "5%", textAlign: "right" }}>
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['ai_reorder'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // :
                          //  (
                          //   ""
                          // )
                        }
                      </a>
                      Riordina{" "}
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "operative_reorder");
                      }}
                      style={{ width: "5%", textAlign: "right" }}>
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['operative_reorder'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // (
                          //   ""
                          // )
                        }
                      </a>
                      Riordino operativo
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "stock_target");
                      }}
                      style={{ width: "5%", textAlign: "right" }}>
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['stock_target'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // : 
                          // (
                          //   ""
                          // )
                        }
                      </a>
                      Stock ottimo
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "data_arrivo");
                      }}
                      style={{ width: "7%", textAlign: "right" }}>
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['data_arrivo'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // : (
                          //   ""
                          // )
                        }
                      </a>
                      Consegna
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "promo");
                      }}
                      style={{ width: "5%", textAlign: "right" }}>
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['promo'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // : (
                          //   ""
                          // )
                        }
                      </a>
                      Promo</th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "instock");
                      }}
                      style={{ width: "5%", textAlign: "right" }}>

                      {" "}
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['instock'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // : (
                          //   ""
                          // )
                        }
                      </a>
                      In stock
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "incoming");
                      }}
                      style={{ width: "5%", textAlign: "right" }}>
                      <a

                        className="changeLeftShow"

                      >
                        {" "}
                        {sortOrderObj['incoming'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // : (
                          //   ""
                          // )
                        }
                      </a>
                      In arrivo{" "}
                    </th>
                    <th
                      onClick={() => {
                        handleSort(sortOrderName, "outgoing");
                      }}
                      style={{ width: "5%", textAlign: "right" }}>
                      <a

                        className="changeLeftShow"
                      >
                        {" "}
                        {sortOrderObj['outgoing'] === "asc" ? (
                          <i class="fas fa-arrow-up"></i>
                        ) : (
                          <i class="fas fa-arrow-down"></i>
                        )
                          // : (
                          //   ""
                          // )
                        }
                      </a>
                      In uscita{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {agents.length > 0 ?
                    agents.map((agent, index) => (
                      <tr key={agent.id} >
                        <td style={{ textAlign: "center" }}>
                          <input
                            type="checkbox"
                            id={`checkbox-${agent.id}`}
                            name={`checkbox-name-${agent.id}`}
                            defaultValue=""
                            checked={agent.checkbox}
                            onChange={(e) => {
                              isCheck(e, { id: agent.id });
                            }}
                          />
                        </td>
                        <td data-th="Articolo" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.article_name}
                        </td>
                        <td data-th="Fornitore" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)} >
                          {agent.suplier_number}
                        </td>
                        <td data-th="Um Purchase" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.um}
                        </td>
                        <td data-th="Scadenza" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)} >
                          {agent.expiring_date}
                        </td>
                        <td data-th="Quantity" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.quantity}
                        </td>
                        <td data-th="Riordina" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.ai_reorder}
                        </td>
                        <td data-th="Riordino operativo" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.operative_reorder}
                        </td>
                        <td data-th="Stock ottimo" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.stock_target}
                        </td>
                        <td data-th="Consegna" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.data_arrivo}
                        </td>
                        <td data-th="Promo" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.promo === true ? (
                            <span
                              style={{
                                height: "25px",
                                width: "25px",
                                backgroundColor: "green",
                                padding: "3px",
                                borderRadius: "50%",
                              }}
                            >
                              p
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td data-th="In stock" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.instock}
                        </td>
                        <td data-th="In arrivo	" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.incoming}
                        </td>
                        <td data-th="In uscita" style={{ textAlign: "right" }} onClick={(e) => ModelBoxOpen(e, agent)}>
                          {agent.outgoing}
                        </td>
                      </tr>
                    ))
                    : <Loading />
                  }
                </tbody>
              </table>
            </div>
            {perPage !== 0 && (
              <Pagination
                page={page}
                totalRecords={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                defaultPerPage={10}
                perPageChangeHandler={perPageChangeHandler}
                currentDocLength={agents.length}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset({
            descrizione: "",
            tipo: "",
            codice: "",
          });
          clearErrors("descrizione");
          clearErrors("tipo");

          clearErrors("codice");
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-2 ">
                      <label>Fornitore</label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text"
                        className={`form-control ${errors.descrizione && "is-invalid"
                          }`}
                          disabled={true}

                        name="suplier_number"
                        id="suplier_number"
                        {...register("suplier_number", {
                          required: false,
                        })}
                      />
                      {errors.suplier_number?.type === "required" && (
                        <div className="invalid-feedback">
                          suplier number field is required.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-2 ">
                      <label>Articolo</label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text"
                        className={`form-control ${errors.article_name && "is-invalid"
                          }`}
                          disabled={true}
                        name="article_name"
                        id="article_name"
                        {...register("article_name", {
                          required: false,
                        })}
                      />
                      {errors.article_name?.type === "required" && (
                        <div className="invalid-feedback">
                          article name field is required.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-2 ">
                      <label>Unità</label>
                    </div>
                    <div className="col-lg-4">
                    <Controller
                            control={control}
                            name="um_purchase"
                            rules={{ required: false, minLength: 1 }}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Select
                                menuPosition="fixed"
                                options={Units}
                                value={Units && Units.find((item)=> item.value == value )}
                                placeholder="Select Unit"
                                className={`basic-multi-select ${
                                  errors.um_purchase && "is-invalid"
                                }`}
                                classNamePrefix="select"
                                onChange={(val) => onChange(val.value)}
                              />
                            )}
                          />
                          {errors.um_purchase?.type === "required" && (
                            <div className="invalid-feedback">
                              The um_purchase field is required.
                            </div>
                          )}
                      {/* <input
                        type="text"
                        className={`form-control ${errors.um && "is-invalid"
                          }`}
                        name="um"
                        id="um"
                        {...register("um", {
                          required: true,
                        })}
                      />
                      {errors.um?.type === "required" && (
                        <div className="invalid-feedback">
                          um field is required.
                        </div>
                      )} */}
                    </div>
                    <div className="col-lg-2 ">
                      <label>Quantita</label>
                    </div>
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className={`form-control ${errors.quantity && "is-invalid"
                          }`}
                        name="quantity"
                        id="quantity"
                        {...register("quantity", {
                          required: true,
                        })}
                      />
                      {errors.quantity?.type === "required" && (
                        <div className="invalid-feedback">
                          quantity field is required.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-2 ">
                      <label>PZ x CT</label>
                    </div>
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className={`form-control ${errors.tipo && "is-invalid"
                          }`}
                        name="pieces_for_packages"
                        id="pieces_for_packages"
                        {...register("pieces_for_packages", {
                          required: false,
                        })}
                      />
                      {errors.pieces_for_packages?.type === "required" && (
                        <div className="invalid-feedback">
                          pieces_for_packages field is required.
                        </div>
                      )}
                    </div>
                    <div className="col-lg-2 ">
                      <label>Consegna</label>
                    </div>
                    <div className="col-lg-4">
                        <DatePicker
                          placeholder="MM/DD//YYYY"
                          className={`form-control`}
                          selected={deliveryDate}
                          onChange={(date) => setDeliveryDate(date)}
                        />

                        {errors.deliveryDate?.type === "required" && (
                          <div className="invalid-feedback">
                            The delivery date field is required.
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-2 ">
                      <label>Prezzo</label>
                    </div>
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className={`form-control ${errors.tipo && "is-invalid"
                          }`}
                        name="price"
                        id="price"
                        {...register("price", {
                          required: true,
                        })}
                      />
                      {errors.price?.type === "required" && (
                        <div className="invalid-feedback">
                          price field is required.
                        </div>
                      )}
                    </div>
                    {/* <div className="col-lg-2 ">
                      <label>Promo price</label>
                    </div>
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className={`form-control ${errors.tipo && "is-invalid"
                          }`}
                        name="promo"
                        id="promo"
                        {...register("promo", {
                          required: false,
                        })}
                      />
                      {errors.promo?.type === "required" && (
                        <div className="invalid-feedback">
                          promo field is required.
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-2 ">
                      <label>Note</label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text"
                        className={`form-control ${errors.note && "is-invalid"
                          }`}
                        name="note"
                        id="note"
                        {...register("note", {
                          required: false,
                        })}
                      />
                      {errors.note?.type === "required" && (
                        <div className="invalid-feedback">
                          note field is required.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-pill btn-yellow" type="submit">
              SALVA
            </button>
            {/* <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={() => {
                    reset({
                      descrizione:'',
                      tipo:'',
                      codice:''
                    })
                    clearErrors("descrizione");
                    clearErrors("tipo");

                    clearErrors("codice");
                    setShow(false)
                  } 
                  }
                
                >
                  ANNULLA
                </button> */}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Riordino;
