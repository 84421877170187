import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AmministrazioneSideBar from "../../../../component/sidebar/AmministrazioneSideBar";
import Header from "../../../../component/header/Header";
import Pagination from "../../../../component/Pagination/Pagination";
import axios from "axios";
import { toast } from "react-toastify";
import useRequest from "../../../../hooks/useRequest";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Moment from "moment";
import { useForm, Controller } from "react-hook-form";

const AddDocumentiattivi = () => {
  let navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [expiringDate, setExpiringDate] = useState(new Date());
  const [amountexpiringDate, setamountExpiringDate] = useState(new Date());
  const [vatDate, setVatDate] = useState(new Date());
  const [agents, setAgents] = useState([]);
  const [agentName, setAgentName] = useState([]);
  const [street, setStreet] = useState([]);
  const [place, setPlace] = useState([]);
  const [Province, setProvince] = useState([]);
  const [country, setCountry] = useState([]);
  const [fiscalcode, setFiscalcode] = useState([]);
  const [vat, setVat] = useState([]);
  const [doctype, setdoctype] = useState([]);
  const [paymenttypes, setpaymenttypes] = useState([]);
  const [sellingorder, setsellingorder] = useState([]);
  const [delivery, setDelivery] = useState([]);

  const { request, response } = useRequest();
  const { request: requestAgent, response: responseAgent } = useRequest();
  const { request: requestDoctype, response: responseDoctype } = useRequest();
  const { request: requestpaymenttypes, response: responsepaymenttypes } =
    useRequest();
  const { request: requestSellingorder, response: responseSellingorder } =
    useRequest();
  const { request: requestDelivery, response: responseDelivery } = useRequest();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValue,
    reset,
  } = useForm();

  useEffect(() => {
    requestAgent("GET", "get-all-agent-selling-point");
    requestDoctype("GET", "doc-type");
    requestpaymenttypes("GET", "payment-types");
    requestSellingorder("GET", "selling-orders");
    requestDelivery("GET", "get-delivery");
  }, []);

  useEffect(() => {
    if (responseAgent) {
      if(responseAgent.status=="SUCCESS"){

          setAgents(
            responseAgent.data?.agentSellingPointData?.map((sellingPoint) => {
              return {
                value: sellingPoint.id_agent_sp,
                label: sellingPoint.sellingPointData?.description,
                agent: sellingPoint.agentData.naming,
                street: sellingPoint.agentData.address.street,
                place: sellingPoint.agentData.address.place,
                Province: sellingPoint.agentData.address.province.nomeprovincia,
                country: sellingPoint.agentData.address.country.nome,
                fiscalcode: sellingPoint.agentData.fiscal_code,
                vat: sellingPoint.agentData.vat,
              };
            })
          );
        }
    }
  }, [responseAgent]);

  useEffect(() => {
    if (responseDoctype) {
      setdoctype(
        responseDoctype.data?.docs?.map((doc) => {
          return {
            value: doc.id_doc_type,
            label: doc.descrizione,
          };
        })
      );
    }
  }, [responseDoctype]);

  useEffect(() => {
    if (responsepaymenttypes) {
      if(Object.keys(responsepaymenttypes?.data).length>0){

        setpaymenttypes(
          responsepaymenttypes.data?.map((pay) => {
            return {
              value: pay.id_payment_type,
              label: pay.descrizione,
            };
          })
        );
      }
    }
  }, [responsepaymenttypes]);

  useEffect(() => {
    if (responseSellingorder) {
      if(responseSellingorder.status=="SUCCESS" ){
        setsellingorder(
          responseSellingorder.data?.map((sell) => {
            return {
              value: sell.id_selling_order,
              label: sell.id_selling_order,
            };
          })
        );
      }
    }
  }, [responseSellingorder]);

  useEffect(() => {
    if (responseDelivery) {
      if (responseDelivery.status === "SUCCESS") {

        setDelivery(
          responseDelivery.data?.deliveryData?.map((delivery) => {
            return {
              value: delivery.id_delivery,
              label: delivery.id_delivery,
            };
          })
        );
      }
      }
  }, [responseDelivery]);
  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success(response.message);
        navigate("/documentiattivi");
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    data["account_data_exp"] = Moment(amountexpiringDate).format("MM-DD-YYYY");
    data["date_vat_protocol"] = Moment(vatDate).format("MM-DD-YYYY");
    data["expiring_date"] = Moment(expiringDate).format("MM-DD-YYYY");
    data["issue_date"] = Moment(startDate).format("MM-DD-YYYY");
    request("POST", "add-active-document", data);
  };

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <AmministrazioneSideBar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">AMMINISTRAZIONE</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">CICLO ATTIVO</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/documentiattivi">DOCUMENTI ATTIVI</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">ADD DOCUMENTI ATTIVI</a>
                  </li>
                  {/* <li className="breadcrumb-item active" aria-current="page">
                    SLM00001
                  </li> */}
                </ol>
              </nav>
            </div>
          </div>

          <div className="pageContent">
            <div className="themeTabs my-4">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="data-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#data-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="data-tab-pane"
                    aria-selected="true"
                  >
                    Nuovo documento attivo
                  </button>
                </li>
              </ul>

              <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                <div className="tabH-full">
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Stato</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.state && "is-invalid"
                              }`}
                              name="state"
                              id="state"
                              {...register("state", {
                                required: true,
                              })}
                            />
                            {errors.state?.type === "required" && (
                              <div className="invalid-feedback">
                                Stato field is required.
                              </div>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Tipi Di Pagamento
</label>
                          </div>
                          <div className="col-lg-7">
                            <Controller
                              control={control}
                              rules={{ required: true, minLength: 1 }}
                              name="id_payment_type"
                              render={({ field: { onChange, value, ref } }) => (
                                <Select
                                  // menuPosition="fixed"
                                  options={paymenttypes}
                                  classNamePrefix="select"
                                  placeholder="Select"
                                  className={`basic-multi-select ${
                                    errors.id_payment_type && "is-invalid"
                                  }`}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                />
                              )}
                            />
                            {errors.id_payment_type?.type === "required" && (
                              <div className="invalid-feedback">
                                Modalità Di Pagamento field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Numero del documento</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.number && "is-invalid"
                              }`}
                              name=" number"
                              id=" number"
                              {...register("number", {
                                required: true,
                              })}
                            />
                            {errors.number?.type === "required" && (
                              <div className="invalid-feedback">
                                Numero Del Documento field is required.
                              </div>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Data Di Emissione</label>
                          </div>
                          <div className="col-lg-7">
                            <DatePicker
                              placeholder="MM/DD//YYYY"
                              className={`form-control`}
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                            />
                          </div>
                          <div className="col-lg-7">
                            {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Tipo Di Documento</label>
                          </div>
                          <div className="col-lg-7">
                            <Controller
                              control={control}
                              rules={{ required: true, minLength: 1 }}
                              name="id_doc_type"
                              render={({ field: { onChange, value, ref } }) => (
                                <Select
                                  // menuPosition="fixed"
                                  options={doctype}
                                  classNamePrefix="select"
                                  placeholder="Select"
                                  className={`basic-multi-select ${
                                    errors.id_provider && "is-invalid"
                                  }`}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                />
                              )}
                            />
                            {errors.id_doc_type?.type === "required" && (
                              <div className="invalid-feedback">
                                Seleziona un PV field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Seleziona un PV</label>
                          </div>
                          <div className="col-lg-7">
                            <Controller
                              control={control}
                              rules={{ required: true, minLength: 1 }}
                              name="id_provider"
                              render={({ field: { onChange, value, ref } }) => (
                                <Select
                                  // menuPosition="fixed"
                                  options={agents}
                                  classNamePrefix="select"
                                  placeholder="Select"
                                  className={`basic-multi-select ${
                                    errors.id_provider && "is-invalid"
                                  }`}
                                  onChange={(e) => {
                                    onChange(e.value);

                                    console.log(e, "asds");
                                    setAgentName(e.agent);
                                    setStreet(e.street);
                                    setPlace(e.place);
                                    setProvince(e.Province);
                                    setCountry(e.country);
                                    setFiscalcode(e.fiscalcode);
                                    setVat(e.vat);
                                  }}
                                />
                              )}
                            />
                            {errors.id_provider?.type === "required" && (
                              <div className="invalid-feedback">
                                Seleziona un PV field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Agente</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text "
                              className={`form-control`}
                              defaultValue={agentName}
                              disabled="true"
                            />
                          </div>
                          <div className="col-lg-7">
                            {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Indirizzo</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text "
                              className={`form-control`}
                              defaultValue={street}
                              disabled="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Posto</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text "
                              className={`form-control`}
                              defaultValue={place}
                              disabled="true"
                            />
                          </div>
                          <div className="col-lg-7">
                            {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Provincia</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text "
                              className={`form-control`}
                              defaultValue={Province}
                              disabled="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Nazione</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text "
                              className={`form-control`}
                              defaultValue={country}
                              disabled="true"
                            />
                          </div>
                          <div className="col-lg-7"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Partita IVA</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.vat && "is-invalid"
                              }`}
                              name="vat"
                              id="vat"
                              defaultValue={vat}
                              disabled="true"
                            />
                            {errors.vat?.type === "required" && (
                              <div className="invalid-feedback">
                                Partita IVA field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Codice fiscale</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              defaultValue={fiscalcode}
                              className={`form-control `}
                              name="fiscal_code"
                              id="fiscal_code"
                              disabled="true"
                            />
                          </div>
                          <div className="col-lg-7">
                            {errors.recap_notes?.type === "required" && (
                              <div className="invalid-feedback">
                                Codice fiscale field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Note Di Intestazione</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.header_note && "is-invalid"
                              }`}
                              name="header_note"
                              id="header_note"
                              {...register("header_note", {
                                required: true,
                              })}
                            />
                            {errors.header_note?.type === "required" && (
                              <div className="invalid-feedback">
                                Note Di Intestazione field is required.
                              </div>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Peso Totale</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.total_weight && "is-invalid"
                              }`}
                              name="total_weight"
                              id="total_weight"
                              {...register("total_weight", {
                                required: true,
                              })}
                            />
                            {errors.total_weight?.type === "required" && (
                              <div className="invalid-feedback">
                                Peso Totale field is required.
                              </div>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {errors.recap_notes?.type === "required" && (
                              <div className="invalid-feedback">
                                Note Di Riepilogofield is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Imposta Totale</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.total_tax && "is-invalid"
                              }`}
                              name="total_tax"
                              id="total_tax"
                              {...register("total_tax", {
                                required: true,
                              })}
                            />
                            {errors.total_tax?.type === "required" && (
                              <div className="invalid-feedback">
                                Imposta Totale field is required.
                              </div>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Data di scadenza</label>
                          </div>
                          <div className="col-lg-7">
                            <DatePicker
                              placeholder="MM/DD//YYYY"
                              className={`form-control`}
                              selected={expiringDate}
                              onChange={(date) => setExpiringDate(date)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Entrate totali</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.total_revenue && "is-invalid"
                              }`}
                              name="total_revenue"
                              id="total_revenue"
                              {...register("total_revenue", {
                                required: true,
                              })}
                            />
                            {errors.total_revenue?.type === "required" && (
                              <div className="invalid-feedback">
                                Entrate totali field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Nota documento</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.doc_notes && "is-invalid"
                              }`}
                              name="doc_notes"
                              id="doc_notes"
                              {...register("doc_notes", {
                                required: true,
                              })}
                            />
                            {errors.doc_notes?.type === "required" && (
                              <div className="invalid-feedback">
                                Nota documento field is required.
                              </div>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {errors.recap_notes?.type === "required" && (
                              <div className="invalid-feedback">
                                Nota documento field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Note Sul Programma</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.total_tax && "is-invalid"
                              }`}
                              name="schedule_notes"
                              id="schedule_notes"
                              {...register("schedule_notes", {
                                required: true,
                              })}
                            />
                            {errors.schedule_notes?.type === "required" && (
                              <div className="invalid-feedback">
                                Note Sul Programma field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Bandiera</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="checkbox"
                              className="form-check-input checklg"
                              name="active_flag"
                              id="active_flag"
                              {...register("active_flag")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Pagare</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.to_pay && "is-invalid"
                              }`}
                              name="to_pay"
                              id="to_pay"
                              {...register("to_pay", {
                                required: true,
                              })}
                            />
                            {errors.to_pay?.type === "required" && (
                              <div className="invalid-feedback">
                                Pagare field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Consegna</label>
                          </div>
                          <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_delivery"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      // menuPosition="fixed"
                                      options={delivery}
                                      classNamePrefix="select"
                                      placeholder="Select"
                                      className={`basic-multi-select ${
                                        errors.id_delivery && "is-invalid"
                                      }`}
                                      onChange={(e) => {onChange(e.value)}}
                                        
                                    />
                                  )}
                                />
                                {errors.id_delivery?.type === "required" && (
                                  <div className="invalid-feedback">
                                  Consegna field is required.
                                  </div>
                                )}
                              </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Ordine Di Vendita</label>
                          </div>

                          <div className="col-lg-7">
                            <Controller
                              control={control}
                              rules={{ required: true, minLength: 1 }}
                              name="id_selling_order"
                              render={({ field: { onChange, value, ref } }) => (
                                <Select
                                  // menuPosition="fixed"
                                  options={sellingorder}
                                  classNamePrefix="select"
                                  placeholder="Select"
                                  className={`basic-multi-select ${
                                    errors.id_selling_order && "is-invalid"
                                  }`}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                />
                              )}
                            />
                            {errors.id_selling_order?.type === "required" && (
                              <div className="invalid-feedback">
                                Seleziona un PV field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Assegnato</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.assigned && "is-invalid"
                              }`}
                              name="assigned"
                              id="assigned"
                              {...register("assigned", {
                                required: true,
                              })}
                            />
                            {errors.assigned?.type === "required" && (
                              <div className="invalid-feedback">
                                Assegnato field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Numero di scatole</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.number_boxes && "is-invalid"
                              }`}
                              name="number_boxes"
                              id="number_boxes"
                              {...register("number_boxes", {
                                required: true,
                              })}
                            />
                            {errors.number_boxes?.type === "required" && (
                              <div className="invalid-feedback">
                                Numero di scatole field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Protocollo Iva</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.vat_protocol && "is-invalid"
                              }`}
                              name="vat_protocol"
                              id="vat_protocol"
                              {...register("vat_protocol", {
                                required: true,
                              })}
                            />
                            {errors.vat_protocol?.type === "required" && (
                              <div className="invalid-feedback">
                                Protocollo Iva field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Data Protocollo Iva</label>
                          </div>
                          <div className="col-lg-7">
                            <DatePicker
                              placeholder="MM/DD//YYYY"
                              className={`form-control`}
                              selected={vatDate}
                              onChange={(date) => setVatDate(date)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Invia trigger di posta</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control ${
                                errors.trigger_send_mail && "is-invalid"
                              }`}
                              name="trigger_send_mail"
                              id="trigger_send_mail"
                              {...register("trigger_send_mail", {
                                required: true,
                              })}
                            />
                            {errors.trigger_send_mail?.type === "required" && (
                              <div className="invalid-feedback">
                                Invia trigger di posta field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Data di scadenza del conto</label>
                          </div>
                          <div className="col-lg-7">
                            <DatePicker
                              placeholder="MM/DD//YYYY"
                              className={`form-control`}
                              selected={amountexpiringDate}
                              onChange={(date) => setamountExpiringDate(date)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Note di riepilogo</label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.recap_notes && "is-invalid"
                              }`}
                              name="recap_notes"
                              id="recap_notes"
                              {...register("recap_notes", {
                                required: true,
                              })}
                            />
                            {errors.recap_notes?.type === "required" && (
                              <div className="invalid-feedback">
                                Note di riepilogo field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-md-4 mt-3 text-center text-md-start">
                  <button className="btn btn-pill btn-yellow">SALVA</button>
                  <button
                    className="btn btn-pill btn-blue ms-2 ms-md-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    ANNULLA
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDocumentiattivi;
