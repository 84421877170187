import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSuccess, logout } from "../../store/auth/action";
import Moment from "moment";
import { Link, useLocation } from "react-router-dom";

const CUSTOMERROUTES = [
  "/clienti",
  "/puntovendita",
  "/addClienti",
  "/editClienti",
  "/puntovendita/add",
  "/puntovendita/edit/:id"
];


function DashBoard() {
  const { pathname } = useLocation();
  let time = null;
  const dispatch = useDispatch();
  let username = localStorage.getItem("username");
  const loginTime = Moment(new Date()).format("DD-MM-YYYY, hh:mm:ss");

  const  [dateTime, setDateTime] = useState(loginTime);

  useEffect(() => {
    SetTimer();

    return () => {
      clearInterval(time);
      time = null;
    };
  }, []);

  const SetTimer = () => {
    time = setInterval(() => {
      setDateTime(Moment(new Date()).format("DD-MM-YYYY, hh:mm:ss"));
    }, 1000);

  };

  // toggler
  const [toggleNavbar, setToggleNavbar] = useState(false);
  useEffect(() => {
    if (toggleNavbar) {
      document.body.classList.add("scroll-off");
    } else {
      document.body.classList.remove("scroll-off");
    }
  }, [toggleNavbar]);


  const date= Moment(new Date()).format("DD-MM-YYYY")

  return (
    <div className="bg-with-h100 dashbox-page">
      <div className="overlay"  
          style={toggleNavbar ? null : { display: "none" }}
          onClick={() => setToggleNavbar(!toggleNavbar)}></div>

      <div className="headerDashboard">
        <div className="headerDashboard-logo">
          <a href="#">
            <img src="/img/logo.png" alt="logo" />
          </a>
        </div>
        <div className="otherLinks">
          <a href="#">
            <i className="far fa-plus me-2"></i>
            <span>AGGIUNGI UTENTE</span>
          </a>
        </div>
        {/* <div className="ms-auto">
          <select className="form-select form-control pe-4 pe-md-5 me-1 me-md-0">
            <option value="it">Italian</option>
            <option value="en">English</option>
          </select>
        </div> */}
        <div className="headerLinks">
          <nav className="navbar navbar-expand-lg p-0">
            <button className={toggleNavbar ? `navbar-toggler menu-opened` : `navbar-toggler`} onClick={() => setToggleNavbar(!toggleNavbar)} type="button">
              <span className="navbar-toggler-icon" />
            </button>
            <div className={toggleNavbar ? `collapse navbar-collapse menu-show` : `collapse navbar-collapse`}>
              <ul className="navbar-nav ms-md-auto">
                <li className="nav-item">
                  <a className="nav-link" href="#!">
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 27.04 27.29"
                      >
                        <path
                          fill="currentColor"
                          d="M27.04,14.81c.04,6.86-5.48,12.45-12.34,12.49S2.26,21.81,2.22,14.95c0-.54,.03-1.08,.1-1.62,.4-2.62-.02-2.2,6.52-.24,2.02,.6,5.84,1.71,5.84,1.71,.12,0-2.37-10.12-2.79-11.54-.26-.9,1.81-.83,2.79-.83,6.83,0,12.37,5.54,12.37,12.37h0Z"
                        />
                        <path
                          fill="currentColor"
                          d="M24.55,14.82c.03,5.48-4.38,9.94-9.85,9.98-5.48,.03-9.94-4.38-9.98-9.85,0-.43,.02-.87,.08-1.3,.32-2.09-.01-1.76,5.2-.19,1.61,.49,4.67,1.37,4.67,1.37,.1,0-1.89-8.08-2.23-9.21-.21-.72,1.44-.66,2.23-.66,5.46,0,9.88,4.42,9.88,9.88h0Z"
                        />
                        <path
                          fill="currentColor"
                          d="M.34,8.51l11.25,3.48c.08,.02,.16-.02,.18-.1,0-.02,0-.05,0-.07L9.02,.39c-.07-.27-.34-.44-.61-.38h-.04C4.52,1.23,1.45,4.14,.03,7.9c-.09,.25,.05,.52,.3,.6h.02Z"
                        />
                        <path
                          fill="currentColor"
                          d="M2.93,9.22l8.7,2.68c.06,.02,.12-.01,.14-.07,0-.02,0-.04,0-.06L9.64,2.93c-.05-.21-.26-.34-.47-.29h-.03c-2.97,.94-5.34,3.19-6.45,6.1-.07,.19,.04,.4,.23,.47h0Z"
                        />
                        <circle
                          fill="currentColor"
                          cx="14.62"
                          cy="21.56"
                          r="1.27"
                        />
                        <circle
                          fill="currentColor"
                          cx="19.89"
                          cy="12.29"
                          r="1.27"
                        />
                        <circle
                          fill="currentColor"
                          cx="7.96"
                          cy="7.59"
                          r="1.27"
                        />
                        <path
                          fill="currentColor"
                          d="M18.36,20.29c0,.16,.06,.32,.17,.44,.21,.22,.27,.06,.94,.06"
                        />
                        <path
                          fill="currentColor"
                          d="M20.6,17.87c.16,0,.32-.06,.45-.17,.22-.21,.05-.28,.05-.94"
                        />
                        <path
                          fill="currentColor"
                          d="M17.33,15.69c.08,0,.52,.04,.45-.17-.17-.49,.05-.28,.05-.94"
                        />
                        <path
                          fill="currentColor"
                          d="M9.79,14.58c0,.16,.06,.32,.16,.44,.21,.22,1.15,.12,1.82,.12"
                        />
                        <path
                          fill="currentColor"
                          d="M11.82,18.21c.2,0,.4-.05,.57-.17,.35-.33,.53-.81,.47-1.28"
                        />
                        <path
                          fill="currentColor"
                          d="M7.82,18.58c.12,0,.8,.05,.7-.22-.26-.62,.09-.35,.09-1.21"
                        />
                        <path
                          fill="currentColor"
                          d="M16.49,6.87c0,.16,.06,.32,.16,.44,.21,.22,.27,.06,.94,.06"
                        />
                        <path
                          fill="currentColor"
                          d="M19.45,9.02c.08,0,.12,.35,.28,.2,.22-.21,.22-.65,.22-1.31"
                        />
                        <path
                          fill="currentColor"
                          d="M15.13,9.78c.08,0,.51,.04,.45-.17-.16-.49,.05-.27,.05-.94"
                        />
                        <path
                          fill="currentColor"
                          d="M7.28,4.07c0,.16,.06,.32,.16,.44,.21,.22,.27,.06,.94,.06"
                        />
                        <path
                          fill="currentColor"
                          d="M4.98,7.42c.08,0,.51,.04,.45-.17-.16-.49,.05-.27,.05-.94"
                        />
                      </svg>
                    </span>
                    LABORATORIO
                  </a>
                </li>

                <li  className={
                    pathname === "/clienti" || pathname === "/addClienti" || pathname === "/editClienti"
                      ? "nav-item    active"
                      : ""
                  } >
                  <a 
                        className={CUSTOMERROUTES.includes(pathname) ? "nav-link" : ""}
                  href="/clienti">
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 21.09 25.31"
                      >
                        <path
                          fill="currentColor"
                          d="M18.98,0H2.11V2.11H18.98V0ZM2.11,25.31H18.98v-2.11H2.11v2.11ZM18.98,4.22H2.11c-1.16,0-2.11,.95-2.11,2.11v12.65c0,1.16,.95,2.11,2.11,2.11H18.98c1.16,0,2.11-.95,2.11-2.11V6.33c0-1.16-.95-2.11-2.11-2.11Zm-8.44,2.9c1.31,0,2.37,1.06,2.37,2.37s-1.06,2.37-2.37,2.37-2.37-1.06-2.37-2.37h0c0-1.31,1.06-2.37,2.37-2.37Zm5.27,10.81H5.27v-1.58c0-1.76,3.51-2.64,5.27-2.64s5.27,.88,5.27,2.64v1.58Z"
                        />
                      </svg>
                    </span>
                    ANAGRAFICA
                  </a>
                </li>
                <li  >
                <a className="nav-link " href="/riordino">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 19.05 25.4"
                    >
                      <path
                        fill="currentColor"
                        d="M19.05,9.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.47h-3.17V1.59C15.87,.71,15.16,0,14.29,0H4.76c-.88,0-1.59,.71-1.59,1.59v1.59H0c0,2.01,1.28,3.8,3.17,4.47v1.88H0c0,2.01,1.27,3.81,3.17,4.48v1.88H0c0,2.07,1.35,3.9,3.32,4.52,.74,3.42,4.12,5.6,7.54,4.86,2.43-.53,4.33-2.43,4.86-4.86,1.97-.62,3.32-2.45,3.32-4.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.48Zm-9.52,11.11c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Z"
                      />
                    </svg>
                  </span>
                  MAGAZZINO
                </a>
              </li>
                <li className="nav-item">
                  <a className="nav-link"  href="/ordinidivendita">
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 19.05 25.4"
                      >
                        <path
                          fill="currentColor"
                          d="M19.05,9.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.47h-3.17V1.59C15.87,.71,15.16,0,14.29,0H4.76c-.88,0-1.59,.71-1.59,1.59v1.59H0c0,2.01,1.28,3.8,3.17,4.47v1.88H0c0,2.01,1.27,3.81,3.17,4.48v1.88H0c0,2.07,1.35,3.9,3.32,4.52,.74,3.42,4.12,5.6,7.54,4.86,2.43-.53,4.33-2.43,4.86-4.86,1.97-.62,3.32-2.45,3.32-4.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.48Zm-9.52,11.11c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Z"
                        />
                      </svg>
                    </span>
                    AMMINISTRAZIONE
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#!">
                    <span className="icon">
                      <svg viewBox="0 0 31.74 21.17">
                        <path
                          fill="currentColor"
                          d="M31.56,9.78C28.59,3.83,22.53,.05,15.87,0,9.22,.05,3.16,3.83,.19,9.78c-.26,.51-.26,1.1,0,1.61,2.97,5.95,9.03,9.73,15.68,9.78,6.65-.05,12.71-3.83,15.68-9.78,.25-.5,.24-1.1,0-1.61Zm-15.68,8.74c-4.38,0-7.94-3.55-7.94-7.94,0-4.38,3.55-7.94,7.94-7.94,4.38,0,7.94,3.55,7.94,7.94s-3.55,7.94-7.94,7.94h0Zm0-13.23c-.47,0-.94,.08-1.4,.21,.86,1.17,.61,2.82-.56,3.69-.93,.68-2.2,.68-3.13,0-.76,2.81,.9,5.71,3.72,6.48,2.81,.76,5.71-.9,6.48-3.72,.76-2.81-.9-5.71-3.72-6.48-.45-.12-.92-.18-1.39-.18h0Z"
                        />
                      </svg>
                    </span>
                    CONTROLLO
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#!">
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                        />
                      </svg>
                    </span>
                    CONFIGURAZIONE
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div className="headerDashboard-right">
          <div className="headerDashboard-user">
            <i>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-person"
                viewBox="0 0 16 16"
              >
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
              </svg>
            </i>
            <div className="headerDashboard-text">
              <span>
                {username}
              </span>
              <small>{date}</small>
            </div>
          </div>
          <a className="hd-signout" onClick={() => dispatch(logout())}>
            <svg version="1.1" viewBox="0 0 24 24">
              <g id="info" />
              <g id="icons">
                <g id="exit2">
                  <path d="M12,10c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2s-2,0.9-2,2v4C10,9.1,10.9,10,12,10z"></path>
                  <path d="M19.1,4.9L19.1,4.9c-0.3-0.3-0.6-0.4-1.1-0.4c-0.8,0-1.5,0.7-1.5,1.5c0,0.4,0.2,0.8,0.4,1.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0    c1.3,1.3,2,3,2,4.9c0,3.9-3.1,7-7,7s-7-3.1-7-7c0-1.9,0.8-3.7,2.1-4.9l0,0C7.3,6.8,7.5,6.4,7.5,6c0-0.8-0.7-1.5-1.5-1.5    c-0.4,0-0.8,0.2-1.1,0.4l0,0C3.1,6.7,2,9.2,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,9.2,20.9,6.7,19.1,4.9z"></path>
                </g>
              </g>
            </svg>
          </a>
        </div>
      </div>

      <div className="container-fluid px-md-5">
        <div className="row g-4">
          <div className="col-md-6 col-xl-3">
            <div className="dashcol-card">
              <h2>
                ANAGRAFICA
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 21.09 25.31"
                  >
                    <path
                      id="d"
                      className="e"
                      d="M18.98,0H2.11V2.11H18.98V0ZM2.11,25.31H18.98v-2.11H2.11v2.11ZM18.98,4.22H2.11c-1.16,0-2.11,.95-2.11,2.11v12.65c0,1.16,.95,2.11,2.11,2.11H18.98c1.16,0,2.11-.95,2.11-2.11V6.33c0-1.16-.95-2.11-2.11-2.11Zm-8.44,2.9c1.31,0,2.37,1.06,2.37,2.37s-1.06,2.37-2.37,2.37-2.37-1.06-2.37-2.37h0c0-1.31,1.06-2.37,2.37-2.37Zm5.27,10.81H5.27v-1.58c0-1.76,3.51-2.64,5.27-2.64s5.27,.88,5.27,2.64v1.58Z"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth="1px"
                    />
                  </svg>
                </span> 
              </h2>

              <div className="dashcol-inner">
                <ul className="list-unstyled mb-0">
                  <li>
                    <label>agenti</label>
                    <div className="dashcol-count">
                      20
                      <span className="text-success">
                        <i className="far fa-long-arrow-alt-up"></i>
                        +0.5%
                      </span>
                    </div>
                  </li>
                  <li>
                    <label>fornitori</label>
                    <div className="dashcol-count">
                      20
                      <span className="text-danger">
                        <i className="far fa-long-arrow-alt-down"></i>
                        -3.5%
                      </span>
                    </div>
                  </li>
                  <li>
                    <label>clienti</label>
                    <div className="dashcol-count">
                      2165
                      <span>-</span>
                    </div>
                  </li>
                  <li>
                    <label>articoli</label>
                    <div className="dashcol-count">
                      10890
                      <span className="text-success">
                        <i className="far fa-long-arrow-alt-up"></i>
                        +15%
                      </span>
                    </div>
                  </li>
                  <li>
                    <label>store attivi</label>
                    <div className="dashcol-count">
                      3<span>-</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xl-3">
            <div className="dashcol-card">
              <h2>
                AMMINISTRAZIONE
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 19.05 25.4"
                  >
                    <path
                      d="M19.05,9.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.47h-3.17V1.59C15.87,.71,15.16,0,14.29,0H4.76c-.88,0-1.59,.71-1.59,1.59v1.59H0c0,2.01,1.28,3.8,3.17,4.47v1.88H0c0,2.01,1.27,3.81,3.17,4.48v1.88H0c0,2.07,1.35,3.9,3.32,4.52,.74,3.42,4.12,5.6,7.54,4.86,2.43-.53,4.33-2.43,4.86-4.86,1.97-.62,3.32-2.45,3.32-4.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.48Zm-9.52,11.11c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Z"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth="1px"
                    />
                  </svg>
                </span>
              </h2>

              <div className="dashcol-inner">
                <ul className="list-unstyled mb-0">
                  <li>
                    <label>ordini di acquisto (ultimi 30 gg)</label>
                    <div className="dashcol-count">
                      2350
                      <span className="text-success">
                        <i className="far fa-long-arrow-alt-up"></i>
                        +0.5%
                      </span>
                    </div>
                  </li>
                  <li>
                    <label>ordini di vendita (ultimi 30 gg)</label>
                    <div className="dashcol-count">
                      1895
                      <span className="text-danger">
                        <i className="far fa-long-arrow-alt-down"></i>
                        -3.5%
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xl-3">
            <div className="dashcol-card">
              <h2>
                CONTROLLO
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 19.05 25.4"
                  >
                    <path
                      d="M19.05,9.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.47h-3.17V1.59C15.87,.71,15.16,0,14.29,0H4.76c-.88,0-1.59,.71-1.59,1.59v1.59H0c0,2.01,1.28,3.8,3.17,4.47v1.88H0c0,2.01,1.27,3.81,3.17,4.48v1.88H0c0,2.07,1.35,3.9,3.32,4.52,.74,3.42,4.12,5.6,7.54,4.86,2.43-.53,4.33-2.43,4.86-4.86,1.97-.62,3.32-2.45,3.32-4.52h-3.17v-1.88c1.9-.67,3.17-2.46,3.17-4.48Zm-9.52,11.11c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Zm0-6.35c-1.31,0-2.38-1.07-2.38-2.38,0-1.31,1.07-2.38,2.38-2.38s2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38h0Z"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth="1px"
                    />
                  </svg>
                </span>
              </h2>

              <div className="dashcol-inner">
                <ul className="list-unstyled mb-0">
                  <li>
                    <label>KPI</label>
                    <div className="dashcol-count">
                      225
                      <span className="text-success">
                        <i className="far fa-long-arrow-alt-up"></i>
                        +0.5%
                      </span>
                    </div>
                  </li>
                  <li>
                    <label>KPI 2</label>
                    <div className="dashcol-count">
                      46500
                      <span className="text-danger">
                        <i className="far fa-long-arrow-alt-down"></i>
                        -3.5%
                      </span>
                    </div>
                  </li>
                  <li>
                    <label>KPI 3</label>
                    <div className="dashcol-count">
                      840
                      <span>-</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xl-3">
            <div className="dashcol-card d-flex align-items-center justify-content-center">
              <div className="text-center">
                <h2 className="pe-0">CONFIGURAZIONE</h2>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 44.81 46.05"
                    width="36"
                    height="36"
                  >
                    {" "}
                    <path
                      fill="#ffffff"
                      data-name="ic settings 24px"
                      className="cls-1"
                      d="M39.14,25.23A18.1,18.1,0,0,0,39.3,23a17.94,17.94,0,0,0-.16-2.2l4.75-3.72a1.13,1.13,0,0,0,.27-1.44l-4.5-7.79a1.13,1.13,0,0,0-1.37-.5L32.68,9.62a16.4,16.4,0,0,0-3.8-2.21l-.86-6A1.09,1.09,0,0,0,26.91.5h-9a1.1,1.1,0,0,0-1.1,1l-.86,6a17.61,17.61,0,0,0-3.8,2.21L6.53,7.37a1.11,1.11,0,0,0-1.38.5L.65,15.66A1.12,1.12,0,0,0,.92,17.1l4.76,3.72a15.27,15.27,0,0,0,0,4.41L.92,29a1.14,1.14,0,0,0-.27,1.44l4.5,7.79a1.14,1.14,0,0,0,1.38.5l5.61-2.25a16.77,16.77,0,0,0,3.8,2.21l.86,6a1.1,1.1,0,0,0,1.1.95h9A1.1,1.1,0,0,0,28,44.6l.85-6a17.86,17.86,0,0,0,3.81-2.21l5.61,2.25a1.09,1.09,0,0,0,1.37-.5l4.5-7.79A1.14,1.14,0,0,0,43.88,29ZM22.41,30.91A7.89,7.89,0,1,1,30.29,23,7.89,7.89,0,0,1,22.41,30.91Z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="dashmid-logo">
          <span>
            <img src="/img/logo.png" alt="logo" />
          </span>
        </div>

        <div className="dashStorage">
          <div className="row row-cols-lg-5 g-4">
            <div className="col-xl">
              <div className="dashStorage-card">
                <h2>ORDINI IN RICEZIONE</h2>
                <div className="dashStorage-count">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 84.63 73.39"
                    >
                      <path
                        fill="currentColor"
                        d="M84.14,32,72,22.91l11.28-8.39a1.29,1.29,0,0,0,.49-1.17,1.24,1.24,0,0,0-.75-1L56.25.56A2.23,2.23,0,0,0,54,.83L42.49,9.58,30.27.5A2.54,2.54,0,0,0,27.72.22L1.91,11.79A1.88,1.88,0,0,0,1,14.3a2,2,0,0,0,.61.73l11.14,8-12,8.62a1.82,1.82,0,0,0,.32,3.12L13,40V58.83a2.93,2.93,0,0,0,1.76,2.7L41,73.07a3.8,3.8,0,0,0,1.52.32h0a3.8,3.8,0,0,0,1.52-.32L70,61.94a3,3,0,0,0,1.8-2.73V40.15L83.88,34.2a1.34,1.34,0,0,0,.61-1.79,1.22,1.22,0,0,0-.41-.49Zm-29-29.66a.36.36,0,0,1,.35,0L81.3,13.59,70.86,21.41a1.88,1.88,0,0,0-.56.69L44.35,10.56ZM42.49,34.68l-.12.08L16,23.51,42.41,11.85l.13.09.11-.09L69.07,23.58,42.63,34.76ZM2.67,13.51,28.5,2a.69.69,0,0,1,.68.07l11.49,8.56-26,11.5ZM13.73,24.59,40.62,36,28.89,44.53a.39.39,0,0,1-.39,0l-14.23-6h0L1.9,33.11Zm1.75,35.23a1.07,1.07,0,0,1-.65-1v-18L27.76,46.3a2.6,2.6,0,0,0,.87.18A2.31,2.31,0,0,0,30,46l11.59-8.39V71.28Zm53.73.42L43.48,71.3V37.65l11.41,8.13a2.72,2.72,0,0,0,2.6.3L69.94,41V59.22a1.1,1.1,0,0,1-.69,1Zm1.25-21.53-13.7,5.64a.82.82,0,0,1-.8-.09L44.41,36,71.28,24.65,82.34,32.9Z"
                      />
                    </svg>
                  </span>
                  42
                  <span className="text-danger">
                    <i className="far fa-long-arrow-alt-down"></i>
                    -7%
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xl">
              <div className="dashStorage-card">
                <h2>ORDINI IN SPEDIZIONE</h2>
                <div className="dashStorage-count">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 73.82 71.63"
                    >
                      <path
                        fill="currentColor"
                        idata-name="Sottrazione 10"
                        className="cls-1"
                        d="M43,71.63a6.08,6.08,0,0,1-5.83-4.29H18.64A6.11,6.11,0,0,1,7,67.34H0V31.07a1.24,1.24,0,0,1,1.24-1.2H34.6A1.24,1.24,0,0,1,35.7,31V34.7H47.1a1.25,1.25,0,0,1,1,.47L59.15,49.25a1.32,1.32,0,0,1,.26.77v4.75l0,0v2h0V66.1a1.24,1.24,0,0,1-1.24,1.24H48.85A6.07,6.07,0,0,1,43,71.63Zm0-10.39a4.28,4.28,0,0,0,0,8.56h0a4.28,4.28,0,1,0,0-8.56Zm-30.21,0a4.28,4.28,0,1,0,4.28,4.28,4.28,4.28,0,0,0-4.28-4.28ZM43,59.64a6.1,6.1,0,0,1,6.11,5.88h8.46V50.21L46.81,36.52H35.6V54a1.83,1.83,0,0,1-1.83,1.83H1.83v9.69H6.7a6.11,6.11,0,0,1,12.22,0h18A6.1,6.1,0,0,1,43,59.64ZM1.83,31.7V54.05H33.77V31.7ZM39.54,49.75a2.62,2.62,0,0,1-2.61-2.49V39.8a2.23,2.23,0,0,1,2.23-2.14h.08l4.37,0,.3,0a3.24,3.24,0,0,1,2.49,1.16l6.17,8.57a1.42,1.42,0,0,1,.09,1.52,1.6,1.6,0,0,1-1.4.83H39.54ZM39.22,39.5c-.27,0-.48.16-.48.29v7.45c0,.35.43.66.91.66H50.71l-5.83-8c-.15-.24-.58-.36-1.28-.36Zm2.92-6.44h0a1.63,1.63,0,0,1-1.63-1.48v-9.5a.78.78,0,0,1,1.56,0v9.39a.44.44,0,0,0,.22.05H56.19v0a.78.78,0,1,1,.06,1.56.06.06,0,0,1-.06,0h-14Z"
                      />
                      <path
                        id="Sottrazione_12"
                        data-name="Sottrazione 12"
                        className="cls-1"
                        d="M59.45,56.85V54.8A29,29,0,1,0,14.24,29.89h-2a30.8,30.8,0,1,1,47.21,27Z"
                      />
                    </svg>
                  </span>
                  42
                  <span className="text-success">
                    <i className="far fa-long-arrow-alt-up"></i>
                    +5%
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="dashStorage-title">
                <div>
                  <span>AREA PRODUTTIVA E STOCCAGGIO</span>
                  {/* 11:32 &nbsp;&nbsp; 25.01.2023 */}
                  {dateTime}
                </div>
              </div>
            </div>

            <div className="col-xl">
              <div className="dashStorage-card">
                <h2>ORDINI LAVORATI</h2>
                <div className="dashStorage-count">
                  <span className="icon">
                    <svg viewBox="0 0 79.7 70.38">
                      <path
                        id="Tracciato_139"
                        data-name="Tracciato 139"
                        fill="currentColor"
                        d="M78.11,24.53h0a35.13,35.13,0,0,0-44-22.9l-.48.06c-3.3,1-6.33,2.1-7,3.91a2.11,2.11,0,0,0,.29,2c.54.8,1.76,2.74,3.29,5.22a2.1,2.1,0,0,0,.24.5c.4.58,1.22,1.89,2.27,3.59C36.62,23.24,41.26,31,43,34l-5.68.13-5.94.11H25.12c-2.21,0-3.8,0-5,.08H20c-6.37,0-8.75,0-9.9,1.33-.88,1.06-.68,2.47-.3,4.82l.16,1C11.42,51,17.28,59.5,26.05,65a35.67,35.67,0,0,0,18.81,5.43,34.22,34.22,0,0,0,10.29-1.59A35.55,35.55,0,0,0,78.11,24.53ZM52.58,60.21a26.15,26.15,0,0,1-21.66-2.87A25.64,25.64,0,0,1,19,40l-.13-.82a6.27,6.27,0,0,1-.18-2.36,3,3,0,0,1,1.57-.35h7.7l9.36-.17,7-.16a1.35,1.35,0,0,0,1.15-2c-1.11-2.14-5.61-9.64-9.14-15.32-1.42-2.34-2.83-4.63-4-6.6.33-.46,1.76-1.09,4.31-1.89l.4-.12A26.15,26.15,0,0,1,69.63,27.23h0a26.49,26.49,0,0,1-17.07,33Zm2,6.71a33.1,33.1,0,0,1-27.43-3.64C19,58.23,13.34,50,12,41.27l-.17-1c-.25-1.52-.46-2.83-.16-3.18s2.2-.59,5-.62a8.59,8.59,0,0,0,.19,3l.12.8a27.31,27.31,0,0,0,12.9,18.79,28.54,28.54,0,0,0,15.08,4.35,27.55,27.55,0,0,0,8.26-1.27A28.49,28.49,0,0,0,71.56,26.61h0A28.18,28.18,0,0,0,36.29,8.23l-.4.12a15.42,15.42,0,0,0-4.78,2.07C29.94,8.54,29,7.11,28.61,6.49c-.07-.12-.06-.15-.06-.17.36-1,4.21-2.2,5.67-2.64l.49-.15A33.1,33.1,0,0,1,76.18,25.14h0A33.54,33.54,0,0,1,54.55,66.92Z"
                      />
                      <path
                        id="Tracciato_140"
                        data-name="Tracciato 140"
                        fill="currentColor"
                        d="M9.69,32.18,35,32a1.3,1.3,0,0,0,1.32-1.31,1.26,1.26,0,0,0-.2-.68L23.19,8.59A1.44,1.44,0,0,0,23,8.34L19.29,2.18A2.43,2.43,0,0,0,16,1.34l-.14.1C12.33,3.94.67,13.37,0,29.84a2.36,2.36,0,0,0,2.3,2.39h.27l7.1,0ZM20.82,8.73l.45.61L33.69,30l-23.78.14c.47-11.87,8.9-18.71,11.45-20.53ZM2.32,30.17h0a.3.3,0,0,1-.28-.3C2.64,14.35,13.65,5.42,17,3.05a.36.36,0,0,1,.2-.06.39.39,0,0,1,.34.19l2.84,4.68h0l-.14.1C17.49,9.9,8.42,17.26,7.92,30.09v.06Z"
                      />
                      <path
                        id="Tracciato_141"
                        data-name="Tracciato 141"
                        fill="currentColor"
                        d="M46.81,47.78A4.5,4.5,0,1,0,49,50.43,4.5,4.5,0,0,0,46.81,47.78Zm.12,5.15a2.48,2.48,0,0,1-4.4-2.31A2.52,2.52,0,0,1,44,49.4h0a2.67,2.67,0,0,1,.75-.11,2.48,2.48,0,0,1,2.19,3.63Z"
                      />
                      <path
                        id="Tracciato_142"
                        data-name="Tracciato 142"
                        fill="currentColor"
                        d="M55.49,20.64h0a4.51,4.51,0,1,0,5.65,2.95h0A4.51,4.51,0,0,0,55.49,20.64Zm2.1,6.67a2.49,2.49,0,1,1,1.62-3.12,2.49,2.49,0,0,1-1.62,3.12Z"
                      />
                      <path
                        id="Tracciato_143"
                        data-name="Tracciato 143"
                        fill="currentColor"
                        d="M17.29,23.83a4.51,4.51,0,1,0,2.95-5.66h0A4.53,4.53,0,0,0,17.29,23.83Zm3.53-3.72a2.48,2.48,0,0,1,1.49,4.73,2.42,2.42,0,0,1-1.9-.16,2.49,2.49,0,0,1-1.05-3.35,2.42,2.42,0,0,1,1.46-1.22Z"
                      />
                    </svg>
                  </span>
                  15
                  <span className="text-success">
                    <i className="far fa-long-arrow-alt-up"></i>
                    +15%
                  </span>
                </div>
              </div>
            </div>

            <div className="col-xl">
              <div className="dashStorage-card">
                <h2>LOTTI IN MAGAZZINO</h2>
                <div className="dashStorage-count">
                  <span className="icon">
                    <svg viewBox="0 0 82.76 79.99">
                      <path
                        fill="currentColor"
                        data-name="Tracciato 151"
                        className="cls-1"
                        d="M81.8,43.2,62.67,35V10.21a1.59,1.59,0,0,0-.94-1.45L42.19.13a1.65,1.65,0,0,0-1.27,0L21.24,8.53a1.6,1.6,0,0,0-1,1.46V35.17L.94,43.42a1.54,1.54,0,0,0-.79.76v0h0a1.35,1.35,0,0,0-.15.62V70.1a1.59,1.59,0,0,0,1,1.47l19.86,8.3h0a1.58,1.58,0,0,0,.61.12,1.5,1.5,0,0,0,.62-.13l19.46-8.29L61,79.86a1.56,1.56,0,0,0,1.27,0l19.51-8.74a1.59,1.59,0,0,0,.94-1.45v-25a1.59,1.59,0,0,0-1-1.47ZM10.62,56.85l-1.27.82V48.38a2.08,2.08,0,0,0-.09-.6l18.42-7.87,4.62,2-17.49,7.5a1.43,1.43,0,0,0-.87,1.31v8.87l-1.74-2.4A1.17,1.17,0,0,0,10.62,56.85ZM3.51,44.51l17.42-7.44,4.19,1.76L7.75,46.25ZM26.64,13.05a1.48,1.48,0,0,0,.41.12v11a1.49,1.49,0,0,0,1.49,1.49,1.47,1.47,0,0,0,.71-.18L31,24.53l2.41,3.73a1.16,1.16,0,0,0,1,.53,1.26,1.26,0,0,0,.33-.05,1.15,1.15,0,0,0,.83-1.11V17l5,2.09-.13,24.08L28.17,38,28,37.91,22.28,35.5v-24Zm25.9-6.16L34.69,14.54l-.19.13a1.5,1.5,0,0,0-.34.16,1.31,1.31,0,0,0-.58,1.08v8.9l-1.36-2.07a1.11,1.11,0,0,0-1.47-.37l-1.7.92V13h0l19-8.11Zm6.31,2.78L41.58,17.29l-4.17-1.74L55,8Zm14,31.86-18.17,7.8a.11.11,0,0,0-.09.06,1.14,1.14,0,0,0-.36.15,1.31,1.31,0,0,0-.61,1.11v8.06l-1.07-1.56a1.63,1.63,0,0,0-2.09-.51l-1.26.65V47.72l19-8.13h0ZM47.6,46.23l-3.73-1.62L62,36.85l3.77,1.62Zm13-11L42.39,43l.13-24L60.6,11ZM34.86,43l3.92,1.65L21.25,51.79l-3.49-1.47ZM22.24,53.5l18.17-7.43-.13,23.86L22.2,77.63Zm20.17-7.34,4.79,2.07V58.66a1.17,1.17,0,0,0,1.21,1.13,1.13,1.13,0,0,0,.5-.13l2.18-1.14,2.56,3.69.51.64.7-.22a1.07,1.07,0,0,0,.76-1V51.71L60.75,54a2.82,2.82,0,0,0-.08.65v22.9l-18.34-7.8Zm20,5.63a3.39,3.39,0,0,0-.72.46l-4.3-1.93,18-7.67h0L79,44.18ZM41.54,2l4,1.76L28.28,11.12a1.25,1.25,0,0,1-.93,0L23.5,9.69ZM2,46l5.05,2.07a.68.68,0,0,0,.26.15.17.17,0,0,1,0,.1V59.17a1.21,1.21,0,0,0,1.81,1L11,59l3,4.08a1.05,1.05,0,0,0,.86.44.91.91,0,0,0,.33,0l.73-.29V51.68l4.3,1.81v24L2,69.83ZM62.67,77.44V54.63a1.14,1.14,0,0,1,.66-1l17.43-8.09V69.4Z"
                      />
                    </svg>
                  </span>
                  9.782
                  <span className="text-danger">
                    <i className="far fa-long-arrow-alt-down"></i>
                    -3.5%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;
