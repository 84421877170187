import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import Pagination from "../../component/Pagination/Pagination";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import swal from 'sweetalert';
import moment from "moment";
import { toast } from "react-toastify";

const TIME_SLOTS = [
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "00:00",
];

const Orari = () => {
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [timetables, setTimetables] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [lastIndex, setLastIndex] = useState(0);

  const { request, response } = useRequest();
  const { request: updateRequest, response: updateResponse } = useRequest();
  const { request: deleteRequest, response: deleteResponse } = useRequest();

  const {
    register,
    unregister,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    request("GET", "provider-timetables");
  }, []);

  useEffect(() => {
    if (response) {
      const updatedData = response.data?.map((timetable, index) => {
        return {
          ...timetable,
          id: index,
        };
      });
      setTimetables(updatedData);
      setLastIndex(updatedData.length);
      const resetData = updatedData.map((timetable) => {

        return {

          id_delivery_rules: timetable.id_delivery_rules,
          order_day: timetable.order_day,
          limit_hours: timetable.limit_hours,
          s:timetable.s
        };
      });
      reset({ ...resetData });
    }
  }, [response]);

  useEffect(() => {
    if (updateResponse) {
      if (updateResponse.status === "SUCCESS") {
        request("GET", "provider-timetables");
        toast.success(updateResponse.message);
      } else {
        toast.error(updateResponse.message);
      }
    }
  }, [updateResponse]);

  useEffect(() => {
    if (deleteResponse) {
      if (deleteResponse.status === "SUCCESS") {
        request("GET", "provider-timetables");
        toast.success(deleteResponse.message);
      } else {
        toast.error(deleteResponse.message);
      }
    }
  }, [deleteResponse]);

  const submitHandler = (data) => {
    const time_tables = [];
    let isError = false;
    for (let key in data) {
      data[key]["limit_hours"] = moment(data[key]["limit_hours"], "hh:mm").format(
        "hh:mm:ss"
      );
      data[key]["s"] = +data[key]["s"];
      time_tables.push(data[key]);
    }
    if (isError) {
      return;
    }
    updateRequest("POST", "add-update-provider-timetable", {
      time_tables,
    });
  };

  const addNewRow = () => {
    const oldData = [...timetables];
    oldData.unshift({
      id: lastIndex,
      s: 0,
      limit_hours: "00:00",
      id_delivery_rules: null ,
    });

    setValue(`${lastIndex}.s`, 0);
    setValue(`${lastIndex}.limit_hours`, "00:00");
    setValue(`${lastIndex}.id_delivery_rules`, null);

    setLastIndex((prev) => prev + 1);
    setTimetables(oldData);
  };

  const deleteHandler = ({ id, id_delivery_rules }) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this ORARI data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        if (id_delivery_rules !== 0) {
          deleteRequest("POST", `delete-provider-timetable/${id_delivery_rules}`);
        }
        unregister([
          `${id}.s`,
          `${id}.limit_hours`,
          `${id}.id_delivery_rules`,
        ]);
    
        setTimetables(timetables.filter((timetable) => timetable.id!== id));
        swal("Poof! Your ORARI data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your ORARI data is safe!");
      }
    });
 
  };

  return (
    <div
      className="tab-pane fade"
      id="point-tab-pane"
      role="tabpanel"
      aria-labelledby="point-tab"
      tabIndex={0}
    >
      <div className="themeTable customerTable">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div>
            <button
              type="button"
              className="btn btn-sm btn-primary ms-3"
              onClick={addNewRow}
            >
              Add
            </button>
            <button type="submit" className="btn btn-sm btn-primary ms-3">
              Save
            </button>
          </div>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th style={{ width: "15%" }}>ID</th>
                <th style={{ width: "15%" }}>Delivery day</th>
                <th style={{ width: "30%" }}>Start time</th>
                <th style={{ width: "30%" }}>Giorni consegna</th>
                <th style={{ width: "10%" }} />
              </tr>
            </thead>
            <tbody>
              {timetables.length > 0 &&
                timetables.map((timetable) => (
                  <tr key={`timetable_${timetable.id}`}>
                    <td
                      data-th="ID"
                      {...register(`${timetable.id}.id_delivery_rules`)}
                    >
                      {timetable.id}
                    </td>
                    <td data-th="delivery">
                      <Controller
                        control={control}
                        name={`${timetable.id}.order_day`}
                        rules={{ required: true, minLength: 1 }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            className={`form-control ${
                              errors[timetable.id]?.order_day && "is-invalid"
                            }`}
                            value={value}
                            onChange={onChange}
                          >
                            <option value={0}>All</option>
                            <option value={1}>Monday</option>
                            <option value={2}>Tuesday</option>
                            <option value={3}>Wednesday</option>
                            <option value={4}>Thursday</option>
                            <option value={5}>Friday</option>
                            <option value={6}>Saturday</option>
                            <option value={7}>Sunday</option>
                          </select>
                        )}
                      />
                      {errors[timetable.id]?.order_day?.type ===
                        "required" && (
                        <div className="invalid-feedback">
                          The delivery date field is required.
                        </div>
                      )}
                    </td>
                    <td data-th="limit_hours">
                      <Controller
                        control={control}
                        name={`${timetable.id}.limit_hours`}
                        rules={{ required: true, minLength: 1 }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            className={`form-control ${
                              errors[timetable.id]?.limit_hours && "is-invalid"
                            }`}
                          >
                            {TIME_SLOTS.map((time, idx) => (
                              <option value={time} key={`limit_hours${idx}`}>
                                {time}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                      {errors[timetable.id]?.limit_hours?.type ===
                        "required" && (
                        <div className="invalid-feedback">
                          The start time field is required.
                        </div>
                      )}
                    </td>
                    <td data-th="end_time">
                      <input
                         className={`form-control ${
                          errors[timetable.id]?.s && "is-invalid"
                        }`}
                        {...register(`${timetable.id}.s`)}
                        />
                      {errors[timetable.id]?.s?.type === "required" && (
                        <div className="invalid-feedback">
                          The Giorni Consegna field is required.
                        </div>
                      )}
                    </td>
                    <td data-th="ACTION">
                      <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={() => deleteHandler(timetable)}
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </form>
      </div>
      {/* {perPage !== 0 && (
        <Pagination
          page={page}
          totalRecords={totalDocuments}
          getNewData={fetchMoreData}
          perPage={perPage}
          defaultPerPage={1}
          perPageChangeHandler={perPageChangeHandler}
          currentDocLength={timetables.length}
        />
      )} */}
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Orari;
