import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SIDEBAR_TOGGLE,
  LOADING,
} from "./actionTypes";
import { toast } from "react-toastify";

export const authSuccess = (updates) => {     
  if (updates.token) {
    localStorage.setItem("token", updates.token);
    localStorage.setItem("lastLoginTime", new Date(Date.now()).getTime());
    localStorage.setItem("username", updates.username); 
    localStorage.setItem("warehouse_code",updates.warehouse_code); 

  }
  return {
    type: AUTH_SUCCESS,
    updates,
  };
};

export const sidebarToggle = (updates) => {
  return {
    type: SIDEBAR_TOGGLE,
    updates,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("lastLoginTime");
  localStorage.removeItem("username");
  localStorage.removeItem("warehouse_code");
  localStorage.removeItem("active");
  toast.success("You are now logged out!");
  return {
    type: AUTH_LOGOUT,
  };
};

export const updateLoading = (updates) => {
  return {
    type: LOADING,
    updates,
  };
};
