import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import Loading from "../../../component/Loading/Loading"
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

const Articoli = (state) => {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    unregister,
    reset,
  } = useForm();
  const id_catalog = state.state.id_catalog;

  const [show, setShow] = useState(false);
  const [article, setArticle] = useState([]);
  const [articoli, setArticoli] = useState([]);
  const [descrizione, setDescrizione] = useState();
  const [priority, setPriority] = useState([]);
  const [articledata, setarticleData] = useState([]);
  const [catalogArticle, setcatalogArticle] = useState([]);
  const { response, request } = useRequest();
  const { response: articleResponse, request: articleRequest } = useRequest();
  const { response: modalResponse, request: modalRequest } = useRequest();
  const { response: submitResponse, request: submitRequest } = useRequest();
  const { request: deleteRequest, response: deleteResponse } = useRequest();

  useEffect(() => {
    request("GET", `catalog-article/${id_catalog}`);
    articleRequest("GET", `get-article-catalog-article/${id_catalog}`);
  }, []);

  useEffect(() => {
    if (modalResponse) {
      if (modalResponse.status === "SUCCESS") {
        toast.success("Catalogo delle composizioni aggiunto con successo");
        setDescrizione("");
        setShow(false);
        request("GET", `catalog-article/${id_catalog}`);
      } else {
        toast.error(modalResponse.message);
      }
    }
  }, [modalResponse]);


  useEffect(() => {
    if (articleResponse) {
      if(articleResponse.data?.articleData?.length>0){
        setArticle(
          articleResponse.data?.articleData?.map((c, o) => {
            return {
              value: c.id_article,
              label: c.description,
            };  
          })
        );
      }
   
    }
  }, [articleResponse]);

  useEffect(() => {
    if (response) {   
      if (response.data.length<=0)
       {
      //  <>
      //     <Loading/>
      //     </>
      }else{
        setArticoli(response.data?.catalog_article);
        const resetData = response.data?.catalog_article?.map((articolis) => {
          return {
       
                  id_article: articolis.id_article,
                  id_catalog: articolis.id_catalog,
                  priority: articolis.priority,
                };
        })
        reset({ ...resetData });
      }
    }
  }, [response]);

  useEffect(() => {
    if (deleteResponse) {
      request("GET", `catalog-article/${id_catalog}`);
    }
  }, [deleteResponse]);


  const deleteHandler = ({id_article,id}) => {
    debugger
      deleteRequest("POST", `delete-catalog-article`,{id_catalog,id_article});
    unregister([
      `${id}.id_article`,
      `${id}.id_catalog`,
      `${id}.priority`
    ]);
    setArticoli(articoli.filter((articoli) => articoli.id!== id));
 
  };



  const onModalSubmit = (e) => {
    e.preventDefault();
    if (!descrizione) {
      toast.error("the descrizione field is required");
    } else {
      modalRequest("POST", "add-catalog-article", {
        id_article: descrizione,
        id_catalog,
      });
    }
  };

  useEffect(() => {
    if (submitResponse) {
      if (submitResponse.status === "SUCCESS") {
        toast.success(submitResponse.message);
        navigate(-1);
      } else {
        toast.error(submitResponse.message);
      }
    }
  }, [submitResponse]);

  const onSubmit = (data) => {
    const catalog_article_data = [];
    for (let key in data) {
      data[key]["priority"] = +data[key]["priority"];
      data[key]["id_catalog"] = +data[key]["id_catalog"];
      data[key]["id_article"] = +data[key]["id_article"];
 
      catalog_article_data.push(data[key]);
    }
    submitRequest("POST", "edit-catalog-article", {
      catalog_article_data: data,
    });
  };
  return (
    <>
      <div
        className="tab-pane fade"
        id="point-tab-pane"
        role="tabpanel"
        aria-labelledby="point-tab"
        tabIndex={0}
      >
        <div className="themeTable customerTable">
          <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <button
                type="button"
                className="btn btn-sm btn-primary ms-3"
                onClick={() => setShow(true)}
              >
                Add
              </button>
            </div>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>Code</th>
                  <th style={{ width: "20%" }}>Priorità</th>
                  <th style={{ width: "30%" }}>Articolo</th>
                  <th style={{ width: "20%" }}>Fornitore</th>
                  <th style={{ width: "10%" }} />
                </tr>
              </thead>
              <tbody>
                {articoli.length > 0 &&
                  articoli.map((articolis, index) => (
                    <tr key={articolis.id}>
                      <td data-th="Code">{articolis.article_data.article_code}</td>
                      <td data-th="Priorità">
                        <Controller
                          control={control}
                          name={`${articolis.id}.priority`}
                          // name="periorty"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <select
                              value={value}
                              placeholder="Select"
                              className={`form-control`}
                              classNamePrefix="select"
                              onChange={onChange}
                            >
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                            </select>
                          )}
                        />
                      </td>
                      <td data-th="Articolo">
                        {" "}
                        {articolis.article_data.description}{" "}
                      </td>
                      <td data-th="Fornitore">
                        {articolis.article_data.provider.description}
                      </td>
                      <td data-th="Action">
                        <button
                          className="btn btn-sm btn-danger"
                          type="button"
                          onClick={() => deleteHandler(articolis)}
                        >
                          <i className="fas fa-trash" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="mt-md-4 mt-3 text-center text-md-start">
              <button className="btn btn-pill btn-yellow">SALVA</button>
              <button
                className="btn btn-pill btn-blue ms-2 ms-md-3"
                onClick={() => {
                  navigate(-1);
                }}
              >
                ANNULLA
              </button>
            </div>
          </form>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
          >
            <form onSubmit={onModalSubmit}>
              <Modal.Header>
                <Modal.Title>Scegliere I'articolo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-3 ">
                          <label>Descrizione articolo</label>
                        </div>
                        <div className="col-lg-7"> 
                              <Select
                                options={article}
                                placeholder="Select"
                                className={`basic-multi-select`}
                                classNamePrefix="select"
                                onChange={(e) => {
                                  setDescrizione(e.value);
                                }}
                              />
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-pill btn-yellow">SALVA</button>
                <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                >
                  ANNULLA
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default Articoli;
