import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SIdebar from "../../component/sidebar/SIdebar";
import Header from "../../component/header/Header";
import Pagination from "../../component/Pagination/Pagination";
import useRequest from "../../hooks/useRequest";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { debounce } from "lodash";

const searchQueryHandler = (page, per_page, name = "") => {
  return `product-groups?page=${page}&per_page=${per_page}&name=${name}`;
};

function ProductGroup() {
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [groups, setGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("");
  const [newGroup, SetnewGroup] = useState(null);
  const [edit, setEdit] = useState("");
  const [Id, setId] = useState("");

  const { request, response } = useRequest();
  const { request: modalRequest, response: modalResponse } = useRequest();
  const { request: groupeditRequest, response: groupeditResponse } =
    useRequest();
  const { request: demoRequest } = useRequest();

  useEffect(() => {
    request("GET", searchQueryHandler(1, perPage, searchQuery));
    demoRequest("GET", "get-all-product-categories");
  }, []);

  const fetchMoreData = ({ selected }) => {
    setGroups([]);
    setPage(selected + 1);
    request("GET", searchQueryHandler(selected + 1, perPage, searchQuery));
  };

  const onSearchHandler = () => {
    request("GET", searchQueryHandler(1, perPage, searchQuery));
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    request("GET", searchQueryHandler(1, event.target.value, searchQuery));
    setPage(1);

    setPerPage(event.target.value);
  };
  useEffect(() => {
    if (response) {
      setGroups(response.data.results);
      setTotalDocuments(response.data?.total_records);
    }
  }, [response]);

  useEffect(() => {
    if (modalResponse) {
      if (modalResponse.status === "SUCCESS") {
        setShow(false);
        setDescription("");
        request("GET", searchQueryHandler(1, perPage, searchQuery));
        toast.success(modalResponse.message);
      } else {
        toast.error(modalResponse.message);
        setShow(true);
      }
    }
  }, [modalResponse]);

  useEffect(() => {
    if (groupeditResponse) {
      if (groupeditResponse.status === "SUCCESS") {
        toast.success(groupeditResponse.message);
        SetnewGroup(null);
        request("GET", searchQueryHandler(1, perPage, searchQuery));
      } else {
        toast.error(groupeditResponse.message);
      }
    }
  }, [groupeditResponse]);

  const handleClick = (e, group) => {
    SetnewGroup(group.id_products_group);
    setEdit(group.description);
  };

  const handleCancelClick = () => {
    SetnewGroup(null);
  };

  const onModalSubmit = (e) => {
    e.preventDefault();
    modalRequest("POST", "add-product-group", { description: description });
  };

  const handleSubmit = () => {
    groupeditRequest("post", "edit-product-group", {
      description: edit,
      id_products_group: Id,
    });
  };

  const handleChangeWithSearch = debounce((searchQuery) => {
    request("GET", searchQueryHandler(1, perPage, searchQuery));
  }, 500);
  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">ANAGRAFICA</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">GRUPPO PRODOTTO</Link>
                  </li>
                </ol>
              </nav>
              <button
                onClick={() => {
                  setShow(true);
                }}
                className="btn btn-sm btn-primary ms-3"
              >
                <i className="far fa-plus" />
              </button>
            </div>
            <div className="pagesearch">
              <div className="pagesearch-block">
                {/* <input
                    type="text"
                    placeholder="DENOMINAZIONE"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  /> */}

                <input
                  type="text"
                  placeholder="DENOMINAZIONE"
                  value={searchQuery}
                  // onChange={(e) =>

                  //  }
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchQuery(e.target.value);
                    handleChangeWithSearch(e.target.value);
                  }}
                />
                {/* <button onClick={onSearchHandler}>
                  <i className="fas fa-search" />
                </button> */}
              </div>
            </div>
          </div>
          {/* Page Content */}
          <div className="pageContent">
            <div className="themeTable customerTable">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>DENOMINAZIONE</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {groups.length > 0 &&
                    groups.map((group, index) => {
                      return (
                        <>
                          {newGroup === group.id_products_group ? (
                            <tr>
                              <td data-th="ID">{group.id_products_group}</td>
                              <td data-th="DENOMINAZIONE">
                                <input
                                  type="text"
                                  className={`form-control `}
                                  name="description"
                                  id="Description"
                                  value={edit}
                                  onChange={(e) => {
                                    setEdit(e.target.value);
                                    setId(group.id_products_group);
                                  }}
                                />
                              </td>

                              <td data-th="ACTION">
                                <button
                                  style={{ marginRight: "10px" }}
                                  onClick={(e) => handleSubmit()}
                                  className="btn btn-sm btn-primary"
                                >
                                  submit
                                </button>
                                <button
                                  onClick={(e) => handleCancelClick()}
                                  className="btn btn-sm btn-danger"
                                >
                                  <i className="fas fa-times " />
                                </button>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td data-th="ID">{group.id_products_group}</td>
                              <td data-th="DENOMINAZIONE">
                                {group.description}
                              </td>

                              <td data-th="ACTION">
                                <button
                                  onClick={(e) => handleClick(e, group)}
                                  className="btn btn-sm btn-primary"
                                >
                                  <i className="fas fa-pencil-alt" />
                                </button>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {perPage !== 0 && (
              <Pagination
                page={page}
                totalRecords={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                defaultPerPage={10}
                perPageChangeHandler={perPageChangeHandler}
                currentDocLength={groups.length}
              />
            )}
          </div>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
          >
            <form onSubmit={onModalSubmit}>
              <Modal.Header>
                <Modal.Title>generale</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-3 ">
                          <label>Description</label>
                        </div>
                        <div className="col-lg-7">
                          <input
                            type="text"
                            className={`form-control `}
                            name="description"
                            id="Description"
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-pill btn-yellow">SALVA</button>
                <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                >
                  ANNULLA
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default ProductGroup;
