import React, { useEffect, useState } from "react";
import SIdebar from "../../../component/sidebar/SIdebar";
import Header from "../../../component/header/Header";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
// import Contatti from "./contatti";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

function DatiCliented({ activeSelectId, passiveSelectId }) {
  
  let navigate = useNavigate();

  const [region, setRegion] = useState([]);
  const [providertime, setProvidertime] = useState([]);

  const [provider, setProvider] = useState([]);
  const [charts, setCharts] = useState([]);
  const [atucalCharts, setActualCharts] = useState([]);
  const [show, setShow] = useState(false);
  const [descrizione, setdescrizione] = useState();
  const [tipo, setTipo] = useState();
  const [codice, setCodice] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
  } = useForm();

  const { response, request } = useRequest();

  const { response: regionResponse, request: regionRequest } = useRequest();
  const { response: providertimeResponse, request: providertimeRequest } =
    useRequest();
  const { request: countryRequest, response: countryResponse } = useRequest();
  const { request: providerRequest, response: providerResponse } = useRequest();
  const { request: listRequest, response: listResponse } = useRequest();
  const { request: addListRequest, response: addListResponse } = useRequest();
  // const { request: chartsRequest, response: chartResponse } = useRequest();

  useEffect(() => {
    // debugger;
    // providertimeRequest("GET", "provider-timetables");
    // chartsRequest("GET", "chart-of-accounts");
    // countryRequest("GET", "country");
    providerRequest("GET", "providers");
    listRequest("GET", "lists");
  }, []);

  useEffect(() => {
    if (providerResponse) {
      setProvider(
        providerResponse.data?.results.map((provider) => {
          return {
            value: provider.id_provider,
            label: provider.description,
          };
        })
      );
    }
  }, [providerResponse]);

  // useEffect(() => {
  //   if (chartResponse) {
  //     setCharts(
  //       chartResponse.data?.map((list) => {
  //         return {
  //           value: list.idpianoconti,
  //           label: list.descrizione,
  //         };
  //       })
  //     );
  //     setActualCharts(
  //       chartResponse.data?.map((list) => {
  //         return {
  //           value: list.idpianoconti,
  //           label: list.descrizione,
  //         };
  //       })
  //     );
  //   }
  // }, [chartResponse]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success("Cliente aggiunto correttamente");
        navigate(-1);
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);
  useEffect(() => {
    if (addListResponse) {
      // debugger;
      if (addListResponse.status === "SUCCESS") {
        // chartsRequest("GET", "chart-of-accounts");
      } else {
        toast.error(response.message);
      }
    }
  }, [addListResponse]);

  const onSubmit = (data) => {
    request("POST", "add-article", {
      ...data,
      id_coa_active: activeSelectId,
      id_coa_passive: passiveSelectId,
    });
  };

  const onModelSubmit = (e) => {
    e.preventDefault();
    if (!descrizione) {
      toast.error("The descrizione field is required.");
    } else {
      addListRequest("POST", "add-chart-of-accounts", {
        descrizione,
        tipo,
        codice,
      });
      setShow(false);
    }
  };

  return (
    <>
      <form method="post" onSubmit={handleSubmit(onSubmit)}>
        <div className="tabH-full">
          <div className="row g-lg-0">
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>fornitore</label>
                  </div>
                  <div className="col-lg-7">
                    <Controller
                      control={control}
                      rules={{ required: true, minLength: 1 }}
                      name="id_provider"
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          // menuPosition="fixed"
                          options={provider}
                          classNamePrefix="select province"
                          placeholder="select "
                          className={`basic-multi-select ${
                            errors.id_provider && "is-invalid"
                          }`}
                          onChange={(e) => onChange(e.value)}
                        />
                      )}
                    />
                    {errors.id_provider?.type === "required" && (
                      <div className="invalid-feedback">
                        The fornitore field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>CODICE ARTICOLI</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.article_code && "is-invalid"
                      }`}
                      name="article_code"
                      id="article_code"
                      {...register("article_code", {
                        required: true,
                      })}
                    />
                    {errors.article_code?.type === "required" && (
                      <div className="invalid-feedback">
                        CODICE ARTICOLI field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-lg-0">
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>misura unita</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.unite_measure && "is-invalid"
                      }`}
                      name="unite_measure"
                      id="unite_measure"
                      {...register("unite_measure", {
                        required: true,
                      })}
                    />
                    {errors.unite_measure?.type === "required" && (
                      <div className="invalid-feedback">
                        misura unita field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>paese macellato</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.butchered_country && "is-invalid"
                      }`}
                      name="butchered_country"
                      id="butchered_country"
                      {...register("butchered_country", {
                        required: true,
                      })}
                    />
                    {errors.butchered_country?.type === "required" && (
                      <div className="invalid-feedback">
                        paese macellato field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>regole di consegna</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_delivery_rules"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      // menuPosition="fixed"
                                      options={providertime}
                                      classNamePrefix="select"
                                      placeholder="Select Regione"
                                      className={`basic-multi-select ${
                                        errors.id_province && "is-invalid"
                                      }`}
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.id_delivery_rules?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The regione field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> */}
          </div>
          <div className="row g-lg-0">
            <div className="col-md-4">
              <div className="form-group">
                {/* <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>coa active</label>
                  </div>
                  <div className="col-lg-7">
                    <Controller
                      control={control}
                      name="id_coa_active"
                      render={({ field: { onChange } }) => (
                        <Select
                          options={charts}
                          placeholder="Select"
                          className={`basic-multi-select`}
                          classNamePrefix="select"
                          onChange={(e) => {
                            onChange(e.value);
                            setCharts(
                              atucalCharts.filter(
                                (c) =>
                                  c.value != e.value &&
                                  c.value != getValues("id_coa_passive")
                              )
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                {/* <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>coa passive</label>
                  </div>
                  <div className="col-lg-6">
                    <Controller
                      control={control}
                      name="id_coa_passive"
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          options={charts}
                          placeholder="Select"
                          className={`basic-multi-select `}
                          classNamePrefix="select"
                          onChange={(e) => {
                            onChange(e.value);
                            setCharts(
                              atucalCharts.filter(
                                (c) =>
                                  c.value != e.value &&
                                  c.value != getValues("id_coa_active")
                              )
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-1">
                    <button
                      className="btn -pill btn-yellow"
                      type="button"
                      onClick={() => setShow(true)}
                    >
                      Add
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="co l-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>NAZIONE</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_country"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      // menuPosition="fixed"
                                      options={country}
                                      classNamePrefix="select"
                                      placeholder="Select Nazione"
                                      className={`basic-multi-select ${
                                        errors.id_country && "is-invalid"
                                      }`}
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.id_country?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The NAZIONE field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> */}
          </div>
          <div className="row g-lg-0">
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>ordine minimo</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="number"
                      className={`form-control ${
                        errors.min_order && "is-invalid"
                      }`}
                      name="min_order"
                      id="min_order"
                      {...register("min_order", {
                        required: true,
                      })}
                    />
                    {errors.min_order?.type === "required" && (
                      <div className="invalid-feedback">
                        ordine minimo field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label> paese sezionato</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.dissected_country && "is-invalid"
                      }`}
                      name="dissected_country"
                      id="dissected_country"
                      {...register("dissected_country", {
                        required: true,
                      })}
                    />
                    {errors.dissected_country?.type === "required" && (
                      <div className="invalid-feedback">
                        paese sezionato field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-lg-0">
            {/* <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>regole di consegna</label>
                  </div>
                  <div className="col-lg-7">
                    <Controller
                      control={control}
                      rules={{ required: true, minLength: 1 }}
                      name="id_delivery_rules"
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                        
                          options={providertime}
                          classNamePrefix="select"
                          placeholder="Select"
                          className={`basic-multi-select ${
                            errors.id_delivery_rules && "is-invalid"
                          }`}
                          onChange={(e) => onChange(e.value)}
                        />
                      )}
                    />
                    {errors.id_delivery_rules?.type === "required" && (
                      <div className="invalid-feedback">
                        The regole di consegna field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>costi aggiuntivi</label>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-check">
                      <input
                        className="form-check-input checklg"
                        type="checkbox"
                        defaultValue=""
                        id="PRIVATO"
                        {...register("additional_costs")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PRIVATO"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>nota laboratorio</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.note_lab && "is-invalid"
                                  }`}
                                  name="note_lab"
                                  id="note_lab"
                                  {...register("note_lab", {
                                    required: true,
                                  })}
                                />
                                {errors.note_lab?.type === "required" && (
                                  <div className="invalid-feedback">
                                  nota laboratorio field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> */}
          </div>
          <div className="row g-lg-0">
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>prezzo d'acquisto</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="number"
                      step="0.01"
                      className={`form-control ${
                        errors.purchase_price && "is-invalid"
                      }`}
                      name="purchase_price"
                      id="purchase_price"
                      {...register("purchase_price", {
                        required: true,
                      })}
                    />
                    {errors.purchase_price?.type === "required" && (
                      <div className="invalid-feedback">
                        prezzo d'acquisto field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>pezzi per confezione</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="number"
                      className={`form-control ${
                        errors.pieces_for_package && "is-invalid"
                      }`}
                      name=""
                      id="street"
                      {...register("pieces_for_package", {
                        required: true,
                      })}
                    />
                    {errors.pieces_for_package?.type === "required" && (
                      <div className="invalid-feedback">
                        pezzi per confezione field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-lg-0"></div>
          <div className="row g-lg-0">
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>paese allevato</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.bred_country && "is-invalid"
                      }`}
                      name="bred_country"
                      id="bred_country"
                      {...register("bred_country", {
                        required: true,
                      })}
                    />
                    {errors.bred_country?.type === "required" && (
                      <div className="invalid-feedback">
                        paese allevato field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>ce timbro</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.ce_stamp && "is-invalid"
                      }`}
                      name="ce_stamp"
                      id="ce_stamp"
                      {...register("ce_stamp", {
                        required: true,
                      })}
                    />
                    {errors.ce_stamp?.type === "required" && (
                      <div className="invalid-feedback">
                        ce timbro field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label> LISTINO</label>
                              </div>
                              <div className="col-lg-6">
                                <Controller
                                  control={control}
                                  name="idlistino"
                                  
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      menuPosition="fixed"
                                      options={getlist}
                                      placeholder="Select Dept"
                                      className={`basic-multi-select `}
                                      classNamePrefix="select"
                                    //   onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                       
                              </div>
                              
                            </div>
                          </div>
                        </div> */}
          </div>
          <div className="row g-lg-0">
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>laboratorio del prodotto</label>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-check">
                      <input
                        className="form-check-input checklg"
                        type="checkbox"
                        defaultValue=""
                        id="PRIVATO"
                        {...register("product_lab")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="product_lab"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>ATTIVO</label>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-check">
                      <input
                        className="form-check-input checklg"
                        type="checkbox"
                        defaultValue=""
                        id="active"
                        {...register("active")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PRIVATO"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-lg-0">
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>unisci misura riordina</label>
                  </div>
                  <div className="col-lg-7  ">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.unite_measure_reorder && "is-invalid"
                      }`}
                      name="unite_measure_reorder"
                      id="unite_measure_reorder"
                      {...register("unite_measure_reorder", {
                        required: true,
                      })}
                    />
                    {errors.unite_measure_reorder?.type === "required" && (
                      <div className="invalid-feedback">
                        unisci misura riordina field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>Paese di nascita</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.birth_country && "is-invalid"
                      }`}
                      name="birth_country"
                      id="birth_country"
                      {...register("birth_country", {
                        required: true,
                      })}
                    />
                    {errors.birth_country?.type === "required" && (
                      <div className="invalid-feedback">
                        Paese di nascita field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-lg-0">
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>DESCRIPTION</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.description && "is-invalid"
                      }`}
                      name=""
                      id="description"
                      {...register("description", {
                        required: true,
                      })}
                    />
                    {errors.description?.type === "required" && (
                      <div className="invalid-feedback">
                        description field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>nota laboratorio</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.note_lab && "is-invalid"
                      }`}
                      name="note_lab"
                      id="note_lab"
                      {...register("note_lab", {
                        required: true,
                      })}
                    />
                    {errors.note_lab?.type === "required" && (
                      <div className="invalid-feedback">
                        nota laboratorio field is required.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-md-4 mt-3 text-center text-md-start">
          <button className="btn btn-pill btn-yellow">SALVA</button>
          <button
            className="btn btn-pill btn-blue ms-2 ms-md-3"
            onClick={() => {
              navigate(-1);
            }}
          >
            ANNULLA
          </button>
        </div>
      </form>
      <Modal show={show} onHide={() => setShow(false)}>
        <form onSubmit={onModelSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>generale</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-2 text-lg-end">
                      <label>descrizione</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        type="text"
                        className={"form-control"}
                        name=""
                        id="description"
                        onChange={(e) => {
                          setdescrizione(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-2 text-lg-end">
                      <label>codice</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        type="text"
                        className={"form-control"}
                        name=""
                        id="codice"
                        onChange={(e) => {
                          setCodice(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="row align-items-center">
                    <div className="col-lg-2 text-lg-end ">
                      <label>tipo</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        type="text"
                        className={"form-control"}
                        name=""
                        id="setTipo"
                        onChange={(e) => {
                          setTipo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={() => setShow(false)}
                >
                  ANNULLA
                </button> */}
            <button className="btn btn-pill btn-yellow">SALVA</button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default DatiCliented;
