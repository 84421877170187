import React, { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

const ChartOfAccount = ({
  activeSelectId,
  setActiveSelectId,
  passiveSelectId,
  setPassiveSelectId,
}) => {
  const [charts, setCharts] = useState([]);
  const [show, setShow] = useState(false);
  const [ple,setPle] = useState('')
  const [showHeading, setShowHeading] = useState("");


  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    clearErrors,
  } = useForm();
  // codice
  const { request: chartsRequest, response: chartResponse } = useRequest();
  const { request: addchartofaccountsRequest , response: addchartofaccountsResponse } = useRequest();
  useEffect(() => {
    chartsRequest("GET", "chart-of-accounts");
    setShowHeading(localStorage.getItem('active'))
  }, []);

  useEffect(() => {
    if (addchartofaccountsResponse) {
      if (addchartofaccountsResponse.status === "SUCCESS") {
        setShow(false)
        reset({
          descrizione:'',
          tipo:'',
          codice:''
        })
        activeHandler(ple+1)
        passiveHandler(ple+1)
        // window.location.reload()
        chartsRequest("GET", "chart-of-accounts");
      }
      
    }

  }, [addchartofaccountsResponse]);
  useEffect(() => {
    if (chartResponse) {
      if (chartResponse.status === "SUCCESS") {
        setCharts(chartResponse?.data);
        setPle(chartResponse?.data?.length)
      }
    }
  });

  const activeHandler = (id) => {
    setActiveSelectId(id);
  };

  const passiveHandler = (id) => {
    setPassiveSelectId(id);
  };


  const onModelSubmit = (data) => {
   addchartofaccountsRequest("POST", "add-chart-of-accounts", data);
  };

  return (
    <div
    className={
      showHeading == 2 ? "tab-pane fade show active" : "tab-pane fade"
    }
      id="floor-tab-pane"
      role="tabpanel"
      aria-labelledby="floor-tab"
      tabIndex={1}
    >
      <div className="tabH-full">
        <div className="row g-4">
          <div className="col-lg-6">
            <h5 className="mb-3 mb-md-4">ATTIVO</h5>
            <div className="themeTable1 table-responsive themeTable customerTable">
              <table className="table mb-0 table table-striped table-bordered">
                <thead>
                  <tr>
                    <th className="w-20">TIPO</th>
                    <th className="w-20">CODICE</th>
                    <th> DESCRIZIONE</th>
                  </tr>
                </thead>
                <tbody>
                  {charts.length > 0 &&
                    charts.map((chart, index) => (
                      // <div onClick={() => activeHandler(chart.idpianoconti)}>
                      <tr
                        className={
                          chart.idpianoconti === activeSelectId
                            ? "table table-striped table-bordered  active"
                            : ""
                        }
                        style={{ cursor: "pointer" }}
                        key={chart.idpianoconti}
                        onClick={() => activeHandler(chart.idpianoconti)}
                      >
                        <td data-th="TIPO">{chart.tipo}</td>
                        <td data-th="CODICE">{chart.codice}</td>
                        <td data-th="Descrizione">{chart.descrizione}</td>
                      </tr>
                      // </div>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-6">
          <div className="row g-4">
          <h5 className="mb-3 mb-md-4 col-lg-10">PASSIVO</h5>
            <div className="col-lg-2 " >
              <button
              style={{ alignItems:"end" }}  
                className="btn -pill btn-yellow"
                type="button"
                onClick={() => setShow(true)}
              >
                Nuovo
              </button>
            </div>
          </div>
           
            <div className="themeTable1 table-responsive themeTable customerTable">
              <table className="table mb-0 table table-striped table-bordered">
                <thead>
                  <tr>
                    <th className="w-20">TIPO</th>
                    <th className="w-20">CODICE </th>
                    <th>DESCRIZIONE</th>
                  </tr>
                </thead>
                <tbody>
                  {charts.length > 0 &&
                    charts.map((chart, index) => (
                      // <div onClick={() => passiveHandler(chart.idpianoconti)}>
                      <tr
                        className={
                          chart.idpianoconti === passiveSelectId
                            ? "table table-striped table-bordered  active"
                            : ""
                        }
                        style={{ cursor: "pointer" }}
                        key={chart.idpianoconti}
                        onClick={() => passiveHandler(chart.idpianoconti)}
                      >
                        <td data-th="TIPO">{chart.tipo}</td>
                        <td data-th="CODICE">{chart.codice}</td>
                        <td data-th="Descrizione">{chart.descrizione}</td>
                      </tr>
                      // </div>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => {setShow(false)
                    reset({
                      descrizione:'',
                      tipo:'',
                      codice:''
                    })
                    clearErrors("descrizione");
                    clearErrors("tipo");

                    clearErrors("codice");}}>
            <form onSubmit={handleSubmit(onModelSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title>generale</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-2 ">
                          <label>descrizione</label>
                        </div>
                        <div className="col-lg-5">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.descrizione && "is-invalid"
                            }`}
                            name="descrizione"
                            id="descrizione"
                            {...register("descrizione", {
                              required: true,
                            })}
                          />
                           {errors.codice?.type === "required" && (
                      <div className="invalid-feedback">
                        descrizione field is required.
                      </div>
                    )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-2 ">
                          <label>codice</label>
                        </div>
                        <div className="col-lg-5">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.codice && "is-invalid"
                            }`}
                            name="codice"
                            id="codice"
                            {...register("codice", {
                              required: true,
                            })}
                          />
                             {errors.codice?.type === "required" && (
                      <div className="invalid-feedback">
                        codice field is required.
                      </div>
                    )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-2 ">
                          <label>tipo</label>
                        </div>
                        <div className="col-lg-5">
                        <input
                            type="text"
                            className={`form-control ${
                              errors.tipo && "is-invalid"
                            }`}
                            name="tipo"
                            id="tipo"
                            {...register("tipo", {
                              required: true,
                            })}
                          />
                             {errors.tipo?.type === "required" && (
                      <div className="invalid-feedback">
                        tipo field is required.
                      </div>
                    )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </Modal.Body>
              <Modal.Footer>
              <button className="btn btn-pill btn-yellow">SALVA</button>
                {/* <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={() => {
                    reset({
                      descrizione:'',
                      tipo:'',
                      codice:''
                    })
                    clearErrors("descrizione");
                    clearErrors("tipo");

                    clearErrors("codice");
                    setShow(false)
                  } 
                  }
                
                >
                  ANNULLA
                </button> */}
           
              </Modal.Footer>
            </form>
          </Modal>
    </div>
  );
};

export default ChartOfAccount;
