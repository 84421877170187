import React, { useEffect, useState } from "react";
import SIdebar from "../../../../component/sidebar/SIdebar";
import Header from "../../../../component/header/Header";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useNavigate, Link ,useLocation} from "react-router-dom";
import useRequest from "../../../../hooks/useRequest";
import { toast } from "react-toastify";

function AddAgentsContatti() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [roles, setRoles] = useState([]);
  console.log(state,"sddfas")
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm();
  const { response, request } = useRequest();
  const { request: roleRequest, response: roleResponse } = useRequest();
  useEffect(() => {
    roleRequest("GET", "roles");
    reset({
        id_agent:state.id_agent
    })
    // setShowHeading(true)
  }, []);
  useEffect(() => {
    if (roleResponse) {
      setRoles(
        roleResponse.data.map((role) => {
          return {
            value: role.id_role,
            label: role.description,
          };
        })
      );
    }
  }, [roleResponse]);
  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success(response.message);
        navigate(-1);
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);
  const onSubmit = (data) => {
    request("POST", "add-contact-agent", data);
  };

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">ANAGRAFICA</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" onClick={() => navigate(-1)}>
                      CONTATTI
                    </a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Content */}
          <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="pageContent">
              <div className="themeTabs my-4">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="data-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#data-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="data-tab-pane"
                      aria-selected="true"
                    >
                      DATI CONTATTI
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="data-tab-pane"
                    role="tabpanel"
                    aria-labelledby="data-tab"
                    tabIndex={0}
                  >
                    <div className="tabH-full">
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Ruolo</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="col-lg-7">
                                  <Controller
                                    control={control}
                                    name="role"
                                    rules={{ required: true, minLength: 1 }}
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <Select
                                        menuPosition="fixed"
                                        options={roles}
                                        placeholder="Select Role"
                                        className={`basic-multi-select ${
                                          errors.role && "is-invalid"
                                        }`}
                                        classNamePrefix="select"
                                        onChange={(val) => onChange(val.label)}
                                      />
                                    )}
                                  />
                                  {errors.role?.type === "required" && (
                                    <div className="invalid-feedback">
                                      The ruolo field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>NOME</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.name && "is-invalid"
                                    }`}
                                    name="name"
                                    id="name"
                                    {...register("name", {
                                      required: true,
                                    })}
                                  />
                                  {errors.name?.type === "required" && (
                                    <div className="invalid-feedback">
                                      The name field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Cognome</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.surname && "is-invalid"
                                  }`}
                                  name="surname"
                                  id="surname"
                                  {...register("surname", {
                                    required: true,
                                  })}
                                />
                                {errors.surname?.type === "required" && (
                                  <div className="invalid-feedback">
                                    cognome field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Telefono</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="number"
                                  className={`form-control ${
                                    errors.phone && "is-invalid"
                                  }`}
                                  name=""
                                  id="phone"
                                  {...register("phone", {
                                    required: true,
                                  })}
                                />
                                {errors.phone?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The telefono field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>eMail</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="email"
                                  className={`form-control ${
                                    errors.mail && "is-invalid"
                                  }`}
                                  name="mail"
                                  id="mail"
                                  {...register("mail", {
                                    required: true,
                                    pattern:
                                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                  })}
                                />
                                {errors.mail?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The eMail field is required.
                                  </div>
                                )}
                                {errors.email?.type === "pattern" && (
                                  <div className="invalid-feedback">
                                    The eMail field is invalid.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Notes</label>
                              </div>
                              <div className="col-lg-7">
                                <textarea
                                  className={`form-control ${
                                    errors.note && "is-invalid"
                                  }`}
                                  {...register("note", {
                                    required: true,
                                  })}
                                  cols="30"
                                  rows="5"
                                ></textarea>
                                {errors.note?.type === "required" && (
                                  <div className="invalid-feedback">
                                    the note field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-md-4 mt-3 text-center text-md-start">
                      <button className="btn btn-pill btn-yellow">SALVA</button>
                      <button
                        className="btn btn-pill btn-blue ms-2 ms-md-3"
                        onClick={() => {
                          navigate(-1)
                          localStorage.setItem("active",1)
                        }}
                      >
                        ANNULLA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddAgentsContatti;
