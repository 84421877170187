import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";
function PrezziCatalogo(state) {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const [show, setShow] = useState(false);
  const { response, request } = useRequest();
  useEffect(() => {
    reset({
      ...state.state,
    });
  }, []);

  useEffect(() => {
    setShow(state.state.fixed_price);
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success("Catalogare aggiornato correttamente");
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    request("POST", "edit-catalog", data);
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="general-tab-pane"
        role="tabpanel"
        aria-labelledby="general-tab"
        tabIndex={0}
      >
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
          <div className="tabH-full">
            <div className="row g-lg-0">
              <div className="col-md-4">
                <div className="form-group">
                  <div className="row align-items-center g-lg-3">
                    <div className="col-lg-5 text-lg-end">
                      <label>Prezzo fisso</label>
                    </div>
                    <div className="col-lg-7">
                      <div className="col-lg-7">
                        <input
                          className="form-check-input checklg"
                          type="checkbox"
                          defaultValue=""
                          id="fixed_price"
                          {...register("fixed_price")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-4">
                <div className="form-group">
                  <div className="row align-items-center g-lg-3">
                    <div className="col-lg-5 text-lg-end">
                      <label>Margine</label>
                    </div>
                    <div className="col-lg-7">
                      <div className="col-lg-7">
                        <input
                          type="number"
                          // disable ={show===false}
                          // disable={value.toString(show===false)}
                          className={`form-control `}
                          name="margin"
                          id="margin"
                          {...register("margin", {})}
                        />
                        {errors.increase?.type === "required" && (
                          <div className="invalid-feedback">
                            The increase field is required.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <div className="row align-items-center g-lg-3">
                    <div className="col-lg-5 text-lg-end">
                      <label>Selling price</label>
                    </div>
                    <div className="col-lg-7">
                      <input
                        type="number"
                        pattern="^\d+(?:\.\d{1,2})?$"
                        min="0" max="10000000000" step="0.25"
                        className={`form-control `}
                        name="selling_price"
                        id="selling_price"
                        {...register("selling_price")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-4">
                <div className="form-group">
                  {/* <div className="row align-items-center g-lg-3">
                  <div className="col-lg-5 text-lg-end">
                    <label>price</label>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="number"
                      className={`form-control ${errors.price && "is-invalid"}`}
                      name=""
                      id="price"
                      {...register("price", {
                        required: true,
                      })}
                    />
                    {errors.price?.type === "required" && (
                      <div className="invalid-feedback">
                        The price field is required.
                      </div>
                    )}
                  </div>
                </div> */}
                </div>
              </div>
            </div>
            <div className="row g-lg-0">
              <div className="col-md-4">
                <div className="form-group">
                  <div className="row align-items-center g-lg-3">
                    <div className="col-lg-5 text-lg-end">
                      <label> Corr. margine</label>
                    </div>
                    <div className="col-lg-7">
                      <input
                        type="number"
                        pattern="^\d+(?:\.\d{1,2})?$"
                        min="0" max="10000000000" step="0.25"
                        className={`form-control `}
                        name="margin_fix"
                        id="margin_fix"
                        {...register("margin_fix")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-md-4 mt-3 text-center text-md-start">
            <button className="btn btn-pill btn-yellow">SALVA</button>
            <button
              className="btn btn-pill btn-blue ms-2 ms-md-3"
              onClick={() => {
                navigate(-1);
              }}
            >
              ANNULLA
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default PrezziCatalogo;
