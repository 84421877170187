import React, { useEffect, useState } from "react";
import { API } from "../../constant/api";
import axios from "axios";
import useRequest from "../../hooks/useRequest";
import Pagination from "../../component/Pagination/Pagination";
import { Link } from "react-router-dom";

const searchQueryHandler = (page, per_page, name = "") => {
  return `customer-contacts?page=${page}&per_page=${per_page}&name=${name}`;
};

const Contatti = () => {
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [coustmerdata, setCoustmerdata] = useState({});
  const { request, response } = useRequest();

  useEffect(() => {
    request("GET", searchQueryHandler(1, perPage, searchQuery));
  }, []);

  useEffect(() => {
    if (response) {
      setContacts(response.data?.contact_customer?.results);
      setTotalDocuments(response.data?.contact_customer?.total_records);
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setContacts([]);
    setPage(selected + 1);
    request("GET", searchQueryHandler(selected + 1, perPage, searchQuery));
  };

  const onSearchHandler = () => {
    request("GET", searchQueryHandler(1, perPage, searchQuery));
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    request("GET", searchQueryHandler(1, event.target.value, searchQuery));
    setPage(1);

    setPerPage(event.target.value);
  };
  return (
    <div
      className="tab-pane fade"
      id="contact-tab-pane"
      role="tabpanel"
      aria-labelledby="contact-tab"
      tabIndex={0}
    >
      {/* <Link to="/contatti/add" className="btn btn-sm btn-primary ms-3">
        Add
      </Link> */}

      <div className="themeTable customerTable">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>role</th>
              <th>name</th>
              <th>surname</th>
              <th>email</th>
              <th>note</th>
              <th>phone number</th>
              {/* <th /> */}
            </tr>
          </thead>
          <tbody>
            {contacts.length > 0 &&
              contacts.map((contact) => (
                <tr key={contact?.id_contact_customer}>
                  <td data-th="ID">{contact?.id_contact_customer}</td>
                  <td data-th="role">{contact?.role}</td>
                  <td data-th="name">{contact?.name}</td>
                  <td data-th="surname">{contact?.surname}</td>
                  <td data-th="email">{contact?.email}</td>
                  <td data-th="note">{contact?.note}</td>
                  <td data-th="phone_number">{contact?.phone_number}</td>
                  {/* <td data-th="ACTION">

                    <Link
                      to={`/contatti/edit/${contact?.id_contact_customer}`}
                      className="btn btn-sm btn-primary"
                      state={contact}
                    >
                      <i className="fas fa-pencil-alt" />
                    </Link>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {perPage !== 0 && (
        <Pagination
          page={page}
          totalRecords={totalDocuments}
          getNewData={fetchMoreData}
          perPage={perPage}
          defaultPerPage={1}
          perPageChangeHandler={perPageChangeHandler}
          currentDocLength={contacts.length}
        />
      )}
    </div>
  );
};

export default Contatti;
