import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SIdebar from "../../../component/sidebar/SIdebar";
import AmministrazioneSideBar from "../../../component/sidebar/AmministrazioneSideBar";
import Header from "../../../component/header/Header";
import Pagination from "../../../component/Pagination/Pagination";
import axios from "axios";
import useRequest from "../../../hooks/useRequest";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Moment from "moment";  


const searchQueryHandler = (page, per_page, name = "",) => {
  return `passive-documents?page=${page}&per_page=${per_page}&name=${name}`;
};

function Ordinidivenditalist() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValue,
        reset,
      } = useForm();

  let navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [Documentipassivi, setDocumentipassivi] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [date, setDate] = useState(new Date());
  const [doctype, setdoctype] = useState([]);

//   const [startDate, setStartDate] = useState(new Date());

  const { request, response } = useRequest();
  const { request: requestDoctype, response: responseDoctype } = useRequest();


  useEffect(() => {
    requestDoctype("GET", "doc-type")
    request("GET", searchQueryHandler(1, perPage, searchQuery, ));
  }, []);

  useEffect(() => {
    if (response) {
        debugger
      setDocumentipassivi(response.data?.results);
      setTotalDocuments(response.data?.total_records);
    }
  }, [response]);

  useEffect(() => {
    if (responseDoctype) {
      setdoctype(
        responseDoctype.data?.docs?.map((doc) => {
          return {
            value: doc.id_doc_type,
            label: doc.descrizione,
          };
        })
      );
    }
  }, [responseDoctype]);

  useEffect(() => {

request(
    "GET",
    searchQueryHandler(1, perPage, searchQuery, )
  );
  setPage(1)
  }, [date]);

  const fetchMoreData = ({ selected }) => {
    setDocumentipassivi([]);
    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(selected + 1, perPage, searchQuery, )
    );
  };

  const onSearchHandler = () => {
    request("GET", searchQueryHandler(1, perPage, searchQuery, ));
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    request(
      "GET",
      searchQueryHandler(1, event.target.value, searchQuery,  )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const provisionalHandler = (e) => {
    request(
      "GET",
      searchQueryHandler(1, perPage, searchQuery, e.target.value)
    );
    setPage(1);
    // setDate(e.target.checked);
  };

  let listToDisplay = Documentipassivi;

  // if (searchQuery !== "") {
  //   debugger
  //   listToDisplay = Documentipassivi.filter((cut) => {
  //     return cut.includes(searchQuery);
  //   });
  // }

  const handleChangeWithLib = debounce((searchQuery) => {
    request("GET", searchQueryHandler(1, perPage, searchQuery, date));
  }, 500);

  const options = [
    { value: 'Tutti', label: 'Tutti' },
    { value: 'Ricevuto', label: 'Ricevuto' },
    { value: 'Accettato', label: 'Accettato' },
    { value: 'Protocollato', label: 'Protocollato' },
    { value: 'Annullato', label: 'Annullato' }


  ]
  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <AmministrazioneSideBar />
        <div className="dashboard-content">
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">AMMINISTRAZIONE</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">  CICLO ATTIVO</a>
                  </li>
                  {/* <li className="breadcrumb-item active" aria-current="page">
                    CLIENTI
                  </li> */}
                </ol>
              </nav>
              {/* <div
                className="btn btn-sm btn-primary ms-3"
                onClick={() => navigate("/addClienti")}
              >
                <i className="far fa-plus" />
              </div> */}
              {/* <Link to="/Documentipassiviadd" className="btn btn-sm btn-primary ms-3">
                <i className="far fa-plus" />
              </Link> */}
            </div>
            <div className="pagesearch">
              <div className="pagesearch-block">
                <input
                  type="text"
                  placeholder="DENOMINAZIONE"
                  value={searchQuery}
                  // onChange={(e) =>

                  //  }
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchQuery(e.target.value);
                    handleChangeWithLib(e.target.value);
                  }}
                />
                {/* <button onClick={onSearchHandler}>
                  <i className="fas fa-search" />
                </button> */}
              </div>
              
            </div>
          </div>
          {/* Page Content */}
          <div className="pageContent">
          <div className="row g-lg-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>	Stato</label>
                          </div>
                          <div className="col-lg-7">
                          <Select options={options} />
                            {/* {errors.number?.type === "required" && (
                              <div className="invalid-feedback">
                                Numero Del Documento field is required.
                              </div>
                            )} */}
                          </div>
                          <div className="col-lg-7">
                            {/* {errors.id_contact_customer?.type ===
                              "required" && (
                              <div className="invalid-feedback">
                                The contact field is required.
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Tipo Di Documento</label>
                          </div>
                          <div className="col-lg-7">
                          <Controller
                              control={control}
                              rules={{ required: true, minLength: 1 }}
                              name="id_doc_type"
                              render={({ field: { onChange, value, ref } }) => (
                                <Select
                                  // menuPosition="fixed"
                                  options={doctype}
                                  classNamePrefix="select"
                                  placeholder="Select"
                                  className={`basic-multi-select ${
                                    errors.id_provider && "is-invalid"
                                  }`}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                />
                              )}
                            />
                            {/* {errors.number?.type === "required" && (
                              <div className="invalid-feedback">
                                Numero Del Documento field is required.
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="row align-items-center g-lg-3">
                          <div className="col-lg-5 text-lg-end">
                            <label>Number </label>
                          </div>
                          <div className="col-lg-7">
                            <input
                              type="number"
                              className={`form-control `}
                              name=" number"
                              id=" number"
                            //   {...register("number", {
                            //     required: true,
                            //   })}
                            />
                            {/* {errors.number?.type === "required" && (
                              <div className="invalid-feedback">
                                Numero Del Documento field is required.
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            <div className="themeTable customerTable">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Number</th>
                    <th>Stato</th>
                    <th>Tipo Di Documento</th>
                  


                    <th />
                  </tr>
                </thead>
                <tbody>
                  {listToDisplay.length > 0 &&
                    listToDisplay.map((Documentipassivi, index) => (
                      <tr key={Documentipassivi.id_customer}>
                        {/* <td data-th="Prodotto">1</td> */}

                        <td
                          data-th="NUMBER"
                          style={{ textAlign: "left" }}
                        >
                          {
                            Documentipassivi.number
                          }
                        </td>
                        <td data-th="Dati">
                          {
                            Documentipassivi.docTypeData.descrizione
                          }
                        </td>
                        <td data-th="Nome">
                          {Documentipassivi.state}
                        </td>
                       
                        <td data-th="ACTION">
                          <Link
                            to="/documentopassivo/Edit"
                            state={Documentipassivi}
                            className="btn btn-sm btn-primary"
                          >
                          <i className="fas fa-pencil-alt" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {perPage !== 0 && (
              <Pagination
                page={page}
                totalRecords={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                defaultPerPage={10}
                perPageChangeHandler={perPageChangeHandler}
                currentDocLength={Documentipassivi.length}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Ordinidivenditalist;
