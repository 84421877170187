import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AmministrazioneSideBar from "../../../component/sidebar/AmministrazioneSideBar";
import Header from "../../../component/header/Header";
import Pagination from "../../../component/Pagination/Pagination";
import axios from "axios";
import Moment from "moment";
import Select from "react-select";
import useRequest from "../../../hooks/useRequest";
import { debounce } from "lodash";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
  

function Ordinidivendita() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValue,
    reset,
  } = useForm();
  const options = [
    { value: 0, label: "Cartoni" },
    { value: 1, label: "Pezzi" },
  ];

  let navigate = useNavigate();

  const [agents, setAgents] = useState([]);
  const [agentsValue, setAgentsValue] = useState([]);
  const [products, setProducts] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setendDate] = useState(startDate);

  const { request, response } = useRequest();
  const { request: requestAgent, response: responseAgent } = useRequest();
  const { request: requestProduct, response: responseProduct } = useRequest();

  useEffect(() => {
    requestAgent("GET", `get-all-agent-selling-point`);
    requestProduct("GET", `get-all-catalog`);
  }, []);

 useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success(response.message);
        navigate("/ordinidivenditaelenco");
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  useEffect(() => {
    if (responseAgent) {
      debugger
      setAgents(
        responseAgent.data?.agentSellingPointData?.map((sellingPoint) => {
          return {
            value: sellingPoint.id_agent_sp,
            label: sellingPoint.sellingPointData?.description,
            agent: sellingPoint.agentData.naming,
          };
        })
      );
    }
  }, [responseAgent]);

  useEffect(() => {
    if (responseProduct) {
      setProducts(
        responseProduct.data?.map((product) => {
          return {
            value: product.id_catalog,
            label: product.description,
          };
        })
      );
    }
  }, [responseProduct]);

  const onSubmit = (data) => {
    data["order_date"] = Moment(startDate).format("MM-DD-YYYY");
    data["delivery_date"] = Moment(endDate).format("MM-DD-YYYY");
    request("POST", "add-selling-order", data);
  };

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <AmministrazioneSideBar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">AMMINISTRAZIONE</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">CICLO ATTIVO</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">INSERISCI</a>
                  </li>
                  {/* <li className="breadcrumb-item active" aria-current="page">
                    SLM00001
                  </li> */}
                </ol>
              </nav>
            </div>
          </div>

          {/* Page Content */}
         
          <div className="pageContent">
            <div className="themeTabs my-4">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="data-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#data-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="data-tab-pane"
                    aria-selected="true"
                  >
                    Nuovo ordine vendita
                  </button>
                </li>
              </ul>
              <form method="post" onSubmit={handleSubmit(onSubmit)}>
                <div className="tabH-full">
                  <div
                    className="tab-pane fade show active"
                    id="data-tab-pane"
                    role="tabpanel"
                    aria-labelledby="data-tab"
                    tabIndex={0}
                  >
                    <div className="tabH-full">
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Punto vendita</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_agent_sp"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      options={agents}
                                      classNamePrefix="select"
                                      placeholder="Select "
                                      className={`basic-multi-select ${
                                        errors.id_agent_sp && "is-invalid"
                                      }`}
                                      onChange={(e) => {
                                        console.log(e);
                                        onChange(e.value);
                                        setAgentsValue(e.agent);
                                      }}
                                    />
                                  )}
                                />
                                {errors.id_agent_sp?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The Punto vendita field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Agente</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control `}
                                  name="lab_type"
                                  id="lab_type"
                                  defaultValue={agentsValue}
                                  disabled="true"
                                  // {...register("lab_type")}
                                />
                                {/* {errors.birth_country?.type === "required" && (
                                  <div className="invalid-feedback">
                                    Paese di nascita field is required.
                                  </div>
                                )} */}
                              </div>
                              <div className="col-lg-7">
                                {errors.id_contact_customer?.type ===
                                  "required" && (
                                  <div className="invalid-feedback">
                                    The contact field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Prodotto</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  name="id_catalog"
                                  rules={{ required: true, minLength: 1 }}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <Select
                                      options={products}
                                      placeholder="Select Products"
                                      className={`basic-multi-select ${
                                        errors.id_catalog && "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      onChange={(val) => {
                                        onChange(val.value);
                                      }}
                                    />
                                  )}
                                />
                                {errors.id_catalog?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The Prodotto field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Unita</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  name="um"
                                  rules={{ required: true, minLength: 1 }}
                                  render={({
                                    field: { onChange, label, value, ref },
                                  }) => (
                                    <Select
                                      options={options}
                                      placeholder="Select Unita"
                                      className={`basic-multi-select ${
                                        errors.um && "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      onChange={(val) => {
                                        onChange(val.value);
                                      }}
                                    />
                                  )}
                                />
                                {errors.um?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The Unita field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Data Dell'ordine</label>
                              </div>
                              <div className="col-lg-7">
                                <DatePicker
                                  placeholder="MM/DD//YYYY"
                                  className={`form-control`}
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                />

                                {errors.start_time?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The start time field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Data Di Consegna</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  name="order_date"
                                  // rules={{ required: true, minLength: 1 }}
                                  control={control}
                                  render={({ onChange, value }) => (
                                    <DatePicker
                                      placeholder="MM/DD//YYYY"
                                      className={`form-control`}
                                      minDate={startDate}
                                      selected={endDate}
                                      onChange={(date) => setendDate(date)}
                                    />
                                  )}
                                />

                                {/* {errors.order_date?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The hour field is required.
                                  </div>
                                )} */}
                                {/* {errors.end_time?.type === "manual" && (
                                  <div className="invalid-feedback">
                                    The end time should be greater than start
                                    time.
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Quantity</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="number"
                                  className={`form-control ${
                                    errors.quantity && "is-invalid"
                                  }`}
                                  name="quantity"
                                  id="quantity"
                                  {...register("quantity", {
                                    required: true,
                                  })}
                                />
                                {errors.quantity?.type === "required" && (
                                  <div className="invalid-feedback">
                                    Quantity field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>State</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.state && "is-invalid"
                                  }`}
                                  name="state"
                                  id="state"
                                  {...register("state", {
                                    required: true,
                                  })}
                                />
                                {errors.state?.type === "required" && (
                                  <div className="invalid-feedback">
                                    state field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Note di intestazione</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.note_delivery && "is-invalid"
                                  }`}
                                  name="note_delivery"
                                  id="note_delivery"
                                  {...register("note_delivery", {
                                    required: true,
                                  })}
                                />
                                {errors.note_delivery?.type === "required" && (
                                  <div className="invalid-feedback">
                                    consegna della nota field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Ext Reference</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  className="form-check-input checklg"
                                  type="checkbox"
                                  defaultValue=""
                                  id="ext_reference"
                                  {...register("ext_reference")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Prezzo</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="number"
                                  className={`form-control ${
                                    errors.note_delivery && "is-invalid"
                                  }`}
                                  name="price"
                                  id="price"
                                  {...register("price", {
                                    required: true,
                                  })}
                                />
                                {errors.price?.type === "required" && (
                                  <div className="invalid-feedback">
                                    Prezzo field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Regalo</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  className="form-check-input checklg"
                                  type="checkbox"
                                  defaultValue=""
                                  id="gift"
                                  {...register("gift")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Note</label>
                              </div>
                              <div className="col-lg-7">
                                <textarea
                                  className={`form-control ${
                                    errors.note && "is-invalid"
                                  }`}
                                  cols="20"
                                  rows="5"
                                  {...register("note", {
                                    required: true,
                                  })}
                                ></textarea>
                                {errors.note?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The Note field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Note laboratorio</label>
                              </div>
                              <div className="col-lg-7">
                                <textarea
                                  className={`form-control ${
                                    errors.note_lab && "is-invalid"
                                  }`}
                                  cols="20"
                                  rows="5"
                                  {...register("note_lab", {
                                    required: true,
                                  })}
                                ></textarea>
                                {errors.note_lab?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The note_lab field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-md-4 mt-3 text-center text-md-start">
                  <button className="btn btn-pill btn-yellow">SALVA</button>
                  <button
                    className="btn btn-pill btn-blue ms-2 ms-md-3"
                    onClick={() => {
                      navigate("/ordinidivenditaelenco");
                    }}
                  >
                    ANNULLA
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
}

export default Ordinidivendita;
