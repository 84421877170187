import React, { useEffect, useState } from "react";
import SIdebar from "../../../component/sidebar/SIdebar";
import Header from "../../../component/header/Header";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Areamanager from "./Areamanager";


function AddAgents() {
  let navigate = useNavigate();
  const [com,setCom] = useState('')
  const [country, setCountry] = useState([]);
  const [provincia, setProvincia] = useState([]);
  const [region, setRegion] = useState([]);
  const [catalogo, setCatalogo] = useState([]);
  const [acommercial, setAcommercial] = useState([]);
  const [docline, setDocline] = useState([]);
  const [commission, setCommission] = useState([]);
  const [showcommission, setShowcommission] = useState(false);
  const [showcommercial, setShowcommercial] = useState(false);
  const [commissionModel, setCommissionModel] = useState({
    description: "",
    note: "",
    calculated:false
  });

  const [commercialModel, setCommercialModel] = useState({
    description: "",
    porcentage: ""
   
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm();

  const {
    register:resgisterCommercial,
    handleSubmit:handleSubmit2,
    formState: { errors:errors2 },
    reset
    // control,
    // setValue
  } = useForm();
  
  const {
    register:resgisterCommission,
    handleSubmit:handleSubmit3,
    formState: { errors:errors3 },
    reset:reset3
    // control,
    // setValue
  } = useForm();

  const { response, request } = useRequest();
  const { request: countryRequest, response: countryResponse } = useRequest();
  const { request: provinceRequest, response: provinceResponse } = useRequest();
  const { request: regionsRequest, response: regionsResponse } = useRequest();
  const { request: catalogRequest, response: catalogResponse } = useRequest();
  const { request: commercialRequest, response: commercialResponse } =
    useRequest();
  const { request: commissionRequest, response: commissionResponse } =
    useRequest();
    const { request: commissionModelRequest, response: commissionModelResponse } = useRequest();
    const { request:commercialModelRequest, response: commercialModelResponse } = useRequest();
    const { request:doclineRequest, response: doclineResponse } = useRequest();


  useEffect(() => {
    countryRequest("GET", "country");
    provinceRequest("GET", "provinces");
    regionsRequest("GET", "regions");
    catalogRequest("GET", "get-all-catalog");
    commercialRequest("GET", "get-commercial-area");
    commissionRequest("GET", "get-commission-type");
    doclineRequest("GET","get-all-active-doc-line")

  }, []);

  useEffect(() => {
    if (countryResponse) {
      setCountry(
        countryResponse.data?.country.map((c) => {
          return {
            value: c.id_country,
            label: c.nome,
          };
        })
      );
    }
  }, [countryResponse]);

  useEffect(() => {
    if (catalogResponse) {
      setCatalogo(
        catalogResponse.data?.map((region) => {
          return {
            value: region.id_catalog,
            label: region.description,
          };
        })
      );
    }
  }, [catalogResponse]);

  useEffect(() => {
    if (doclineResponse) {
     
      setDocline(doclineResponse.data?.activeDocumentLineData.map((docline) => {
        return {
          value: docline.id_active_doc_line,
          label: docline.description,
        };
      })
      );
    }
  }, [doclineResponse]);

  useEffect(() => {
    if (commercialResponse) {
      setAcommercial(commercialResponse.data?.map((region) => {
        return {
          value: region.id_area_comm,
          label: region.description,
        };
      })
      );
    }
  }, [commercialResponse]);

  useEffect(() => {
    if (commissionResponse) {
      setCommission (
        commissionResponse.data?.map((region) => {
          return {
            value: region.id_commission_type,
            label: region.description,
          };
        })
      );
    }
  }, [commissionResponse]);


  useEffect(() => {
    if (provinceResponse) {
      setProvincia(
        provinceResponse.data?.provinces.map((province) => {
          return {
            value: province.id_province,
            label: province.nomeprovincia,
          };
        })
      );
    }
  }, [provinceResponse]);
  useEffect(() => {
    if (regionsResponse) {
      setRegion(
        regionsResponse.data?.region.map((region) => {
          return {
            value: region.idregione,
            label: region.nomeregione,
          };
        })
      );
    }
  }, [regionsResponse]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success(response.message);
        navigate("/agents");
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  useEffect(() => {
    if (commissionModelResponse) {

      if (commissionModelResponse.status === "SUCCESS") {
        toast.success(commissionModelResponse.message);
        setShowcommission(false)
        reset3({
          description: "",
          note: "",
          calculated:false
        })
        // setCommissionModel("")
        commissionRequest("GET", "get-commission-type");
      } else {
        toast.error(commissionModelResponse.message);
        setShowcommission(true)
      }
    }
  }, [commissionModelResponse]);

  useEffect(() => {
    if (commercialModelResponse) {
      if (commercialModelResponse.status === "SUCCESS") {
        toast.success(commercialModelResponse.message);
        setShowcommercial(false)
        reset({
          description: "",
          porcentage: ""
        })
       
        commercialRequest("GET", "get-commercial-area");
      } else {
        toast.error(commercialModelResponse.message);
        setShowcommission(true)
      }
    }
  }, [commercialModelResponse]);

  const onModalSubmit = (data) => {
    // e.preventDefault();
    commissionModelRequest("POST", "add-commission-type",data)
  };

 const onCommercialModalSubmit=(data)=>{
   
    // e.preventDefault();
    commercialModelRequest("POST", "add-commercial-area",data)
  }
  const onSubmit = (data) => {
    request("POST", "add-agent", data);
  };

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">ANAGRAFICA</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/agents">AGENTI</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Content */}

          <div className="pageContent">
            <div className="themeTabs my-4">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="data-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#data-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="data-tab-pane"
                    aria-selected="true"
                  >
                    Dati Agente
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="general-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#general-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="general-tab-pane"
                    aria-selected="false"
                  >Responsabile di zona
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="data-tab-pane"
                  role="tabpanel"
                  aria-labelledby="data-tab"
                  tabIndex={0}
                >
                  <form method="post" onSubmit={handleSubmit(onSubmit)}>
                    <div className="tabH-full">
                      <div className="row g-lg-0"></div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>NOME</label>
                              </div>

                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.naming && "is-invalid"
                                  }`}
                                  name="name"
                                  id="name"
                                  {...register("naming", {
                                    required: true,
                                  })}
                                />
                                {errors.naming?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The NOME field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>PORCENTAGE</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="number"
                                  pattern="^\d+(?:\.\d{1,2})?$"
                                       step="0.25"
                                  min="0"
                                  max="100"
                                  className={`form-control ${
                                    errors.porcentage && "is-invalid"
                                  }`}
                                  name="porcentage"
                                  id="porcentage"
                                  //   value={age}
                                  {...register("porcentage", {
                                    required: true,
                                  })}
                                />
                                {errors.porcentage?.type === "required" && (
                                  <div className="invalid-feedback">
                                    PORCENTAGE field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>CODICE FISCALE</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.fiscal_code && "is-invalid"
                                  }`}
                                  name=""
                                  id="fiscal_code"
                                  {...register("fiscal_code", {
                                    required: true,
                                  })}
                                />
                                {errors.fiscal_code?.type === "required" && (
                                  <div className="invalid-feedback">
                                    CODICE FISCALE field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>ATTIVO</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="active"
                                    {...register("active")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="active"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>I.V.A</label>
                              </div>

                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.vat && "is-invalid"
                                  }`}
                                  name=""
                                  id="vat"
                                  {...register("vat", {
                                    required: true,
                                  })}
                                />
                                {errors.vat?.type === "required" && (
                                  <div className="invalid-feedback">
                                    i.v.a field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>NAZIONE</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_country"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      // menuPosition="fixed"
                                      options={country}
                                      classNamePrefix="select"
                                      placeholder="Select Nazione"
                                      className={`basic-multi-select ${
                                        errors.id_country && "is-invalid"
                                      }`}
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.id_country?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The NAZIONE field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0"></div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>PROVINCIA</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_province"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      // menuPosition="fixed"
                                      options={provincia}
                                      classNamePrefix="select province"
                                      placeholder="select "
                                      className={`basic-multi-select ${
                                        errors.id_province && "is-invalid"
                                      }`}
                                      onChange={(e) => {onChange(e.value)}}
                                    />
                                  )}
                                />
                                {errors.id_province?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The province field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>POSTO</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.place && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("place", {
                                    required: true,
                                  })}
                                />
                                {errors.place?.type === "required" && (
                                  <div className="invalid-feedback">
                                    POSTO field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>STRADA</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.street && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("street", {
                                    required: true,
                                  })}
                               
                                />
                                {errors.street?.type === "required" && (
                                  <div className="invalid-feedback">
                                    STRADA field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>CAP</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.zip_code && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("zip_code", {
                                    required: true,
                                  })}
                                />
                                {errors.zip_code?.type === "required" && (
                                  <div className="invalid-feedback">
                                    CAP field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label> Catalogare</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_catalog"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      // menuPosition="fixed"
                                      options={catalogo}
                                      classNamePrefix="select province"
                                      placeholder="select "
                                      className={`basic-multi-select ${
                                        errors.id_catalog && "is-invalid"
                                      }`}
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.id_regione?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The REGIONE field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>prezzo</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="number"
                                  className={`form-control ${
                                    errors.place && "is-invalid"
                                  }`}
                                  name="price"
                                  id="price"
                                  {...register("price", {
                                    required: true,
                                  })}
                                />
                                {errors.price?.type === "required" && (
                                  <div className="invalid-feedback">
                                    prezzo field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>blocked price </label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="blocked_price "
                                    {...register("blocked_price")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="blocked_price"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>selezionato</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="selected "
                                    {...register("selected")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="selected "
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>commissione</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="number"
                                  pattern="^\d+(?:\.\d{1,2})?$"
                                  min="0" max="10000000000" step="0.25" 
                                  className={`form-control ${
                                    errors.commission && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("commission", {
                                    required: true,
                                  })}
                                />
                                {errors.commission?.type === "required" && (
                                  <div className="invalid-feedback">
                                    commissione field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>R commissione</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="number"
                                  pattern="^\d+(?:\.\d{1,2})?$"
                                  min="0" max="10000000000" step="0.25"
                                  className={`form-control ${
                                    errors.r_commission && "is-invalid"
                                  }`}
                                  name="price"
                                  id="price"
                                  {...register("r_commission", {
                                    required: true,
                                  })}
                                />
                                {errors.price?.type === "required" && (
                                  <div className="invalid-feedback">
                                    prezzo field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>aumento</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="number"
                                  pattern="^\d+(?:\.\d{1,2})?$"
                                  min="0" max="10000000000" step="0.25"
                                  className={`form-control ${
                                    errors.increase && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("increase", {
                                    required: true,
                                  })}
                                />
                                {errors.increase?.type === "required" && (
                                  <div className="invalid-feedback">
                                    aumento field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>riga del documento attivo</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_active_doc_line"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      options={docline}
                                      placeholder="Description"
                                      className={`basic-multi-select ${
                                        errors.id_active_doc_line  && "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      onChange={(e) => {onChange(e.value)}}
                                     
                                      // value={{value:commercialModel.porcentage, label:commercialModel.description}}
                                    />
                                  )}
                                />
                                  {errors.id_active_doc_line?.type === "required" && (
                                  <div className="invalid-feedback">
                                    riga del documento attivo field is required.
                                  </div>
                                )}
                              </div>
                            
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>selezionato</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="selected "
                                    {...register("selected")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="selected "
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>AREA COMMERCIALE</label>
                              </div>
                              <div className="col-lg-5">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_area_comm"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      options={acommercial}
                                      placeholder="Description"
                                      className={`basic-multi-select ${
                                        errors.id_area_comm  && "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      onChange={(e) => {onChange(e.value)}}
                                     
                                      // value={{value:commercialModel.porcentage, label:commercialModel.description}}
                                    />
                                  )}
                                />
                                  {errors.id_area_comm?.type === "required" && (
                                  <div className="invalid-feedback">
                                    AREA COMMERCIALE field is required.
                                  </div>
                                )}
                              </div>
                            
                              <div className="col-lg-1">
                                <button
                                  className="btn -pill btn-yellow"
                                  type="button"
                                  onClick={() => setShowcommercial(true)}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>TIPO DI COMMISSIONE</label>
                              </div>
                              <div className="col-lg-5">
                                <Controller
                                  control={control}
                                  name="id_commission_type"
                                  rules={{ required: true, minLength: 1 }}
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                  
                                      options={commission}
                                      placeholder="select "
                                      className={`basic-multi-select ${
                                        errors.id_commission_type  && "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      onChange={(e) => onChange(e.value)}
                                      
                                      // value={{value:commissionModel.description, label:commissionModel.note}}
                                    />
                                  )}
                                />
                                 {errors.id_commission_type?.type === "required" && (
                                  <div className="invalid-feedback">
                                    TIPO DI COMMISSIONE field is required.
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-1">
                                <button
                                  className="btn -pill btn-yellow"
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowcommission(true);
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-md-4 mt-3 text-center text-md-start">
                      <button className="btn btn-pill btn-yellow">SALVA</button>
                      <button
                        className="btn btn-pill btn-blue ms-2 ms-md-3"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        ANNULLA
                      </button>
                    </div>
                  </form>

                  <Modal
                    show={showcommission}
                    onHide={() => setShowcommission(false)}
                    backdrop="static"
                    keyboard={false}
                  >
                    <form onSubmit={handleSubmit3(onModalSubmit)}>
                      <Modal.Header>
                        <Modal.Title>generale</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row g-lg-0">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row align-items-center">
                                <div className="col-lg-3 ">
                                  <label>description</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors3.description && "is-invalid"
                                    }`}
                                    name="description"
                                    id="description"
                                    {...resgisterCommission("description", {
                                      required: true,
                                    })}
                                   
                                  />
                                  {errors3.description?.type === "required" && (
                                    <div className="invalid-feedback">
                                      Description field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row align-items-center">
                                <div className="col-lg-3 ">
                                  <label>Note</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors3.note && "is-invalid"
                                    }`}
                                    name="note"
                                    id="note "
                                    {...resgisterCommission("note", {
                                      required: true,
                                    })}
                                    // onChange={(e) => {
                                    //   // setCommissionModel(...commissionModel,)
                                    //   setCommissionModel({
                                    //     ...commissionModel,
                                    //     [e.target.name]: e.target.value,
                                    //   });
                                    // }}
                                  />
                                  {errors3.note?.type === "required" && (
                                    <div className="invalid-feedback">
                                      Note field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row align-items-center">
                                <div className="col-lg-3 ">
                                  <label>calculated</label>
                                </div>
                           
                                <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    id="calculated "
                                    name="calculated"
                                    {...resgisterCommission("calculated")}
                                    // onChange={(e) => {
                                    //   setCommissionModel({
                                    //     ...commissionModel,
                                    //     [e.target.name]: e.target.checked,
                                    //   });
                                    // }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="selected "
                                  ></label>
                                </div>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-pill btn-yellow"
                          // disabled={subcategorys === "" ? true : false}
                        >
                          SALVA
                        </button>
                        <button
                          className="btn btn-pill btn-blue ms-2 ms-md-3"
                          onClick={(e) => {
                            e.preventDefault();
                            reset3({
                              description: "",
                              note: "",
                              calculated:false
                            })
                            setShowcommission(false);
                          
                            
                          }}
                        >
                          ANNULLA
                        </button>
                      </Modal.Footer>
                    </form>
                  </Modal>

                  <Modal
                    show={showcommercial}
                    onHide={() => setShowcommercial(false)}
                    backdrop="static"
                    keyboard={false}
                  >
                    <form  method="post" onSubmit={ handleSubmit2(onCommercialModalSubmit)}>
                      <Modal.Header>
                        <Modal.Title>generale</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row g-lg-0">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row align-items-center">
                                <div className="col-lg-3 ">
                                  <label>DESCRIPTION</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors2.description && "is-invalid"
                                    }`}
                                    name="description"
                                    id="description"
                                    {...resgisterCommercial("description", {
                                      required: true,
                                    })}
                                    // onChange={(e) => {
                                    //   // setCommissionModel(...commissionModel,)
                                    //   setCom(e.target.value)
                                    //   setCommercialModel({
                                    //     ...commercialModel,
                                    //     [e.target.name]: e.target.value,
                                    //   });
                                    // }}
                                  />
                                  {errors2.description?.type === "required" && (
                                    <div className="invalid-feedback">
                                      Description field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row align-items-center">
                                <div className="col-lg-3 ">
                                  <label>PORCENTAGE</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    min="00"
                                    max="100"
                                    className={`form-control ${
                                      errors2.porcentage && "is-invalid"
                                    }`}
                                    name="porcentage"
                                    id="porcentage "
                                    {...resgisterCommercial("porcentage", {
                                      required: true,
                                    })}
                                    // onChange={(e) => {
                                    //   // setCommissionModel(...commissionModel,)
                                    //   setCommercialModel({
                                    //     ...commercialModel,
                                    //     [e.target.name]: e.target.value,
                                    //   });
                                    // }}
                                  />
                                  {errors2.porcentage?.type === "required" && (
                                    <div className="invalid-feedback">
                                      PORCENTAGE field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                     
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-pill btn-yellow"
                          // disabled={subcategorys === "" ? true : false}
                        >
                          SALVA
                        </button>
                        <button
                          className="btn btn-pill btn-blue ms-2 ms-md-3"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowcommercial(false);
                            setCommercialModel({})
                            reset({
                              description: "",
                              porcentage: ""
                            })
                          }}
                        >
                          ANNULLA
                        </button>
                      </Modal.Footer>
                    </form>
                  </Modal>
                </div>
                <Areamanager/>
                <div
                  className="tab-pane fade"
                  id="general-tab-pane"
                  role="tabpanel"
                  aria-labelledby="general-tab"
                  tabIndex={0}
                >


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAgents;
