import React, { useEffect, useState } from "react";
import { API } from "../../constant/api";
import axios from "axios";
import useRequest from "../../hooks/useRequest";
import Pagination from "../../component/Pagination/Pagination";
import { Link } from "react-router-dom";

const searchQueryHandler = (page, per_page, name = "") => {
  return `provider-contacts?page=${page}&per_page=${per_page}&name=${name}`;
};

const Contatti = (id_provider) => {
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [contacts, setContacts] = useState([]);
  const [showHeading, setShowHeading] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");

  const { request, response } = useRequest();

  useEffect(() => {
    request("GET", `provider-contacts/${id_provider.id_provider}`);
    setShowHeading(localStorage.getItem('active'))
  }, []);

  useEffect(() => {
    if (response) {
      setContacts(response.data?.contact_provider);
      // setTotalDocuments(response.data?.contact_provider?.total_records);
    }
  }, [response]);


  return (
    <div
    className={
      showHeading == 1
        ? "tab-pane fade show active"
        : "tab-pane fade"
    }
      id="contact-tab-pane"
      role="tabpanel"
      aria-labelledby="contact-tab"
      tabIndex={0}
    >
        {/* className="tab-pane fade"
                    id="point-tab-pane"
                    role="tabpanel"
                    aria-labelledby="point-tab"
                    tabIndex={0} */}
      <Link
        to="/fornitori/contatti/addcontatti"
        state={id_provider}
        className="btn btn-sm btn-primary ms-3"
      >
        Add
      </Link>

      <div className="themeTable customerTable">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>role</th>
              <th>name</th>
              <th>surname</th>
              <th>email</th>
              <th>note</th>
              <th>phone number</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {contacts.length > 0 &&
              contacts.map((contact) => (
                <tr key={contact?.id_contact_customer}>
                  <td data-th="ID">{contact?.id_prov_contact}</td>
                  <td data-th="role">{contact?.role}</td>
                  <td data-th="name">{contact?.name}</td>
                  <td data-th="surname">{contact?.surname}</td>
                  <td data-th="email">{contact?.email}</td>
                  <td data-th="note">{contact?.note}</td>
                  <td data-th="phone_number">{contact?.phone_number}</td>
                  <td data-th="ACTION">
                    <Link
                      to={`/fornitori/contatti/editcontatti`}
                      className="btn btn-sm btn-primary"
                      state={contact}
                   
                    >

                      <i className="fas fa-pencil-alt" />
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default Contatti;
