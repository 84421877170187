import React from 'react'
import SIdebar from "../../component/sidebar/SIdebar";
import Header from "../../component/header/Header";
function Laboratorio() {
  return (
    <>
    <Header />
    <div className="overlay" style={{ display: "none" }} />
    <div className="dashboard-wrap">
      <SIdebar />
     
    </div>
  </>
  )
}

export default Laboratorio