import React, { useEffect, useState } from "react";
import SIdebar from "../../../component/sidebar/SIdebar";
import Header from "../../../component/header/Header";
import DatiCliented from "./DatiCliented";
import useRequest from "../../../hooks/useRequest";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

function AddArticoli() {
  // const [active, setActive] = useState([]);
  // const [passive, setPassive] = useState([]);
  const [charts, setCharts] = useState([]);
  const [ple,setPle] = useState('')

  const [activeSelectId, setActiveSelectId] = useState();
  const [passiveSelectId, setPassiveSelectId] = useState();

  const { request, response } = useRequest();
  const { request: passiveRequest, response: passiveResponse } = useRequest();
  const { request: chartsRequest, response: chartResponse } = useRequest();
  const {
    request: addchartofaccountsRequest,
    response: addchartofaccountsResponse,
  } = useRequest();

  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    clearErrors,
  } = useForm();

  // useEffect(() => {
  //   if (response) {
  //     setActive(response.data);
  //   }
  // }, [response]);

  useEffect(() => {
    chartsRequest("GET", "chart-of-accounts");
  }, []);

  useEffect(() => {
    if (chartResponse) {
      if (chartResponse.status === "SUCCESS") {
        setCharts(chartResponse?.data);
        setPle(chartResponse?.data?.length)
      }
    }
  }, [chartResponse]);

  useEffect(() => {
    if (addchartofaccountsResponse) {
      if (addchartofaccountsResponse.status === "SUCCESS") {
        setShow(false);
        activeHandler(ple+1);
        passiveHandler(ple+1)
        reset({
          descrizione: "",
          tipo: "",
          codice: "",
        });
        // window.location.reload()
        chartsRequest("GET", "chart-of-accounts");
      }
    }
  }, [addchartofaccountsResponse]);

  // useEffect(() => {
  //   if (passiveResponse) {
  //     setPassive(passiveResponse?.data);
  //   }
  // }, [passiveResponse]);

  // useEffect(() => {
  //   request("GET", "active-chart-of-accounts");
  //   passiveRequest("GET", "passive-chart-of-accounts");
  // }, []);

  const activeHandler = (id) => {
    setActiveSelectId(id);
  };

  const passiveHandler = (id) => {
    setPassiveSelectId(id);
  };
  const onModelSubmit = (data) => {
    addchartofaccountsRequest("POST", "add-chart-of-accounts", data);
  };
  return (
    <>
      <Header />
      {/* Overlay */}
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">ANAGRAFICA</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">CATALOGO</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/articoli">ARTICOLI</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    SLM00001
                  </li>
                  CONTATTI
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Content */}
          <div className="pageContent">
            <div className="themeTabs my-4">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="data-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#data-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="data-tab-pane"
                    aria-selected="false"
                  >
                    DATI CLIENTE
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="floor-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#floor-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="floor-tab-pane"
                    aria-selected="true"
                  >
                    Chart Of Accounts
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="data-tab-pane"
                  role="tabpanel"
                  aria-labelledby="data-tab"
                  tabIndex={0}
                >
                  <div className="tabH-full">
                    <DatiCliented
                      activeSelectId={activeSelectId}
                      passiveSelectId={passiveSelectId}
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade "
                  id="floor-tab-pane"
                  role="tabpanel"
                  aria-labelledby="floor-tab"
                  tabIndex={0}
                >
                  <div className="tabH-full">
                    <div className="row g-4">
                      <div className="col-lg-6">
                        <h5 className="mb-3 mb-md-4">ATTIVO</h5>
                        <div className="themeTable1 table-responsive themeTable customerTable">
                          <table className="table mb-0 table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th className="w-20">TIPO</th>
                                <th className="w-20">CODICE</th>
                                <th>DESCRIZIONE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {charts.length > 0 &&
                                charts.map((chart, index) => (
                                  <tr
                                    className={
                                      chart.idpianoconti === activeSelectId
                                        ? "table table-striped table-bordered  active"
                                        : ""
                                    }
                                    style={{ cursor: "pointer" }}
                                    key={chart.idpianoconti}
                                    onClick={() =>
                                      activeHandler(chart.idpianoconti)
                                    }
                                  >
                                    <td data-th="TIPO">{chart.tipo}</td>
                                    <td data-th="CODICE">{chart.codice}</td>
                                    <td data-th="Descrizione">
                                      {chart.descrizione}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row g-4">
                          <h5 className="mb-3 mb-md-4 col-lg-10">PASSIVO</h5>
                          <div className="col-lg-2 ">
                            <button
                              style={{ alignItems: "end" }}
                              className="btn -pill btn-yellow"
                              type="button"
                              onClick={() => setShow(true)}
                            >
                              Nuovo
                            </button>
                          </div>
                        </div>
                        <div className="themeTable1 table-responsive themeTable customerTable">
                          <table className="table mb-0 table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th className="w-20">TIPO</th>
                                <th className="w-20">CODICE</th>
                                <th>DESCRIZIONE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {charts.length > 0 &&
                                charts.map((chart, index) => (
                                  <tr
                                    className={
                                      chart.idpianoconti === passiveSelectId
                                        ? "table table-striped table-bordered  active"
                                        : ""
                                    }
                                    style={{ cursor: "pointer" }}
                                    // className="primary"
                                    key={chart.idpianoconti}
                                    onClick={() =>
                                      passiveHandler(chart.idpianoconti)
                                    }
                                  >
                                    <td data-th="TIPO">{chart.tipo}</td>
                                    <td data-th="CODICE">{chart.codice}</td>
                                    <td data-th="Descrizione">
                                      {chart.descrizione}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-md-4 mt-3 text-center text-md-start">
                    <button className="btn btn-pill btn-yellow">SALVA</button>
                    <button className="btn btn-pill btn-blue ms-2 ms-md-3">
                      ANNULLA
                    </button>
                  </div> */}
                  <Modal
                    show={show}
                    onHide={() => {
                      setShow(false);
                      reset({
                        descrizione: "",
                        tipo: "",
                        codice: "",
                      });
                      clearErrors("descrizione");
                      clearErrors("tipo");

                      clearErrors("codice");
                    }}
                  >
                    <form onSubmit={handleSubmit(onModelSubmit)}>
                      <Modal.Header closeButton>
                        <Modal.Title>generale</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row g-lg-0">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row align-items-center">
                                <div className="col-lg-2 ">
                                  <label>descrizione</label>
                                </div>
                                <div className="col-lg-5">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.descrizione && "is-invalid"
                                    }`}
                                    name="descrizione"
                                    id="descrizione"
                                    {...register("descrizione", {
                                      required: true,
                                    })}
                                  />
                                  {errors.codice?.type === "required" && (
                                    <div className="invalid-feedback">
                                      descrizione field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row align-items-center">
                                <div className="col-lg-2 ">
                                  <label>codice</label>
                                </div>
                                <div className="col-lg-5">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.codice && "is-invalid"
                                    }`}
                                    name="codice"
                                    id="codice"
                                    {...register("codice", {
                                      required: true,
                                    })}
                                  />
                                  {errors.codice?.type === "required" && (
                                    <div className="invalid-feedback">
                                      codice field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row align-items-center">
                                <div className="col-lg-2 ">
                                  <label>tipo</label>
                                </div>
                                <div className="col-lg-5">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.tipo && "is-invalid"
                                    }`}
                                    name="tipo"
                                    id="tipo"
                                    {...register("tipo", {
                                      required: true,
                                    })}
                                  />
                                  {errors.tipo?.type === "required" && (
                                    <div className="invalid-feedback">
                                      tipo field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button className="btn btn-pill btn-yellow">
                          SALVA
                        </button>
                        {/* <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={() => {
                    reset({
                      descrizione:'',
                      tipo:'',
                      codice:''
                    })
                    clearErrors("descrizione");
                    clearErrors("tipo");

                    clearErrors("codice");
                    setShow(false)
                  } 
                  }
                
                >
                  ANNULLA
                </button> */}
                      </Modal.Footer>
                    </form>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddArticoli;
