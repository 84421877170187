import React, { useEffect, useState } from "react";
import SIdebar from "../../component/sidebar/SIdebar";
import Header from "../../component/header/Header";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Contatti from "./contatti";
import useRequest from "../../hooks/useRequest";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

function AddClienti() {
  let navigate = useNavigate();

  const [region, setRegion] = useState([]);
  const [docType, setDocType] = useState([]);
  const [country, setCountry] = useState([]);
  const [provincia, setProvincia] = useState([]);
  const [getlist, setGetlist] = useState([]);
  const [show, setShow] = useState(false);
  const [description, setdescription] = useState();
  const [active, setActive] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { response, request } = useRequest();

  const { response: regionResponse, request: regionRequest } = useRequest();
  const { response: docTypeResponse, request: docTypeRequest } = useRequest();
  const { request: countryRequest, response: countryResponse } = useRequest();
  const { request: provinceRequest, response: provinceResponse } = useRequest();
  const { request: listRequest, response: listResponse } = useRequest();
  const { request: addListRequest, response: addListResponse } = useRequest();

  useEffect(() => {
    regionRequest("GET", "regions");
    docTypeRequest("GET", "doc-type");
    countryRequest("GET", "country");
    provinceRequest("GET", "provinces");
    listRequest("GET", "lists");
  }, []);

  useEffect(() => {
    if (regionResponse) {
      setRegion(
        regionResponse.data?.region.map((r) => {
          return {
            value: r.idregione,
            label: r.nomeregione,
          };
        })
      );
    }
  }, [regionResponse]);

  useEffect(() => {
    if (docTypeResponse) {
      setDocType(
        docTypeResponse.data?.docs?.map((doc) => {
          return {
            value: doc.id_doc_type,
            label: doc.descrizione,
          };
        })
      );
    }
  }, [docTypeResponse]);

  useEffect(() => {
    if (countryResponse) {
      setCountry(
        countryResponse.data?.country?.map((c) => {
          return {
            value: c.id_country,
            label: c.nome,
          };
        })
      );
    }
  }, [countryResponse]);

  useEffect(() => {
    if (provinceResponse) {
      setProvincia(
        provinceResponse.data?.provinces?.map((province) => {
          return {
            value: province.id_province,
            label: province.nomeprovincia,
          };
        })
      );
    }
  }, [provinceResponse]);

  useEffect(() => {
    if (listResponse) {
      setGetlist(
        listResponse.data?.lists?.map((list) => {
          return {
            value: list.idlistino,
            label: list.description,
          };
        })
      );
    }
  }, [listResponse]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success("Cliente aggiunto correttamente");
        navigate("/clienti");
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  useEffect(() => {
    if (addListResponse) {
      if (addListResponse.status === "SUCCESS") {
        toast.success("Elenco clienti aggiunto correttamente");
        setShow(false);
        listRequest("GET", "lists");
      } else {
        toast.error(response.message);
      }
    }
  }, [addListResponse]);

  const onSubmit = (data) => {
    request("POST", "add-customer", data);
  };

  const onModelSubmit = (e) => {
    e.preventDefault();
    if (!description) {
      toast.error("The description field is required.");
    } else {
      addListRequest("POST", "add-list", { description, active });
    }
  };

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">ANAGRAFICA</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/clienti">CLIENTI</a>
                  </li>
                  {/* <li className="breadcrumb-item">
                    <a href="#">CLIENTE</a>
                  </li> */}
                  <li className="breadcrumb-item active" aria-current="page">
                    GIANLUCA BOVANDOLINO
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="pagesearch">
              <div className="pagesearch-block">
                <input type="text" placeholder="" name="" id="" />
                <button>
                  <i className="fas fa-search" />
                </button>
              </div>
              <div className="form-check ms-2 ms-md-3 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  solo provvisori 
                </label>
              </div>
            </div> */}
          </div>
          {/* Page Content */}
          <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="pageContent">
              <div className="themeTabs my-4">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="data-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#data-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="data-tab-pane"
                      aria-selected="true"
                    >
                      DATI CLIENTE
                    </button>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab-pane"
                      aria-selected="false"
                    >
                      CONTATTI
                    </button>
                  </li> */}
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="point-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#point-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="point-tab-pane"
                      aria-selected="false"
                    >
                      {" "}
                      PUNTI VENDITA
                    </button>
                  </li> */}
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="bank-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#bank-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="bank-tab-pane"
                      aria-selected="false"
                    >
                      {" "}
                      BANCA
                    </button>
                  </li> */}
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="data-tab-pane"
                    role="tabpanel"
                    aria-labelledby="data-tab"
                    tabIndex={0}
                  >
                    <div className="tabH-full">
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>PRIVATO</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="is_private"
                                    {...register("is_private")}
                                  />

                                  <label
                                    className="form-check-label"
                                    htmlFor="PRIVATO"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>NOME</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.name && "is-invalid"
                                    }`}
                                    name="name"
                                    id="name"
                                    {...register("name", {
                                      required: true,
                                    })}
                                  />
                                  {errors.name?.type === "required" && (
                                    <div className="invalid-feedback">
                                      The name field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>COGNOME</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control `
                                  // ${
                                  //   errors.surname && "is-invalid"
                                  // }
                                  }
                                  name="surname"
                                  id="surname"
                                  {...register("surname", {
                                    // required: true,
                                  })}
                                />
                                {/* {errors.surname?.type === "required" && (
                                  <div className="invalid-feedback">
                                    cognome field is required.
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>CODICE FISCALE</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.fiscal_code && "is-invalid"
                                  }`}
                                  name=""
                                  id="fiscal_code"
                                  {...register("fiscal_code", {
                                    required: true,
                                  })}
                                />
                                {errors.fiscal_code?.type === "required" && (
                                  <div className="invalid-feedback">
                                    fiscal_code field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>REGIONE</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="region"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      // menuPosition="fixed"
                                      options={region}
                                      classNamePrefix="select"
                                      placeholder="Select Regione"
                                      className={`basic-multi-select ${
                                        errors.region && "is-invalid"
                                      }`}
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.region?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The regione field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>TIPO DOCUMENTO</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  name="docType"
                                  rules={{ required: true, minLength: 1 }}
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      options={docType}
                                      placeholder="Select Tipo Documento"
                                      className={`basic-multi-select ${
                                        errors.docType && "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.docType?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The tipo documento field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>NAZIONE</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_country"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      // menuPosition="fixed"
                                      options={country}
                                      classNamePrefix="select"
                                      placeholder="Select Nazione"
                                      className={`basic-multi-select ${
                                        errors.id_country && "is-invalid"
                                      }`}
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.id_country?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The NAZIONE field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>PARTITA IVA</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.vat && "is-invalid"
                                  }`}
                                  name="vat"
                                  id="vat"
                                  {...register("vat", {
                                    required: true,
                                  })}
                                />
                                {errors.vat?.type === "required" && (
                                  <div className="invalid-feedback">
                                    partita iva field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>PROVINCIA</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  rules={{ required: true, minLength: 1 }}
                                  name="id_province"
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      // menuPosition="fixed"
                                      options={provincia}
                                      classNamePrefix="select province"
                                      placeholder="select "
                                      className={`basic-multi-select ${
                                        errors.id_province && "is-invalid"
                                      }`}
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.id_province?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The province field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>TRUST</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="PRIVATO"
                                    {...register("trust")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="PRIVATO"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>STRADA</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.id_address && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("street", {
                                    required: true,
                                  })}
                                />
                                {errors.street?.type === "required" && (
                                  <div className="invalid-feedback">
                                    indirizzo field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>CAP</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.zip_code && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("zip_code", {
                                    required: true,
                                  })}
                                />
                                {errors.zip_code?.type === "required" && (
                                  <div className="invalid-feedback">
                                    indirizzo field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0"></div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>POSTO</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.place && "is-invalid"
                                  }`}
                                  name=""
                                  id="street"
                                  {...register("place", {
                                    required: true,
                                  })}
                                />
                                {errors.place?.type === "required" && (
                                  <div className="invalid-feedback">
                                    indirizzo field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label> LISTINO</label>
                              </div>
                              <div className="col-lg-6">
                                <Controller
                                  control={control}
                                  name="idlistino"
                                  rules={{ required: true, minLength: 1 }}
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      // menuPosition="fixed"
                                      options={getlist}
                                      placeholder="Select Dept"
                                      className={`basic-multi-select ${
                                        errors.idlistino && "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      onChange={(e) => onChange(e.value)}
                                    />
                                  )}
                                />
                                {errors.idlistino?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The list field is required.
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-1">
                                <button
                                  className="btn -pill btn-yellow"
                                  type="button"
                                  onClick={() => setShow(true)}
                                >
                                  Nuovo
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>PROVVISORIO</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="PRIVATO"
                                    {...register("provisional")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="PRIVATO"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>ATTIVO</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue=""
                                    id="active"
                                    {...register("active")}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="PRIVATO"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>INDIRIZZO PEC</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.pec && "is-invalid"
                                  }`}
                                  name=""
                                  id="pec"
                                  {...register("pec", {
                                    required: true,
                                  })}
                                />
                                {errors.pec?.type === "required" && (
                                  <div className="invalid-feedback">
                                    indirizzo pec field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>CODICE SDI</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.sdi_code && "is-invalid"
                                  }`}
                                  name=""
                                  id="sdi_code"
                                  {...register("sdi_code", {
                                    required: true,
                                  })}
                                />
                                {errors.sdi_code?.type === "required" && (
                                  <div className="invalid-feedback">
                                    codic sdi field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>DESCRIPTION</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.description && "is-invalid"
                                  }`}
                                  name=""
                                  id="description"
                                  {...register("description", {
                                    required: true,
                                  })}
                                />
                                {errors.description?.type === "required" && (
                                  <div className="invalid-feedback">
                                    description field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-md-4 mt-3 text-center text-md-start">
                      <button className="btn btn-pill btn-yellow">SALVA</button>
                      <button
                        className="btn btn-pill btn-blue ms-2 ms-md-3"
                        onClick={() => {
                          navigate("/clienti");
                        }}
                      >
                        ANNULLA
                      </button>
                    </div>
                  </div>
                  <Contatti />
                  <div
                    className="tab-pane fade"
                    id="point-tab-pane"
                    role="tabpanel"
                    aria-labelledby="point-tab"
                    tabIndex={0}
                  >
                    <div className="tabH-full">3</div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="bank-tab-pane"
                    role="tabpanel"
                    aria-labelledby="bank-tab"
                    tabIndex={0}
                  >
                    <div className="tabH-full">4</div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={onModelSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>generale</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-2 ">
                          <label>descrizione</label>
                        </div>
                        <div className="col-lg-5">
                          <input
                            type="text"
                            className={"form-control"}
                            name=""
                            id="description"
                            onChange={(e) => {
                              setdescription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-lg-0">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="row align-items-center">
                        <div className="col-lg-2 ">
                          <label>trust</label>
                        </div>
                        <div className="col-lg-5">
                          <input
                            className="form-check-input checklg"
                            type="checkbox"
                            defaultValue=""
                            id="active"
                            onChange={(e) => setActive(e.target.checked)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {/* <button
                  className="btn btn-pill btn-blue ms-2 ms-md-3"
                  onClick={() => setShow(false)}
                >
                  ANNULLA
                </button> */}
                <button className="btn btn-pill btn-yellow">SALVA</button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default AddClienti;
