import React from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import { useTranslation } from "react-i18next";

const Pagination = ({
  page,
  totalRecords,
  getNewData,
  perPage,
  defaultPerPage,
  perPageChangeHandler,
  currentDocLength,
}) => {
  const [t, i18n] = useTranslation("translation");
  return (
    <div className="row">
      <div className="col-sm-12 col-md-5">
        <div className="d-flex align-items-center">
          <div className="dataTables_length me-3">
            <label className="mb-0 d-flex align-items-center text-white" >
              <span className="me-2">{t("Show")}</span>
              <select
                onChange={perPageChangeHandler}
                className="dataTablesSelect form-select"
              >
                <option value={defaultPerPage}>{t("Default")}</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </label>
          </div>
          <div className="dataTables_info" style={{color:"black"}}>
            {t("Showing")} {1 + perPage * (page - 1)} {t("to")}{" "}
            {currentDocLength < perPage ? totalRecords : perPage * page}{" "}
            {t("of")} {totalRecords} {t("entries")}
          </div>
        </div>
      </div>

      <div className="col-sm-12 col-md-7">
        <div className="d-flex justify-content-center justify-content-md-end align-items-center mt-3 mt-md-0">
          <div className="dataTables_paginate paging_full_numbers">
            <ReactPaginate
              forcePage={page - 1}
              pageCount={Math.ceil(totalRecords / perPage)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              onPageChange={getNewData}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
