import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useRequest from "../../hooks/useRequest";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { authSuccess } from "../../store/auth/action";



function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const { response, request } = useRequest();
    let navigate = useNavigate();

  const onSubmit = (data) => {
    const { email, password } = data;
    request("POST", "login", { email, password });
  };

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success("You are now logged in!");
        dispatch(
          authSuccess({
            token: response.data.token,
            userId: response.data.user.id_user,
            name: response.data.user.name,
            email: response.data.user.email,
          })
        );
        navigate("/");
      } else {
        toast.error("invalid credentials");
      }
    }
  }, [response]);

  return (
    <>
      <div className="loginsign-wrap bg-with-h100">
        <div className="loginform-wrap">
          <div className="loginlogo">
            <img src="img/logo.png" alt="" />
          </div>
          <div className="login-form default-form">
            <div className="form-inner">
              <div className="loginCircle">
                <img src="img/loginIcon.svg" alt="" />
              </div>
              <form method="post" onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 py-3">
                  <div className="form-group">
                    <input
                      className={`form-control ${errors.email && "is-invalid"}`}
                      type="text"
                      name="email"
                      placeholder="Email"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                    />
                    {errors.email?.type === "required" && (
                      <div className="invalid-feedback">
                        The email field is required.
                      </div>
                    )}
                    {errors.email?.type === "pattern" && (
                      <div className="invalid-feedback">
                        The email must be a valid email address.
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      className={`form-control ${
                        errors.password && "is-invalid"
                      }`}
                      id="password-field"
                      type="password"
                      name="password"
                      placeholder="Password"
                      {...register("password", {
                        required: true,

                      })}
                    />
                    {errors.password?.type === "required" && (
                      <div className="invalid-feedback">
                        The password field is required.
                      </div>
                    )}
                  </div>
                </div>
                <button className="loginbtn" type="submit">
                  Log In
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
