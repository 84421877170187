import React, { forwardRef, useRef, useEffect, useState } from "react";
import exportFromJSON from "export-from-json";
import SIdebar from "../../../component/sidebar/SIdebar";
import Header from "../../../component/header/Header";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import Pagination from "../../../component/Pagination/Pagination";



const searchQueryHandler = (page, per_page, name = "") => {
  return `catalog-print?page=${page}&per_page=${per_page}&name=${name}`;
};
function StampaCatalogo() {

  let navigate = useNavigate();
  const [state, setState]=useState([])
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  // const { state } = useLocation();
  const [fileName, setFileName] = useState("");
  const componentRef = useRef();
  const { response: responsedStampa, request: requestdStampa } = useRequest();
  const { response, request} = useRequest();



useEffect(()=>{
  requestdStampa("GET", searchQueryHandler(1, perPage, searchQuery));
  // request("GET","catalog-print")
},[])

useEffect(() => {
  if (responsedStampa) {
    if (responsedStampa.status === "SUCCESS") {
      setState(responsedStampa.data.results
        )
        setTotalDocuments(responsedStampa.data?.total_records);
     
    } else {
      toast.error(responsedStampa.message);
    }
  }
}, [responsedStampa]);


useEffect(() => {
  if (response) {
    if (response.status === "SUCCESS") {
      debugger
      const data = state;
      const exportType = "xls";
      const url = exportFromJSON({ data, fileName, exportType });
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.xls`);
      document.body.appendChild(link);
      document.body.removeChild(link);
      toast.success(responsedStampa.message);
    } else {
      toast.error(responsedStampa.message);
    }
  }
}, [response]);


const fetchMoreData = ({ selected }) => {
  setState([]);
  setPage(selected + 1);
  requestdStampa("GET", searchQueryHandler(selected + 1, perPage, searchQuery));
};
const perPageChangeHandler = (event) => {
  requestdStampa("GET", searchQueryHandler(1, event.target.value, searchQuery));
  setPage(1);

  setPerPage(event.target.value);
};
const handleChangeWithSearch = debounce((searchQuery) => {
  requestdStampa("GET", searchQueryHandler(1, perPage, searchQuery));
}, 500);


  const buttonClickedXLS = () => {
    // request("GET", searchQueryHandler(1, perPage, searchQuery,true))
    request("GET","catalog-print?download=true")
    // const data = state;
    // const exportType = "xls";
    // const url = exportFromJSON({ data, fileName, exportType });
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", `${fileName}.xls`);
    // document.body.appendChild(link);
    // document.body.removeChild(link);
  };
  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">ANAGRAFICA</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" onClick={() => navigate(-1)}>
                      CONTATTI
                    </a>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="pagesearch">
              <div className="pagesearch-block">
                {/* <input
                  type="text"
                  placeholder="Articolo"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                /> */}
                <input
                  type="text"
                  placeholder="Descrizione del gruppo di prodotti"
                  value={searchQuery}
                  // onChange={(e) =>
                    
                  //  } 
                  onChange={(e) =>
                  {  e.preventDefault()
                    setSearchQuery(e.target.value) 
                    handleChangeWithSearch(e.target.value)}}
                />
                {/* <button onClick={onSearchHandler}>
                  <i className="fas fa-search" />
                </button> */}
              </div>
            </div>
          </div>
          <div >
            <div className="themeTabs my-4">
              <div >
                <div className="themeTable customerTable">
                  <div className="row g-lg">
                    <div className="tabH-full">
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <ReactToPrint
                                  trigger={() => (
                                    <button className="btn -pill btn-yellow">
                                      stampa questo!
                                    </button>
                                  )}
                                  content={() => componentRef.current}
                                />
                              </div>
                              <div className="col-lg-7"></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-4 text-lg-end">
                                <label> Titolo</label>
                              </div>
                              <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control`}
                                name="increase"
                                id="increase"
                                onChange={(e) => {
                                  setFileName(e.target.value);
                                }}
                              />
                              </div>
                              <div className="col-lg-4">
                        <button
                          disabled={!fileName && state != [] }
                          className=" btn -pill btn-blue "
                          type="button"
                          onClick={buttonClickedXLS}
                        >
                          Esporta XLS
                        </button>
                      </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <table
                        className="table table-striped table-bordered"
                        ref={componentRef}
                      >
                        <thead>
                          <tr>
                            <th>Codice catalogo</th>
                            <th>Descrizione del gruppo di prodotti</th>
                            <th>Descrizione della categoria</th>
                            <th>Tempo di consegna</th>
                            <th>Aumento</th>
                            {/* <th>Prezzo</th> */}
                            <th>Sottocategoria</th>
                            <th>Unità di misura</th>
                          </tr>
                        </thead>
                        <tbody>
                          {console.log(state)}
                          {state.map((data) => {
                            return (
                              <tr>
                                <td data-th="Codice catalogo">
                                  {data.catalog_code}
                                </td>
                                <td data-th="Descrizione del gruppo di prodotti">
                                  {data.description}
                                </td>
                                <td data-th="Descrizione della categoria">
                                  {data.category_catalog
}
                                </td>
                                <td data-th="Tempo di consegna">
                                  {data.delivery_time}
                                </td>
                                <td data-th="Aumento">{data.margin}</td>
                                {/* <td data-th="Prezzo">{data.fixed_price}</td> */}
                                <td data-th="Sottocategoria">
                                  {data.sub_category_catalog
}
                                </td>
                                <td data-th="Unità di misura">
                                  {data.unit_measure}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {perPage !== 0 && (
              <Pagination
                page={page}
                totalRecords={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                defaultPerPage={10}
                perPageChangeHandler={perPageChangeHandler}
                currentDocLength={state.length}
              />
            )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StampaCatalogo;
