import React, { useState, useEffect } from "react";
import Moment from "moment";
import { Link, useLocation } from "react-router-dom";

// const CUSTOMERROUTES = [
//   "/clienti",
//   "/puntovendita",
//   "/addClienti",
//   "/editClienti",
//   "/puntovendita/add",
//   "/puntovendita/edit/:id"
// ];
// const CATALOGOROUTES = [
//   "/articoli",
//   "/articoli/add",
//   "/articoli/edit",
//   "/catalogo",
//   "/catalogo/add",
//   "/catalogo/edit",
//   "/listini",
//   "/listini/add",
//   "/listini/edit",
//   "/stampaCatalogo",
//   "/stampacatalogofile"
// ];

// const PARTNER =[
//   "/fornitori",
//   "/addfornitori",
//   "/fornitori/edit",
//   "/fornitori/contatti/editcontatti",
//   "/agents",
//   "/agents/edit",
//   "/agents/add",
//   "/agents/contatti/addcontatti",
//   "/agents/contatti/editcontatti"
// ]

const Magazzino =[
    "/riordino",
    "/riordino/add"
  ]


function MagazzinosideBar() {
  const { pathname } = useLocation();
  // console.log(pathname);
  let username = localStorage.getItem("username");
  const loginTime = Moment(new Date()).format("DD-MM-YYYY, hh:mm:ss");
  let time = null;
  const [dateTime, setDateTime] = useState(loginTime);

  useEffect(() => {
    SetTimer();
    return () => {
      clearInterval(time);
      time = null;
    };
  }, []);
  const SetTimer = () => {
    time = setInterval(() => {
      setDateTime(Moment(new Date()).format("DD-MM-YYYY, hh:mm:ss"));
    }, 1000);
  };
  const date = Moment(new Date()).format("DD-MM-YYYY");

  // toggle

  const [toggleMenubar, setToggleNavbar] = useState(false);
  useEffect(() => {
    if (toggleMenubar) {
      document.body.classList.add("scroll-off");
    } else {
      document.body.classList.remove("scroll-off");
    }
  }, [toggleMenubar]);

  return (
    <>
      <button className="menuMobile"  onClick={() => setToggleNavbar(!toggleMenubar)}>
        Menu
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={32}
          height={32}
          fill="currentColor"
          className="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </button>
      <div  className={toggleMenubar ? `sidebar menu-active` : `sidebar`}>
        <button className="menuMobileClose" onClick={() => setToggleNavbar(!toggleMenubar)}>
          Close
          <i className="far fa-times" />
        </button>
        <div className="menulist" id="accordionMenu">
          <div className="menu-item has-submenu">
            <a
              href="#!"
              className={Magazzino.includes(pathname) ? "collapsed" : ""}
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
            ACQUISTI
            </a>
            <div
              id="collapseOne"
              className={`collapse ${
                Magazzino.includes(pathname) ? "show" : ""
              } `}
             
              data-bs-parent="#accordionMenu"
            >
              <ul className="list-unstyled mb-0">
                <li  
               
                  className={
                    pathname === "/riordino"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/riordino">RIORDINO</Link>
                </li>
                <li
                //   className={
                //     pathname === "/puntovendita" ||
                //     pathname === "/puntovendita/add" ||
                //     pathname === "/puntovendita/edit/:id"
                //       ? "active"
                //       : ""
                //   }
                >
                  {/* <Link to="/puntovendita">PUNTI VENDITA</Link> */}
                </li>
              </ul>
            </div>
          </div>

          {/* <div className="menu-item has-submenu">
            <a
              href="#!"
              className={CATALOGOROUTES.includes(pathname) ? "collapsed" : ""}
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            > 
              CATALOGO
            </a>
            <div
              id="collapseTwo"
              className={`collapse ${
                CATALOGOROUTES.includes(pathname) ? "show" : ""
              }`}
              data-bs-parent="#accordionMenu"
            >
              <ul className="list-unstyled active mb-0">
                <li
                  className={
                    pathname === "/articoli" || pathname === "/articoli/add" ||pathname ==="/articoli/edit"
                      ? "active"
                      : ""
                  }
                >
                  <a href="/articoli">ARTICOLI</a>
                </li>
                <li
                  className={
                    pathname === "/catalogo" || pathname === "/catalogo/add"||  pathname ==="/catalogo/edit"
                      ? "active"
                      : ""
                  }
                >
                  <a href="/catalogo">CATALOGO</a>
                </li>
                <li
                  className={
                    pathname === "/listini" || pathname === "/listini/add"|| pathname ===  "/listini/edit"
                      ? "active"
                      : ""
                  }
                >
                  <a href="/listini">LISTINI</a>
                </li>
             
                <li className={
                    pathname === "/stampaCatalogo"||pathname ==="/stampacatalogofile"
                      ? "active"
                      : ""
                  }>
                  <a href="/stampaCatalogo">STAMPA CATALOGO</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="menu-item has-submenu">
            <a
              href="#!"
              className={PARTNER.includes(pathname) ? "collapsed" : ""}
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              PARTNER
            </a>
            <div
              id="collapseThree"
              className={`collapse ${
                PARTNER.includes(pathname) ? "show" : ""
              }`}
            
              data-bs-parent="#accordionMenu"
            >
              <ul className="list-unstyled mb-0">
                <li className={
                    pathname === "/fornitori" ||pathname === "/addfornitori"||pathname === "/fornitori/edit"|| 
                    pathname === "/fornitori/contatti/editcontatti" ||pathname === "/fornitori/contatti/editcontatti"
                      ? "active"
                      : ""
                  }>
                  <a href="/fornitori">FORNITORI</a>
                </li>
                <li  className={
                    pathname === "/agents" ||pathname === "/agents/edit"|| pathname === "/agents/add"|| pathname === "/agents/contatti/addcontatti"|| pathname==="/agents/contatti/editcontatti"
                      ? "active"
                      : ""
                  }>
                  <a href="/agents"  
                  >AGENTI</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="menu-item has-submenu">
            <a
              href="#!"
              className="collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              TABELLE DI BASE
            </a>
            <div
              id="collapseFour"
              className={`collapse ${
                TABLE.includes(pathname) ? "show" : ""
              }`}
              data-bs-parent="#accordionMenu"
            >
              <ul className="list-unstyled mb-0">
                <li className={
                    pathname === "/gruppiprodotto" 
                      ? "active"
                      : ""
                  }>
                  <a href="/gruppiprodotto">Gruppo prodotti</a>
                </li>
                <li  className={
                    pathname === "/categorie" 
                      ? "active"
                      : ""
                  }>
                  <a href="/categorie">Categorie merceologiche</a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="userinfo">
          <span className="border-bottom">
            <i className="fas fa-user-alt me-2" />
            {username}
          </span>   
          <span>{dateTime}</span>
        </div>
      </div>
    </>
  );
}

export default MagazzinosideBar;
