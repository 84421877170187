import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SIdebar from "../../component/sidebar/SIdebar";
import Header from "../../component/header/Header";
import Pagination from "../../component/Pagination/Pagination";
import axios from "axios";
import useRequest from "../../hooks/useRequest";
 import { debounce } from "lodash";

const searchQueryHandler = (
  page,
  per_page, 
  name = "",
  isProvisional = false
) => {
  return `customers?page=${page}&per_page=${per_page}&name=${name}&provisional=${isProvisional}`;
};


function Clienti() {
  let navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isProvisional, setIsProvisional] = useState(true);
  const { request, response } = useRequest();

  useEffect(() => {
    request("GET", searchQueryHandler(1, perPage, searchQuery, isProvisional));
  }, []);

  useEffect(() => {
    if (response) {
      setCustomers(response.data?.results);
      setTotalDocuments(response.data?.total_records);
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setCustomers([]);
    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(selected + 1, perPage, searchQuery, isProvisional)
    );
  };

  const onSearchHandler = () => {
    request("GET", searchQueryHandler(1, perPage, searchQuery, isProvisional));
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    request(
      "GET",
      searchQueryHandler(1, event.target.value, searchQuery, isProvisional)
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const provisionalHandler = (e) => {
    request(
      "GET",
      searchQueryHandler(1, perPage, searchQuery, e.target.checked)
    );
    setPage(1);
    setIsProvisional(e.target.checked);
  };


  let listToDisplay = customers; 
 
  
  // if (searchQuery !== "") {
  //   debugger
  //   listToDisplay = customers.filter((cut) => {
  //     return cut.includes(searchQuery);
  //   });
  // }

    const handleChangeWithLib = debounce((searchQuery) => {
      request("GET", searchQueryHandler(1, perPage, searchQuery, isProvisional));

  }, 500);

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">ANAGRAFICA</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">CLIENTI</a>
                  </li>
                  {/* <li className="breadcrumb-item active" aria-current="page">
                    CLIENTI
                  </li> */}
                </ol>
              </nav>
              {/* <div
                className="btn btn-sm btn-primary ms-3"
                onClick={() => navigate("/addClienti")}
              >
                <i className="far fa-plus" />
              </div> */}
              <Link to="/addClienti" className="btn btn-sm btn-primary ms-3">
                <i className="far fa-plus" />
              </Link>
            </div>
            <div className="pagesearch">
              <div className="pagesearch-block">
                <input
                  type="text"
                  placeholder="DENOMINAZIONE"
                  value={searchQuery}
                  // onChange={(e) =>
                    
                  //  } 
                  onChange={(e) =>

                  {  e.preventDefault()
                    setSearchQuery(e.target.value) 
                    handleChangeWithLib(e.target.value)}}
                />
                {/* <button onClick={onSearchHandler}>
                  <i className="fas fa-search" />
                </button> */}
              </div>
              <div className="form-check ms-2 ms-md-3 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isProvisional}
                  id="flexCheckChecked"
                  onChange={provisionalHandler}
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  solo provvisori
                </label>
              </div>
            </div>
          </div>
          {/* Page Content */}
          <div className="pageContent">
            <div className="themeTable customerTable">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>DENOMINAZIONE</th>
                    <th>PARTITA IVA</th>
                    <th>CODICE FISCALE</th>
                    <th>INDIRIZZO</th>
                    <th>PROVINCIA</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {listToDisplay.length > 0 &&
                    listToDisplay.map((customer, index) => (
              
                      <tr key={customer.id_customer}>
                        <td data-th="ID">{customer.id_customer}</td>
                        
                        <td data-th="DENOMINAZIONE" style={{ textAlign:"left" }}>
                     {   customer.description}
                        </td>
                        <td data-th="PARTITA IVA">{customer.vat}</td>
                        <td data-th="CODICE FISCALE">{customer.fiscal_code}</td>
                        <td data-th="INDIRIZZO">{customer?.address?.street}</td>
                        <td data-th="PROVINCIA">{customer?.address?.province?.nomeprovincia}</td>
                        <td data-th="ACTION">
                          <Link
                            to="/editClienti"
                            state={customer}
                            className="btn btn-sm btn-primary">
                            <i className="fas fa-pencil-alt" />
                          </Link>
                         
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {perPage !== 0 && (
              <Pagination
                page={page}
                totalRecords={totalDocuments}
                getNewData={fetchMoreData}
                perPage={perPage}
                defaultPerPage={10}
                perPageChangeHandler={perPageChangeHandler}
                currentDocLength={customers.length}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Clienti;
