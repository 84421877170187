import React, { useEffect, useState } from "react";
import SIdebar from "../../../component/sidebar/SIdebar";
import Header from "../../../component/header/Header";
import { useNavigate, Link } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

function StampaCatalogo() {
  let navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());

  const { response: responsedStampa, request: requestdStampa } = useRequest();

  useEffect(() => {
    if (responsedStampa) {
      if (responsedStampa.status === "SUCCESS") {
        navigate("/stampacatalogofile",{state: {
          excelData: responsedStampa.data.excelData,
          startDate:startDate
        }});
        toast.success(responsedStampa.message);
      } else {
        toast.error(responsedStampa.message);
      }
    }
  }, [responsedStampa]);

  const buttonClickedStampa = () => {
    requestdStampa(
      "GET",
      `catalog-print?date=${startDate}`
    );
  };

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">ANAGRAFICA</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <a
                      href="#"
                      // onClick={() => navigate(-1)}
                    >
                      STAMPA CATALOGO
                    </a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Content */}
          <form>
            <div className="pageContent">
              <div className="themeTabs my-4">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="data-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#data-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="data-tab-pane"
                      aria-selected="true"
                    >
                      Stampa catalogo
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="data-tab-pane"
                    role="tabpanel"
                    aria-labelledby="data-tab"
                    tabIndex={0}
                  >
                    <div className="tabH-full">
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Data validità</label>
                              </div>
                              <div className="col-lg-7">
                                <DatePicker
                                  placeholder="MM/DD/YYYY"
                                  className={`form-control`}
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        {" "}
                        <button
                          className="btn -pill btn-yellow"
                          type="button"
                          onClick={buttonClickedStampa}
                        >
                          Stampa
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default StampaCatalogo;
