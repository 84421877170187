import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";
import moment from "moment";
import { toast } from "react-toastify";
import useRequest from "../../../hooks/useRequest";

const TIME_SLOTS = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

const Orari = (props) => {
  const [timetables, setTimetables] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  const [showHeading, setShowHeading] = useState("");
  const { request, response } = useRequest();
  const { request: updateRequest, response: updateResponse } = useRequest();
  const { request: deleteRequest, response: deleteResponse } = useRequest();

  const {
    register,
    unregister,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
    getValues,
    clearErrors,
    setError,
  } = useForm();

  useEffect(() => {
    request("GET", `article-timetables/${props.Id}`);
    
  }, []);

  useEffect(() => {
    if (response) {
      const updatedData = response.data?.timeTableData?.map(
        (timetable, index) => {
          return {
            ...timetable,
            id: index,
          };
        }
      );
      setTimetables(updatedData);
      setLastIndex(updatedData.length);
      let updates = {};

      updatedData.forEach((timetable) => {
        const subUpdates = {
          [`${timetable.id}.order_day`]: timetable.order_day,
          [`${timetable.id}.limit_hours`]: timetable.limit_hours,
          [`${timetable.id}.delivery_days`]: timetable.delivery_days,
        };
        updates = { ...updates, ...subUpdates };
      });
      reset({ ...updates });
    }
  }, [response]);

  useEffect(() => {
    if (updateResponse) {
      if (updateResponse.status === "SUCCESS") {
        request("GET", `article-timetables/${props.Id}`);
        toast.success(updateResponse.message);
      } else {
        toast.error(updateResponse.message);
      }
    }
  }, [updateResponse]);

  useEffect(() => {
    if (deleteResponse) {
      if (deleteResponse.status === "SUCCESS") {
        toast.success(deleteResponse.message);
      } else {
        toast.error(deleteResponse.message);
      }
    }
  }, [deleteResponse]);

  const submitHandler = (data) => {
    const time_tables = [];

    let isErrorMain = false;

    for (let i = 0; i < timetables.length; i++) {
      const item = timetables[i];
      const id = item.id;
      let time = data[id]["limit_hours"];
      const selectedDay = data[id]["order_day"];
      time_tables.push({
        limit_hours: time,
        delivery_days: data[id]["delivery_days"],
        order_day: selectedDay,
        id_delivery_rules: item.id_delivery_rules,
      });

      let selectedDays = [];

      if (selectedDay !== 0) {
        for (let i = 0; i < timetables.length; i++) {
          const item = timetables[i];

          if (item.id === id) {
            continue;
          }

          const itemDay = data[item.id]["order_day"];

          if (itemDay === 0 || itemDay === selectedDay) {
            selectedDays.push(item);
          }
        }
      } else {
        selectedDays = [...timetables].filter((t) => t.id !== id);
      }

      let isError = false;

      for (let i = 0; i < selectedDays.length; i++) {
        const item = selectedDays[i];

        let itemTime = data[item.id]["limit_hours"];

        if (itemTime === time) {
          setError(id + ".limit_hours", {
            type: "manual",
          });
          isError = true;
          isErrorMain = true;
        } else {
          clearErrors(id + ".limit_hours");
        }

        if (isError) {
          break;
        }
      }
    }

    if (isErrorMain) {
      return;
    }

    updateRequest("POST", "add-update-article-timetable", {
      time_tables,
      id_article: props.Id,
    });
  };

  const addNewRow = () => {
    const oldData = [...timetables];

    oldData.push({
      id: lastIndex,
      delivery_days: 0,
      limit_hours: "00:00",
      id_delivery_rules: null,
    });

    setValue(`${lastIndex}.order_day`, 1);
    setValue(`${lastIndex}.limit_hours`, "00:00");
    setValue(`${lastIndex}.delivery_days`, 0);

    setLastIndex((prev) => prev + 1);
    setTimetables(oldData);
  };

  const deleteHandler = ({ id, id_delivery_rules }) => {
    if (id_delivery_rules) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this ORARI data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteRequest("POST", `delete-article-timetable`, {
            id_delivery_rules,
            id_article: props.Id,
          });

          unregister([
            `${id}.delivery_days`,
            `${id}.limit_hours`,
            `${id}.id_delivery_rules`,
          ]);

          setTimetables(timetables.filter((timetable) => timetable.id !== id));

          swal("Poof! Your ORARI data has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your ORARI data is safe!");
        }
      });
    } else {
      unregister([
        `${id}.delivery_days`,
        `${id}.limit_hours`,
        `${id}.id_delivery_rules`,
      ]);
      setTimetables(timetables.filter((timetable) => timetable.id !== id));
    }
  };

  const timeHandler = (key, val, weekName) => {
    let time;

    if (!weekName) {
      time = val;
    } else {
      time = getValues(key + ".limit_hours");
      // time = moment().set({
      //   h: time.split(":")[0],
      //   m: time.split(":")[1],
      // });
    }

    const selectedDay = !weekName ? getValues(key + ".order_day") : +weekName;

    for (let i = 0; i < timetables.length; i++) {
      const itemId = timetables[i].id;
      clearErrors(itemId + ".limit_hours");
    }

    let selectedDays = [];

    if (selectedDay !== 0) {
      for (let i = 0; i < timetables.length; i++) {
        const item = timetables[i];

        if (item.id == key) {
          continue;
        }

        const itemDay = getValues(item.id + ".order_day");

        if (itemDay == 0 || itemDay == selectedDay) {
          selectedDays.push(item);
        }
      }
    } else {
      selectedDays = [...timetables].filter((t) => t.id != key);
    }

    let isError = false;

    for (let i = 0; i < selectedDays.length; i++) {
      const item = selectedDays[i];

      let itemTime = getValues(item.id + ".limit_hours");

      if (itemTime === time) {
        setError(key + ".limit_hours", {
          type: "manual",
        });
      } else {
        clearErrors(key + ".limit_hours");
      }

      if (isError) {
        break;
      }
    }
  };

  return (
    <div
    className={
      showHeading == 1 ? "tab-pane fade show active" : "tab-pane fade"
    }
      id="point-tab-pane"
      role="tabpanel"
      aria-labelledby="point-tab"
      tabIndex={0}
    >
      <div className="themeTable customerTable">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div>
            <button
              type="button"
              className="btn btn-sm btn-primary ms-3"
              onClick={addNewRow}
            >
              Add
            </button>
            <button type="submit" className="btn btn-sm btn-primary ms-3">
              Save
            </button>
          </div>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                {/* <th style={{ width: "15%" }}>ID</th> */}
                <th style={{ width: "15%" }}>Order day</th>
                <th style={{ width: "30%" }}>Start time</th>
                <th style={{ width: "30%" }}>Delivery Days</th>
                <th style={{ width: "10%" }} />
              </tr>
            </thead>
            <tbody>
              {timetables.length > 0 &&
                timetables.map((timetable) => (
                  <tr key={`timetable_${timetable.id}`}>
                    {/* <td
                      data-th="ID"
                      {...register(`${timetable.id}.id_delivery_rules`)}
                    >
                      {timetable.id}
                    </td> */}
                    <td data-th="delivery">
                      <select
                        className={`form-control ${
                          errors[timetable.id]?.order_day && "is-invalid"
                        }`}
                        {...register(`${timetable.id}.order_day`, {
                          required: true,
                          minLength: 1,
                        })}
                        onChange={(e) =>
                          timeHandler(
                            timetable.id,
                            null,
                            e.target.value.toString()
                          )
                        }
                      >
                      `  <option value={0}>All</option>
                        <option value={1}>Monday</option>
                        <option value={2}>Tuesday</option>`
                        <option value={3}>Wednesday</option>
                        <option value={4}>Thursday</option>
                        <option value={5}>Friday</option>
                        <option value={6}>Saturday</option>
                        <option value={7}>Sunday</option>
                      </select>
                      {errors[timetable.id]?.order_day?.type === "required" && (
                        <div className="invalid-feedback">
                          The order day field is required.
                        </div>
                      )}
                    </td>
                    <td data-th="limit_hours">
                      <select
                        className={`form-control ${
                          errors[timetable.id]?.limit_hours && "is-invalid"
                        }`}
                        {...register(`${timetable.id}.limit_hours`, {
                          required: true,
                          minLength: 1,
                        })}
                        onChange={(e) =>
                          timeHandler(timetable.id, e.target.value)
                        }
                      >
                        {TIME_SLOTS.map((time, idx) => (
                          <option value={time} key={idx}>
                            {time}
                          </option>
                        ))}
                      </select>
                      {errors[timetable.id]?.limit_hours?.type ===
                        "required" && (
                        <div className="invalid-feedback">
                          The start time field is required.
                        </div>
                      )}
                      {errors[timetable.id]?.limit_hours?.type === "manual" && (
                        <div className="invalid-feedback">
                          Time has already been taken.
                        </div>
                      )}
                    </td>
                    <td data-th="end_time">
                      <input
                     type="number"
                        className={`form-control ${
                          errors[timetable.id]?.delivery_days && "is-invalid"
                        }`}
                        {...register(`${timetable.id}.delivery_days`, {
                          required: true,
                        })}
                      />
                      {errors[timetable.id]?.delivery_days?.type ===
                        "required" && (
                        <div className="invalid-feedback">
                          The Giorni Consegna field is required.
                        </div>
                      )}
                    </td>
                    <td data-th="ACTION">
                      <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={() => deleteHandler(timetable)}
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </form>
      </div>
      {/* {perPage !== 0 && (
        <Pagination
          page={page}
          totalRecords={totalDocuments}
          getNewData={fetchMoreData}
          perPage={perPage}
          defaultPerPage={1}
          perPageChangeHandler={perPageChangeHandler}
          currentDocLength={timetables.length}
        />
      )} */}
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Orari;
