import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../component/header/Header";
import SIdebar from "../../component/sidebar/SIdebar";
import Contatti from "./contatti";
import useRequest from "../../hooks/useRequest";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { API } from "../../constant/api";
import { toast } from "react-toastify";
import Select from "react-select";
import Timetable from "./Timetable";

const EditPuntoVendita = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [customers, setCustomers] = useState([]);
  const [customerContacts, setCustomerContacts] = useState([]);
  const [timetables, setTimetables] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  // const [Id, setId ] =useState()
  const { token } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const { request, response } = useRequest();
  const { request: customerContactRequest, response: customerContactResponse } =
    useRequest();
  const { request: customerRequest, response: customerResponse } = useRequest();
  const { request: timetableRequest, response: timetableResponse } =
    useRequest();
  const { request: tariffRequest, response: tariffResponse } = useRequest();

  useEffect(() => {
    customerContactRequest("GET", "customer-contacts");
    customerRequest("GET", "customer-list");
    timetableRequest("GET", "timetables");
    tariffRequest("GET", "tariff-type");
    if (!state) {
      navigate("/puntovendita");
    }
    reset({
      note: state.note,
      description: state.description,
      order_zone: state.order_zone,
      esternal: state.esternal,
      id_customer: state?.customer.id_customer,
      id_tariff_type: state?.tariff_type.id_tariff_type,
    });
  }, []);
  const Id = state?.id_selling_point;
  useEffect(() => {
    if (customerResponse) {
      setCustomers(
        customerResponse.data?.results?.map((customer) => {
          return {
            value: customer.id_customer,
            label:
              customer.name + " " + (customer.surname ? customer.surname : ""),
          };
        })
      );
    }
  }, [customerResponse]);

  useEffect(() => {
    if (customerContactResponse) {
      setCustomerContacts(
        customerContactResponse.data?.contact_customer?.results?.map(
          (contact) => {
            return {
              value: contact.id_contact_customer,
              label: contact.name,
            };
          }
        )
      );
    }
  }, [customerContactResponse]);

  useEffect(() => {
    if (timetableResponse) {
      setTimetables(
        timetableResponse.data.map((timetable) => {
          return {
            value: timetable.id_customer_hours,
            label: timetable.start_time + "-" + timetable.end_time,
          };
        })
      );
    }
  }, [timetableResponse]);

  useEffect(() => {
    if (tariffResponse) {
      setTariffs(
        tariffResponse.data?.map((timetable) => {
          return {
            value: timetable.id_tariff_type,
            label: timetable.descrizione,
          };
        })
      );
    }
  }, [tariffResponse]);

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      navigate("/puntovendita");
    }
  }, [response]);

  const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  const customerSearchHandler = debounce(async (inputValue, callback) => {
    const response = await asyncHandler(
      `${API.PORT}/customer-list?name=${inputValue}`
    );

    callback(
      response.results.map((customer) => {
        return {
          value: customer.id_customer,
          label: customer.name + " " + customer.surname,
        };
      })
    );
  }, 1000);

  const contactSearchHandler = debounce(async (inputValue, callback) => {
    const response = await asyncHandler(
      `${API.PORT}/customer-contacts?name=${inputValue}`
    );
    callback(
      response.contact_customer?.results?.map((contact) => {
        return {
          value: contact.id_contact_customer,
          label: contact.name,
        };
      })
    );
  }, 1000);

  const asyncHandler = async (link) => {
    const response = await axios
      .get(link, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err));

    return response.data;
  };

  const submitHandler = (data) => {
    data.esternal = Boolean(data.esternal);
    data.order_zone = +data.order_zone;
    request("POST", `edit-store/${id}`, data);
  };

  return (
    <>
      <Header />
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">ANAGRAFICA</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/puntovendita">PUNTI VENDITA</Link>
                  </li>
                  {/* <li className="breadcrumb-item">
                    <a href="#">PUNTI VENDITA</a>
                  </li> */}
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Content */}
          <div className="pageContent">
            <div className="themeTabs my-4">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="data-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#data-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="data-tab-pane"
                    aria-selected="true"
                  >
                    DATI PUNTO VENDITA
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="contact-tab-pane"
                    aria-selected="false"
                  >
                    CONTATTI
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="point-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#point-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="point-tab-pane"
                    aria-selected="false"
                  >
                    {" "}
                    ORARI
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="bank-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#bank-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="bank-tab-pane"
                    aria-selected="false"
                  >
                    {" "}
                    ORDINI STANDARD
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="data-tab-pane"
                  role="tabpanel"
                  aria-labelledby="data-tab"
                  tabIndex={0}
                >
                  <form onSubmit={handleSubmit(submitHandler)}>
                    <div className="tabH-full">
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Customer</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  name="id_customer"
                                  rules={{ required: true, minLength: 1 }}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <AsyncSelect
                                      value={customers.find(
                                        (c) => c.value === value
                                      )}
                                      cacheOptions
                                      // menuPosition="fixed"
                                      placeholder="Select Customer"
                                      className={`basic-multi-select ${
                                        errors.id_customer && "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      defaultOptions={customers}
                                      onChange={(e) => onChange(e.value)}
                                      loadOptions={customerSearchHandler}
                                    />
                                  )}
                                />
                                {errors.id_customer?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The customer field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Contact</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  name="id_contact_customer"
                                  rules={{ required: true, minLength: 1 }}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <AsyncSelect
                                      value={customerContacts.find(
                                        (c) => c.value === value
                                      )}
                                      cacheOptions
                                      menuPosition="fixed"
                                      placeholder="Select Contact"
                                      className={`basic-multi-select ${
                                        errors.id_contact_customer &&
                                        "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      defaultOptions={customerContacts}
                                      onChange={(e) => onChange(e.value)}
                                      loadOptions={contactSearchHandler}
                                    />
                                  )}
                                />
                                {errors.id_contact_customer?.type ===
                                  "required" && (
                                  <div className="invalid-feedback">
                                    The contact field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="row g-lg-0">
                        {/* <div className="col-md-4"> */}
                        {/* <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Hour</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  name="id_customer_hours"
                                  rules={{ required: true, minLength: 1 }}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <Select
                                      value={timetables.find(
                                        (c) => c.value === value
                                      )}
                                      menuPosition="fixed"
                                      options={timetables}
                                      placeholder="Select Hour"
                                      className={`basic-multi-select ${
                                        errors.id_customer_hours && "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      onChange={(val) => onChange(val.value)}
                                    />
                                  )}
                                />
                                {errors.id_customer_hours?.type ===
                                  "required" && (
                                  <div className="invalid-feedback">
                                    The hour field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>Tariff Type</label>
                              </div>
                              <div className="col-lg-7">
                                <Controller
                                  control={control}
                                  name="id_tariff_type"
                                  rules={{ required: true, minLength: 1 }}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <Select
                                      value={tariffs.find(
                                        (c) => c.value === value
                                      )}
                                      menuPosition="fixed"
                                      options={tariffs}
                                      placeholder="Select Traffic"
                                      className={`basic-multi-select ${
                                        errors.id_tariff_type && "is-invalid"
                                      }`}
                                      classNamePrefix="select"
                                      onChange={(val) => onChange(val.value)}
                                    />
                                  )}
                                />
                                {errors.id_tariff_type?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The tariff field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>esternal</label>
                              </div>
                              <div className="col-lg-7">
                                <div className="form-check">
                                  <input
                                    className="form-check-input checklg"
                                    type="checkbox"
                                    defaultValue={false}
                                    id="PRIVATO"
                                    {...register("esternal", {
                                      // required: true,
                                    })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="PRIVATO"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>order zone</label>
                              </div>
                              <div className="col-lg-7">
                                <input
                                  className={`form-control ${
                                    errors.order_zone && "is-invalid"
                                  }`}
                                  min="0"
                                  defaultValue={0}
                                  type="number"
                                  {...register("order_zone", {
                                    required: true,
                                    min: 0,
                                  })}
                                />
                                {errors.order_zone?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The order zone field is required.
                                  </div>
                                )}
                                {errors.order_zone?.type === "min" && (
                                  <div className="invalid-feedback">
                                    The order zone field must not be less then
                                    0.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-0">
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>description</label>
                              </div>
                              <div className="col-lg-7">
                                <textarea
                                  className={`form-control ${
                                    errors.description && "is-invalid"
                                  }`}
                                  cols="20"
                                  rows="5"
                                  {...register("description", {
                                    required: true,
                                  })}
                                ></textarea>
                                {errors.description?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The description field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <div className="row align-items-center g-lg-3">
                              <div className="col-lg-5 text-lg-end">
                                <label>note</label>
                              </div>
                              <div className="col-lg-7">
                                <textarea
                                  className={`form-control ${
                                    errors.note && "is-invalid"
                                  }`}
                                  cols="20"
                                  rows="5"
                                  {...register("note", {
                                    required: true,
                                  })}
                                ></textarea>
                                {errors.note?.type === "required" && (
                                  <div className="invalid-feedback">
                                    The note field is required.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-md-4 mt-3 text-center text-md-start">
                      <button className="btn btn-pill btn-yellow">SALVA</button>
                      <button
                        className="btn btn-pill btn-blue ms-2 ms-md-3"
                        onClick={() => {
                          navigate("/puntovendita");
                        }}
                      >
                        ANNULLA
                      </button>
                    </div>
                  </form>
                </div>
                <Contatti Id={Id} />

                <Timetable Id={Id} />
                <div
                  className="tab-pane fade"
                  id="bank-tab-pane"
                  role="tabpanel"
                  aria-labelledby="bank-tab"
                  tabIndex={0}
                >
                  <div className="tabH-full">4</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditPuntoVendita;
