import React, { useEffect, useState } from "react";
import SIdebar from "../../../component/sidebar/SIdebar";
import Header from "../../../component/header/Header";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import PrezziCatalogo from "./PrezziCatalogo";
import Articoli from "./Articoli";

function EditCatalog() {
  let navigate = useNavigate();
  const { state } = useLocation();
  if (!state) {
    navigate("/catalogo");
  }
  // console.log(state, "state");
  const [merchandise, setMerchandise] = useState([]);
  const [product, setProduct] = useState([]);
  const [storage, setStorage] = useState([]);
  const [show, setShow] = useState(false);
  // console.log(state.is_lab_product,'UITFiwef')
  const [lab, setLab] = useState(state.is_lab_product);
  const [microCategory, setMicroCategory] = useState([]);
  const [cata, setCata] = useState([]);
  const [subcata, setSubCata] = useState([]);
  const [categorydes, setCategorydes] = useState("");
  const [subcategorydes, setsubCategorydes] = useState("");
  const [subcatavalue, setsubcatavalue] = useState(
    state.Sub_Category.description
  );
  const [category, setCategory] = useState(
    state.Sub_Category.category_catalog.id_category_catalog
  );
  const [showMicro, setShowMicro] = useState(false);
  const [showCata, setShowCata] = useState(false);
  const [showsubCata, setShowsubCata] = useState(false);
  const [macrodiscription, setMacrodiscription] = useState();
  const [macro, setMacro] = useState(
    state.Sub_Category.category_catalog.macro_category_catalog.id_macro_cat
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { response, request } = useRequest();
  const { response: merchandiseResponse, request: merchandiseRequest } =
    useRequest();
  const { response: storageResponse, request: storageRequest } = useRequest();
  const { request: productRequest, response: productResponse } = useRequest();

  const { request: microCategoryRequest, response: microCategoryResponse } =
    useRequest();
  const { response: addmacroResponse, request: addmacroRequest } = useRequest();
  const { request: categoryRequest, response: categoryResponse } = useRequest();
  const { request: addcateRequest, response: addcateResponse } = useRequest();
  const { request: subcateRequest, response: subcateResponse } = useRequest();
  const {
    request: subcateDiscriptonRequest,
    response: subcateDiscriptonResponse,
  } = useRequest();

  useEffect(() => {
    storageRequest("GET", "get-storage");
    productRequest("GET", "get-prod-group");
    merchandiseRequest("GET", "get-merchandise");
    microCategoryRequest("GET", "macro-category");

    reset({
      ...state,
      is_lab_product: lab,
      id_macro_cat:
        state.Sub_Category.category_catalog.macro_category_catalog.id_macro_cat,
      id_category_catalog:
        state.Sub_Category.category_catalog.id_category_catalog,
      id_sub_cat: state.Sub_Category?.id_sub_cat,
      id_storage: state.storage_catalog?.id_storage,
      id_products_group: state.product_group?.id_products_group,
      id_merchandise_cat: state.merchandise_category?.id_merchandise_cat,
    });
  }, []);

  useEffect(() => {
    if (storageResponse) {
      setStorage(
        storageResponse.data.map((time) => {
          return {
            value: time.id_storage,
            label: time.description,
          };
        })
      );
    }
  }, [storageResponse]);
  // useEffect(()=>{
  // s
  //   subcata.filter(subcata === state.Sub_Category.id_sub_cat).map(filteredName => (
  //     console.log(filteredName.label,'aerqwerwqerqwvzfdgser',state.Sub_Category.id_sub_cat)
  //     // console.log(state.Sub_Category.id_sub_cat,'console')

  //       ))
  // },[])
  useEffect(() => {
    categoryRequest("GET", `cate-catalog/${macro}`);
  }, [macro]);

  useEffect(() => {
    subcateRequest("GET", `sub-category/${category}`);
  }, [category]);

  useEffect(() => {
    if (merchandiseResponse) {
      setMerchandise(
        merchandiseResponse.data.map((merchandise) => {
          return {
            value: merchandise.id_merchandise_cat,
            label: merchandise.description,
          };
        })
      );
    }
  }, [merchandiseResponse]);

  useEffect(() => {
    if (productResponse) {
      setProduct(
        productResponse.data.map((list) => {
          return {
            value: list.id_products_group,
            label: list.description,
          };
        })
      );
    }
  }, [productResponse]);

  useEffect(() => {
    if (microCategoryResponse) {
      setMicroCategory(
        microCategoryResponse.data.map((list, o) => {
          return {
            value: list.id_macro_cat,
            label: list.description,
          };
        })
      );
    }
  }, [microCategoryResponse]);

  useEffect(() => {
    if (categoryResponse) {
      if (categoryResponse.data?.category_catalog?.length > 0) {
        setCata(
          categoryResponse.data.category_catalog.map((list, o) => {
            return {
              value: list.id_category_catalog,
              label: list.description,
            };
          })
        );
      } else {
        setCata([]);
        toast.error(
          "you added a new macro category. please add category first"
        );
      }
    }
  }, [categoryResponse]);

  useEffect(() => {
    if (subcateResponse) {
      if (subcateResponse.data?.length > 0) {
        setSubCata(
          subcateResponse.data.map((list) => {
            return {
              value: list.id_sub_cat,
              label: list.description,
            };
          })
        );
      } else {
        setSubCata([]);
        toast.error("you added a new category. please add sub category first");
      }
    }
  }, [subcateResponse]);

  useEffect(() => {
    microCategoryRequest("GET", "macro-category");
  }, [show]);

  useEffect(() => {
    if (response) {
      if (response.status === "SUCCESS") {
        toast.success("Catalogare aggiornato correttamente");
        navigate("/catalogo");
      } else {
        toast.error(response.message);
      }
    }
  }, [response]);

  useEffect(() => {
    if (addmacroResponse) {
      if (addmacroResponse.status === "SUCCESS") {
        setMacrodiscription("");
        microCategoryRequest("GET", "macro-category");
        setShowMicro(false);
      } else {
        toast.error(response.message);
      }
    }
  }, [addmacroResponse]);
  useEffect(() => {
    if (addcateResponse) {
      if (addcateResponse.status === "SUCCESS") {
        setCategorydes("");
        categoryRequest("GET", `cate-catalog/${macro}`);
        setShowCata(false);
      } else {
        toast.error(response.message);
      }
    }
  }, [addcateResponse]);

  useEffect(() => {
    if (subcateDiscriptonResponse) {
      if (subcateDiscriptonResponse.status === "SUCCESS") {
        subcateRequest("GET", `sub-category/${category}`);
        setShowsubCata(false);
      } else {
        toast.error(response.message);
      }
    }
  }, [subcateDiscriptonResponse]);

  const onMacroSubmit = (e) => {
    e.preventDefault();
    if (!macrodiscription) {
      toast.error("The description field is required.");
    } else {
      addmacroRequest("POST", "add-macro-cata", {
        description: macrodiscription,
        prefix: macrodiscription.slice(0, 3),
      });
    }
  };
  const onCataSubmit = (e) => {
    e.preventDefault();
    if (!categorydes) {
      toast.error("The description field is required.");
    } else {
      addcateRequest("POST", `add-cate-catalog`, {
        description: categorydes,
        id_macro_cat: macro,
      });
    }
  };
  const onSubCatSubmit = (e) => {
    e.preventDefault();
    if (!subcategorydes) {
      toast.error("The description field is required.");
    } else {
      subcateDiscriptonRequest("POST", `add-sub-category`, {
        description: subcategorydes,
        id_category_catalog: category,
      });
    }
  };

  const onModalSubmit = (e) => {
    e.preventDefault();
    setShow(false);
  };
// console.log(   state.Sub_Category.description,"   state.Sub_Category.description")
  const onSubmit = (data) => {
    request("POST", "edit-catalog", data);
  };
  return (
    <>
      <Header />
      {/* Overlay */}
      <div className="overlay" style={{ display: "none" }} />
      <div className="dashboard-wrap">
        {/* Sidebar */}
        <SIdebar />
        <div className="dashboard-content">
          {/* Page Head */}
          <div className="pagehead">
            <div className="pageheadTop">
              <span className="ph-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                  ></path>
                </svg>
              </span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">ANAGRAFICA</a>
                  </li>

                  <li className="breadcrumb-item">
                    <a href="#" onClick={() => navigate(-1)}>
                      CATALOGO
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">MODIFICARE</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Content */}
          <div className="pageContent">
            <div className="themeTabs my-4">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="articles-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#articles-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="articles-tab-pane"
                    aria-selected="true"
                  >
                    GENERALE
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="general-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#general-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="general-tab-pane"
                    aria-selected="false"
                  >
                    Prezzi catalogo
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="point-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#point-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="point-tab-pane"
                    aria-selected="false"
                  >
                    {" "}
                    Articoli
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="articles-tab-pane"
                  role="tabpanel"
                  aria-labelledby="articles-tab"
                  tabIndex={0}
                >
                  <div className="tabH-full">
                    <form method="post" onSubmit={handleSubmit(onSubmit)}>
                      <div className="tabH-full">
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label> HQF Code</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.catalog_code && "is-invalid"
                                    }`}
                                    name="catalog_code"
                                    id="catalog_code"
                                    {...register("catalog_code", {
                                      required: true,
                                    })}
                                  />
                                  {errors.catalog_code?.type === "required" && (
                                    <div className="invalid-feedback">
                                      Informazioni field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>personalizzato</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="customized"
                                      {...register("customized")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customized"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>Conservazione</label>
                                </div>
                                <div className="col-lg-7">
                                  <Controller
                                    control={control}
                                    rules={{ required: true, minLength: 1 }}
                                    name="id_storage"
                                    render={({
                                      field: { onChange, value, ref },
                                    }) => (
                                      <Select
                                        value={storage.find(
                                          (c) => c.value === value
                                        )}
                                        menuPosition="fixed"
                                        options={storage}
                                        classNamePrefix="select province"
                                        placeholder="select "
                                        className={`basic-multi-select ${
                                          errors.id_storage && "is-invalid"
                                        }`}
                                        onChange={(e) => onChange(e.value)}
                                      />
                                    )}
                                  />
                                  {errors.id_storage?.type === "required" && (
                                    <div className="invalid-feedback">
                                      The Conservazione field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>Informazioni</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.info && "is-invalid"
                                    }`}
                                    name="info"
                                    id="info"
                                    {...register("info", {
                                      required: true,
                                    })}
                                  />
                                  {errors.info?.type === "required" && (
                                    <div className="invalid-feedback">
                                      Informazioni field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>unità di misura</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.unit_measure && "is-invalid"
                                    }`}
                                    name="unit_measure"
                                    id="unit_measure"
                                    {...register("unit_measure", {
                                      required: true,
                                    })}
                                  />
                                  {errors.unit_measure?.type === "required" && (
                                    <div className="invalid-feedback">
                                      unità di misura field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>tempi di consegna in giorni</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      errors.delivery_time && "is-invalid"
                                    }`}
                                    name="delivery_time"
                                    id="delivery_time"
                                    {...register("delivery_time", {
                                      required: true,
                                    })}
                                  />
                                  {errors.butchered_country?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      paese macellato field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>Categoria merceologica</label>
                                </div>
                                <div className="col-lg-7">
                                  <Controller
                                    control={control}
                                    name="id_merchandise_cat"
                                    rules={{ required: true, minLength: 1 }}
                                    render={({
                                      field: { onChange, value, ref },
                                    }) => (
                                      <Select
                                        value={merchandise.find(
                                          (c) => c.value === value
                                        )}
                                        options={merchandise}
                                        placeholder="Select"
                                        className={`basic-multi-select ${
                                          errors.id_merchandise_cat &&
                                          "is-invalid"
                                        }`}
                                        classNamePrefix="select"
                                        onChange={(e) => onChange(e.value)}
                                      />
                                    )}
                                  />
                                  {errors.id_merchandise_cat?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      The Categoria merceologica field is
                                      required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>sottocategoria</label>
                                </div>
                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className={`form-control`}
                                    name="description"
                                    id="description"
                                    value={subcatavalue}
                                    disabled={true}
                                  />
                                </div>
                                <div className="col-lg-1">
                                  <button
                                    className="btn -pill btn-yellow"
                                    type="button"
                                    onClick={() => setShow(true)}
                                  >
                                    Nuovo
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>margine</label>
                                </div>
                                <div className="col-lg-7">
                                  <input

                                  step="0.01"
                                    type="number"
                                    className={`form-control ${
                                      errors.margin && "is-invalid"
                                    }`}
                                    name="margin"
                                    id="margin"
                                    {...register("margin", {
                                      required: true,
                                    })}
                                  />
                                  {errors.margin?.type === "required" && (
                                    <div className="invalid-feedback">
                                      margine field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>prezzo di vendita</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    pattern="^\d+(?:\.\d{1,2})?$"
                                    min="0" max="10000000000" step="0.01"
                                    className={`form-control ${
                                      errors.selling_price && "is-invalid"
                                    }`}
                                    name="selling_price"
                                    id="selling_price"
                                    {...register("selling_price", {
                                      required: true,
                                    })}
                                  />
                                  {errors.selling_price?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      paese sezionato field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>Gruppo di prodotti</label>
                                </div>
                                <div className="col-lg-7">
                                  <Controller
                                    control={control}
                                    rules={{ required: true, minLength: 1 }}
                                    name="id_products_group"
                                    render={({
                                      field: { onChange, value, ref },
                                    }) => (
                                      <Select
                                        value={product.find(
                                          (c) => c.value === value
                                        )}
                                        // menuPosition="fixed"
                                        options={product}
                                        classNamePrefix="select"
                                        placeholder="Select"
                                        className={`basic-multi-select ${
                                          errors.id_products_group &&
                                          "is-invalid"
                                        }`}
                                        onChange={(e) => onChange(e.value)}
                                      />
                                    )}
                                  />
                                  {errors.id_products_group?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      The Gruppo di prodotti field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>materiali di consumo</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="consumables"
                                      {...register("consumables")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="consumables"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>kg al pezzo</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      errors.kg_per_piece && "is-invalid"
                                    }`}
                                    name="kg_per_piece"
                                    id="kg_per_piece"
                                    {...register("kg_per_piece", {
                                      required: true,
                                    })}
                                  />
                                  {errors.kg_per_piece?.type === "required" && (
                                    <div className="invalid-feedback">
                                      kg al pezzo field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>novità</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="novelty"
                                      {...register("novelty")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="product_lab"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0"></div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>durata di conservazione media</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      errors.average_shelflife && "is-invalid"
                                    }`}
                                    name="average_shelflife"
                                    id="average_shelflife"
                                    {...register("average_shelflife", {
                                      required: true,
                                    })}
                                  />
                                  {errors.average_shelflife?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      durata di conservazione media field is
                                      required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>Soglia scadenza minima</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      errors.minimum_expiry_threshold &&
                                      "is-invalid"
                                    }`}
                                    name="minimum_expiry_threshold"
                                    id="minimum_expiry_threshold"
                                    {...register("minimum_expiry_threshold", {
                                      required: true,
                                    })}
                                  />
                                  {errors.minimum_expiry_threshold?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      Soglia scadenza minima field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>vendita di imballaggi?</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="PRIVATO"
                                      {...register("is_packaging_selling")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="is_packaging_selling"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>ATTIVO</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="active"
                                      {...register("active")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="PRIVATO"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>Soglia scadenza massima</label>
                                </div>
                                <div className="col-lg-7  ">
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      errors.maximum_expiry_threshold &&
                                      "is-invalid"
                                    }`}
                                    name="maximum_expiry_threshold"
                                    id="maximum_expiry_threshold"
                                    {...register("maximum_expiry_threshold", {
                                      required: true,
                                    })}
                                  />
                                  {errors.maximum_expiry_threshold?.type ===
                                    "required" && (
                                    <div className="invalid-feedback">
                                      unisci misura riordina field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>data di scadenza</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      errors.shelflife && "is-invalid"
                                    }`}
                                    name="
                                  data di scadenza"
                                    id="shelflife"
                                    {...register("shelflife", {
                                      required: true,
                                    })}
                                  />
                                  {errors.shelflife?.type === "required" && (
                                    <div className="invalid-feedback">
                                      data di scadenza field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>DESCRIPTION</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.description && "is-invalid"
                                    }`}
                                    name=""
                                    id="description"
                                    {...register("description", {
                                      required: true,
                                    })}
                                  />
                                  {errors.description?.type === "required" && (
                                    <div className="invalid-feedback">
                                      description field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>correzione del margine</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    pattern="^\d+(?:\.\d{1,2})?$"
                                    min="0" step="0.25"
                                    className={`form-control ${
                                      errors.margin_fix && "is-invalid"
                                    }`}
                                    name="margin_fix"
                                    id="margin_fix"
                                    {...register("margin_fix", {
                                      required: true,
                                    })}
                                  />
                                  {errors.margin_fix?.type === "required" && (
                                    <div className="invalid-feedback">
                                      nota laboratorio field is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>laboratorio del prodotto</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="PRIVATO"
                                      {...register("is_lab_product")}
                                      onChange={(e) => {
                                        setLab(e.target.checked);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="product_lab"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>Tipo laboratorio</label>
                                </div>
                                <div className="col-lg-7">
                                  <input
                                    type="text"
                                    className={`form-control `}
                                    name="lab_type"
                                    id="lab_type"
                                    disabled={lab === false}
                                    {...register("lab_type")}
                                  />
                                  {/* {errors.birth_country?.type === "required" && (
                                  <div className="invalid-feedback">
                                    Paese di nascita field is required.
                                  </div>
                                )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-lg-0">
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>prezzo fisso</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="PRIVATO"
                                      {...register("fixed_price")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="product_lab"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="row align-items-center g-lg-3">
                                <div className="col-lg-5 text-lg-end">
                                  <label>isconvstimata</label>
                                </div>
                                <div className="col-lg-7">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input checklg"
                                      type="checkbox"
                                      defaultValue=""
                                      id="isconvstimata"
                                      {...register("isconvstimata")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="product_lab"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-md-4 mt-3 text-center text-md-start">
                        <button className="btn btn-pill btn-yellow">
                          SALVA
                        </button>
                        <button
                          className="btn btn-pill btn-blue ms-2 ms-md-3"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          ANNULLA
                        </button>
                      </div>
                    </form>
                    <Modal
                      show={show}
                      onHide={() => setShow(false)}
                      backdrop="static"
                      keyboard={false}
                    >
                      <form onSubmit={onModalSubmit}>
                        <Modal.Header>
                          <Modal.Title>generale</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row g-lg-0">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="row align-items-center">
                                  <div className="col-lg-3 ">
                                    <label>Macro-Category</label>
                                  </div>
                                  <div className="col-lg-7">
                                    <Controller
                                      control={control}
                                      name="id_macro_cat"
                                      rules={{ minLength: 1 }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          value={microCategory.find(
                                            (c) => c.value === value
                                          )}
                                          options={microCategory}
                                          placeholder="Select"
                                          className={`basic-multi-select `}
                                          classNamePrefix="select"
                                          onChange={(val) => {
                                            onChange(val.value);
                                            setMacro(val.value);
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="col-lg-2">
                                    <button
                                      className="btn -pill btn-yellow"
                                      type="button"
                                      onClick={() => setShowMicro(true)}
                                    >
                                      Nuovo
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {showMicro === true && (
                              <>
                                <div className="row g-lg-0">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <div className="row align-items-center">
                                        <div className="col-lg-3 ">
                                          <label>descrizione</label>
                                        </div>
                                        <div className="col-lg-4">
                                          <input
                                            type="text"
                                            className={"form-control"}
                                            name=""
                                            id="description"
                                            onChange={(e) => {
                                              setMacrodiscription(
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="col-lg-2">
                                          {" "}
                                          <button
                                            className="btn -pill btn-yellow"
                                            type="button"
                                            onClick={onMacroSubmit}
                                          >
                                            SALVA
                                          </button>
                                        </div>
                                        <div className="col-lg-2">
                                          {" "}
                                          <button
                                            className=" btn -pill btn-blue "
                                            type="button"
                                            onClick={() => {
                                              setShowMicro(false);
                                            }}
                                          >
                                            ANNULLA
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="row g-lg-0">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="row align-items-center">
                                  <div className="col-lg-3 ">
                                    <label>Category</label>
                                  </div>
                                  <div className="col-lg-7">
                                    <Controller
                                      control={control}
                                      name="id_category_catalog"
                                      rules={{ minLength: 1 }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          value={cata.find(
                                            (c) => c.value === value
                                          )}
                                          placeholder="Select"
                                          className={`basic-multi-select`}
                                          classNamePrefix="select"
                                          options={cata}
                                          onChange={(val) => {
                                            onChange(val.value);
                                            setCategory(val.value);
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="col-lg-2">
                                    <button
                                      className="btn -pill btn-yellow"
                                      type="button"
                                      onClick={() => setShowCata(true)}
                                    >
                                      Nuovo
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {showCata === true && (
                              <>
                                <div className="row g-lg-0">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <div className="row align-items-center">
                                        <div className="col-lg-3 ">
                                          <label>descrizione</label>
                                        </div>
                                        <div className="col-lg-4">
                                          <input
                                            type="text"
                                            className={"form-control"}
                                            name=""
                                            id="description"
                                            onChange={(e) => {
                                              e.preventDefault();
                                              setCategorydes(e.target.value);
                                            }}
                                          />
                                        </div>
                                        <div className="col-lg-2">
                                          {" "}
                                          <button
                                            className="btn -pill btn-yellow"
                                            type="button"
                                            onClick={onCataSubmit}
                                          >
                                            SALVA
                                          </button>
                                        </div>
                                        <div className="col-lg-2">
                                          {" "}
                                          <button
                                            className=" btn -pill btn-blue "
                                            type="button"
                                            onClick={() => {
                                              setShowCata(false);
                                            }}
                                          >
                                            ANNULLA
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="row g-lg-0">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="row align-items-center">
                                  <div className="col-lg-3 ">
                                    <label>Sub Category</label>
                                  </div>
                                  <div className="col-lg-7">
                                    <Controller
                                      control={control}
                                      name="id_sub_cat"
                                      rules={{ required: true, minLength: 1 }}
                                      render={({
                                        field: { onChange, onBlur, value, ref },
                                      }) => (
                                        <Select
                                          value={subcata.find(
                                            (c) => c.value === value
                                          )}
                                          placeholder="Select"
                                          className={`basic-multi-select`}
                                          classNamePrefix="select"
                                          options={subcata}
                                          onChange={(val) => {
                                            onChange(val.value);
                                            setsubcatavalue(val.label);
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="col-lg-2">
                                    <button
                                      className="btn -pill btn-yellow"
                                      type="button"
                                      onClick={() => setShowsubCata(true)}
                                    >
                                      Nuovo
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {showsubCata === true && (
                              <>
                                <div className="row g-lg-0">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <div className="row align-items-center">
                                        <div className="col-lg-3 ">
                                          <label>descrizione</label>
                                        </div>
                                        <div className="col-lg-4">
                                          <input
                                            type="text"
                                            className={"form-control"}
                                            name=""
                                            id="description"
                                            onChange={(e) => {
                                              e.preventDefault();
                                              setsubCategorydes(e.target.value);
                                            }}
                                          />
                                        </div>
                                        <div className="col-lg-2">
                                          {" "}
                                          <button
                                            className="btn -pill btn-yellow"
                                            type="button"
                                            onClick={onSubCatSubmit}
                                          >
                                            SALVA
                                          </button>
                                        </div>
                                        <div className="col-lg-2">
                                          {" "}
                                          <button
                                            className=" btn -pill btn-blue "
                                            type="button"
                                            onClick={() => {
                                              setShowCata(false);
                                              setShowsubCata(false);
                                            }}
                                          >
                                            ANNULLA
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className="btn btn-pill btn-yellow"
                            // disabled={subcategorys === "" ? true : false}
                          >
                            SALVA
                          </button>
                          <button
                            className="btn btn-pill btn-blue ms-2 ms-md-3"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowMicro(false);
                              setShowCata(false);
                              setShow(false);
                              setMacro("");
                              setCategory("");
                              // setsubCategorys("");
                            }}
                          >
                            ANNULLA
                          </button>
                        </Modal.Footer>
                      </form>
                    </Modal>
                  </div>
                </div>
                <PrezziCatalogo state={state} />
                <Articoli state={state} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCatalog;
