/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Moment from "moment";
import { Link, useLocation } from "react-router-dom";

const CICLOATTIVO = [
  "/ordinidivenditaelenco",
  "/ordinidivendita",
  "/ordinidivenditaelenco2",
  "/documentiattivi",
  "/documentiattiviadd",
  "/documentiattiviedit" 
  // "/editClienti",
  // "/puntovendita/add",
  // "/puntovendita/edit/:id",
];
const CICLOPASSIVO = [
  "/documentopassivo/add",
  "/documentopassivo",
  "/documentopassivo/Edit"
  // "/ordinidivendita",
  // "/ordinidivenditaelenco2",
  // "/documentiattivi",
  // "/documentiattiviadd",
  // "/documentiattiviedit" 
  // "/editClienti",
  // "/puntovendita/add",
  // "/puntovendita/edit/:id",
];
const CATALOGOROUTES = [
  "/articoli",
  "/articoli/add",
  "/articoli/edit",
  "/catalogo",
  "/catalogo/add",
  "/catalogo/edit",
  "/listini",
  "/listini/add",
  "/listini/edit",
  "/stampaCatalogo",
  "/stampacatalogofile",
];

const PARTNER = [
  "/fornitori",
  "/addfornitori",
  "/fornitori/edit",
  "/fornitori/contatti/editcontatti",
  "/agents",
  "/agents/edit",
  "/agents/add",
  "/agents/contatti/addcontatti",
  "/agents/contatti/editcontatti",
];

const TABLE = ["/gruppiprodotto", "/categorie"];

function AmministrazioneSideBar() {
  const { pathname } = useLocation();
  let username = localStorage.getItem("username");
  const loginTime = Moment(new Date()).format("DD-MM-YYYY, hh:mm:ss");
  let time = null;
  const [dateTime, setDateTime] = useState(loginTime);

  useEffect(() => {
    SetTimer();
    return () => {
      clearInterval(time);
      time = null;
    };
  }, []);
  const SetTimer = () => {
    time = setInterval(() => {
      setDateTime(Moment(new Date()).format("DD-MM-YYYY, hh:mm:ss"));
    }, 1000);
  };

  // toggle

  const [toggleMenubar, setToggleNavbar] = useState(false);
  useEffect(() => {
    if (toggleMenubar) {
      document.body.classList.add("scroll-off");
    } else {
      document.body.classList.remove("scroll-off");
    }
  }, [toggleMenubar]);

  return (
    <>
      <button
        className="menuMobile"
        onClick={() => setToggleNavbar(!toggleMenubar)}
      >
        Menu
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={32}
          height={32}
          fill="currentColor"
          className="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </button>
      <div className={toggleMenubar ? `sidebar menu-active` : `sidebar`}>
        <button
          className="menuMobileClose"
          onClick={() => setToggleNavbar(!toggleMenubar)}
        >
          Close
          <i className="far fa-times" />
        </button>
        <div className="menulist" id="accordionMenu">
          <div className="menu-item has-submenu">
            <a
              // href="#!"
              className={CICLOATTIVO.includes(pathname) ? "collapsed" : ""}
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
            CICLO ATTIVO
            </a>
            <div
              id="collapseOne"
              className={`collapse ${
                CICLOATTIVO.includes(pathname) ? "show" : ""
              }`}
              data-bs-parent="#accordionMenu"
            >
              <ul className="list-unstyled mb-0">
                <li
                  className={
                    pathname === "/ordinidivenditaelenco"||
                    pathname === "/ordinidivenditaelenco2"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/ordinidivenditaelenco">ORDINI DI VENDITA</Link>
                </li>
                <li
                  className={
                    pathname === "/ordinidivendita" 
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/ordinidivendita"> INSERIMENTO ORDINI DI VENDITA </Link>
                </li>
                <li
                  className={
                    pathname === "/documentiattivi"||
                    pathname ===  "/documentiattiviadd"||
                    pathname === "/documentiattiviedit" 
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/documentiattivi">DOCUMENTI ATTIVI</Link>
                </li>
                <li
                  className={
                    pathname === "/puntovendita" ||
                    pathname === "/puntovendita/add" ||
                    pathname === "/puntovendita/edit/:id"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="#">GENRAZIONE FATTIURE DIFFERITE</Link>
                </li>
                <li
                  className={
                    pathname === "/puntovendita" ||
                    pathname === "/puntovendita/add" ||
                    pathname === "/puntovendita/edit/:id"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="#">SCADENZARIO</Link>
                </li>
                <li
                  className={
                    pathname === "/puntovendita" ||
                    pathname === "/puntovendita/add" ||
                    pathname === "/puntovendita/edit/:id"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="#">INCASSI</Link>
                </li>
                <li
                  className={
                    pathname === "/puntovendita" ||
                    pathname === "/puntovendita/add" ||
                    pathname === "/puntovendita/edit/:id"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="#">RIBA</Link>
                </li>
                <li
                  className={
                    pathname === "/puntovendita" ||
                    pathname === "/puntovendita/add" ||
                    pathname === "/puntovendita/edit/:id"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="#">REGISTTRAZIONE NC DA RASI</Link>
                </li>
                {/* Generazione fatture differite */}
                {/* Scadenzario */}
                {/* Incassi */}
                {/* Riba */}
                {/* Registrazione NC da resi */}
              </ul>
            </div>
          </div>

          <div className="menu-item has-submenu">
            <a
              // href="#!"
              className={CICLOPASSIVO.includes(pathname) ? "collapsed" : ""}
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >CICLO PASSIVO
            </a>
            <div
              id="collapseTwo"
              className={`collapse ${
                CICLOPASSIVO.includes(pathname) ? "show" : ""
              }`}
              data-bs-parent="#accordionMenu"
            >
              <ul className="list-unstyled active mb-0">
                <li
                  className={
                    pathname === "/documentopassivo/add" 
                      ? "active"
                      : ""
                  }
                >
                  <a href="/documentopassivo/add">NUOVO DOCUMENTO PASSIVO</a>
                </li>
                <li
                  className={
                    pathname === "/documentopassivo" ||
                    pathname === "/documentopassivo/Edit" 
                      ? "active"
                      : ""
                  }
                >
                  <a href="/documentopassivo">DOCUMENTI PASSIVI</a>
                </li>
                {/* <li
                  className={
                    pathname === "/listini" ||
                    pathname === "/listini/add" ||
                    pathname === "/listini/edit"
                      ? "active"
                      : ""
                  }
                >
                  <a href="/listini">LISTINI</a>
                </li>

                <li
                  className={
                    pathname === "/stampaCatalogo" ||
                    pathname === "/stampacatalogofile"
                      ? "active"
                      : ""
                  }
                >
                  <a href="/stampaCatalogo">STAMPA CATALOGO</a>
                </li> */}
              </ul>
            </div>
          </div>
          {/* <div className="menu-item has-submenu">
            <a
              // href="#!"
              className={PARTNER.includes(pathname) ? "collapsed" : ""}
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              PARTNER
            </a>
            <div
              id="collapseThree"
              className={`collapse ${PARTNER.includes(pathname) ? "show" : ""}`}
              data-bs-parent="#accordionMenu"
            >
              <ul className="list-unstyled mb-0">
                <li
                  className={
                    pathname === "/fornitori" ||
                    pathname === "/addfornitori" ||
                    pathname === "/fornitori/edit" ||
                    pathname === "/fornitori/contatti/editcontatti" ||
                    pathname === "/fornitori/contatti/editcontatti"
                      ? "active"
                      : ""
                  }
                >
                  <a href="/fornitori">FORNITORI</a>
                </li>
                <li
                  className={
                    pathname === "/agents" ||
                    pathname === "/agents/edit" ||
                    pathname === "/agents/add" ||
                    pathname === "/agents/contatti/addcontatti" ||
                    pathname === "/agents/contatti/editcontatti"
                      ? "active"
                      : ""
                  }
                >
                  <a href="/agents">AGENTI</a>
                </li>
              </ul>
            </div>
          </div> */}
          {/* <div className="menu-item has-submenu">
            <a
              // href="#!"
              className="collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              TABELLE DI BASE
            </a>
            <div
              id="collapseFour"
              className={`collapse ${TABLE.includes(pathname) ? "show" : ""}`}
              data-bs-parent="#accordionMenu"
            >
              <ul className="list-unstyled mb-0">
                <li className={pathname === "/gruppiprodotto" ? "active" : ""}>
                  <a href="/gruppiprodotto">Gruppo prodotti</a>
                </li>
                <li className={pathname === "/categorie" ? "active" : ""}>
                  <a href="/categorie">Categorie merceologiche</a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="userinfo">
          <span className="border-bottom">
            <i className="fas fa-user-alt me-2" />
            {username}
          </span>
          <span>{dateTime}</span>
        </div>
      </div>
    </>
  );
}

export default AmministrazioneSideBar;
