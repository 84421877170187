import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import { API } from "./constant/api";
import Loading from "./component/Loading/Loading";
import { authSuccess, logout } from "./store/auth/action";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/DashBoard";
import Clienti from "./pages/clienti/Clienti";
import AddClienti from "./pages/clienti/Add-Clienti";
import EditClienti from "./pages/clienti/EditClienti";
import Catalogo from "./pages/catalogo/Catalogo/Catalogo";
import AddCatalogo from "./pages/catalogo/Catalogo/AddCatalogo";
import EditCatalogo from "./pages/catalogo/Catalogo/EditCatalog";
import AggiornaCatalogo from "./pages/catalogo/AggiornaCatalogo";
import Articoli from "./pages/catalogo/articoli/Articoli";
import Listini from "./pages/catalogo/Listini/Listini";
import StampaCatalogo from "./pages/catalogo/stampaCatalogo/StampaCatalogo";
import StampaCatalogofileview from "./pages/catalogo/stampaCatalogo/stampaCatalogoFIle";
import AddListini from "./pages/catalogo/Listini/AddListini";
import EditListini from "./pages/catalogo/Listini/EditListini";
import AddArticoli from "./pages/catalogo/articoli/AddArticoli";
import EditArticoli from "./pages/catalogo/articoli/EditArticoli";
import Laboratorio from "./pages/laboratorio/laboratorio";
import PuntoVendita from "./pages/PuntoVendita/PuntoVendita";
import EditPuntoVendita from "./pages/PuntoVendita/EditPuntoVendita";
import PuntoVenditacontatti from "./pages/PuntoVendita/AddContatti";
import PuntoVenditacontattiEdit from "./pages/PuntoVendita/EditContatti"
import AddPuntoVendita from "./pages/PuntoVendita/AddPuntoVendita";
import AddContatti from "./pages/clienti/Contatti/AddContatti";
import EditContatti from "./pages/clienti/Contatti/EditContatti";
import Fornitori from "./pages/partner/Fornitori";
import AddFornitori from "./pages/partner/AddFornitori";
import Editfornitori from "./pages/partner/Editfornitori";
import AddfornitoriContatti from "./pages/partner/contatti/AddfornitoriContatti";
import Editscontatti from "./pages/partner/contatti/Editscontatti";
import Agents from "./pages/partner/agents/Agents";
import AddAgents from "./pages/partner/agents/AddAgents";
import EditAgents from "./pages/partner/agents/EditAgents";
import AddAgentsContatti from "./pages/partner/agents/contatti/Addcontatti";
import EditAgentContatti from "./pages/partner/agents/contatti/EditAgentContatti";
import ProductGroup from "./pages/Tabellebase/ProductGroup";
import Categorie from "./pages/Tabellebase/Categorie";
import Riordino from "./pages/Magazzino/Acquisti/Riordino";
import AddRiordino from "./pages/Magazzino/Acquisti/AddRiordino";
import Ordinidivendita from  "./pages/Amministrazione/CiclAttivo/Ordinidivendita"
import Ordinidivenditalist from  "./pages/Amministrazione/CiclAttivo/Ordinidivenditalist"
import Ordinidivenditalist2 from  "./pages/Amministrazione/CiclAttivo/Ordinidivenditalist2"
import Documentiattivi from  "./pages/Amministrazione/CiclAttivo/Activedocuments/Documentiattivi"
import AddDocumentiattivi from  "./pages/Amministrazione/CiclAttivo/Activedocuments/AddDocumentiattivi"
import EditDocumentiattivi from  "./pages/Amministrazione/CiclAttivo/Activedocuments/EditDocumentiattivi"
import DocumentoPassivo from  "./pages/Amministrazione/cicloPassio/documentoPassivo"
import DocumentoPassivoView from  "./pages/Amministrazione/cicloPassio/DocumentipassiviView"
import DocumentoPassivoEdit from  "./pages/Amministrazione/cicloPassio/DocumentipassiviEdit"





// import Template6 from './pages/Template6';
const BACKEND_URL = API.PORT;
function App() {
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();
  const {
    loggedIn,
    loading,
    token: reduxToken,
    userRoleId,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(authSuccess({ loggedIn: false }));
    } else {
      setToken(token);
    }
  }, []);

  useEffect(() => {
    const url = `${BACKEND_URL}/user-data`;
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": "it",
        },
      };
      axios
        .get(url, config)
        .then((res) => {
          let userResponse = res.data;
          if (userResponse.status === "FAIL") {
            dispatch(logout());
          } else {
            dispatch(
              authSuccess({
                loggedIn: true,
                token,
                userId: userResponse.data.id_user,
                username: userResponse.data.username,
                warehouse_code:userResponse.data.department.warehouse.warehouse_code,
                email: userResponse.data.email,
                userRoleId: userResponse.data.id_role,
                role: userResponse.data.role,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(logout());
        });
    }
  }, [token]);

  return (
    <>
    {console.log(loading)}
      {loading && <Loading />}
      {loggedIn !== null && (
        <>
          {loggedIn ? (
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/clienti" element={<Clienti />} />
              <Route path="/addClienti" element={<AddClienti />} />
              <Route path="/contatti/add" element={<AddContatti />} />
              <Route path="/contatti/edit/:id" element={<EditContatti />} />
              <Route path="/puntovendita" element={<PuntoVendita />} />
              <Route path="/puntovendita/add" element={<AddPuntoVendita />} />
              <Route
                path="/puntovendita/edit/:id"
                element={<EditPuntoVendita />}
              />
                 <Route
                path="/puntovendita/contatti/add"
                element={<PuntoVenditacontatti />}
              />
                 <Route
                path="/puntovendita/contatti/edit"
                element={<PuntoVenditacontattiEdit />}
              />
              <Route path="/catalogo" element={<Catalogo />} />
              <Route path="/catalogo/add" element={<AddCatalogo />} />
              <Route path="/catalogo/edit" element={<EditCatalogo />} />
              <Route path="/aggiornacatalogo" element={<AggiornaCatalogo />} />
              <Route path="/articoli" element={<Articoli />} />
              <Route path="/articoli/add" element={<AddArticoli />} />

              <Route path="/articoli/edit" element={<EditArticoli />} />
              <Route path="/listini" element={<Listini />} />
              <Route path="/listini/add" element={<AddListini />} />
              <Route path="/listini/edit" element={<EditListini />} />
              <Route path="/stampaCatalogo" element={<StampaCatalogo />} />
              <Route
                path="stampacatalogofile"
                element={<StampaCatalogofileview />}
              />
              <Route path="/laboratorio" element={<Laboratorio />} />
              <Route path="/editClienti" element={<EditClienti />} />
              <Route path="/fornitori" element={<Fornitori />} />
              <Route path="/addfornitori" element={<AddFornitori />} />
              <Route path="/fornitori/edit" element={<Editfornitori />} />
              <Route
                path="/fornitori/contatti/addcontatti"
                element={<AddfornitoriContatti />}
              />
              <Route
                path="/fornitori/contatti/editcontatti"
                element={<Editscontatti />}
              />
              <Route path="/agents" element={<Agents />} />
              <Route path="/agents/add" element={<AddAgents />} />
              <Route path="/agents/edit" element={<EditAgents />} />
              <Route
                path="/agents/contatti/addcontatti"
                element={<AddAgentsContatti />}
              />
              <Route
                path="/agents/contatti/editcontatti"
                element={<EditAgentContatti />}
              />
              <Route path="/gruppiprodotto" element={<ProductGroup />} />
              <Route path="/categorie" element={<Categorie />} />
              <Route path="/riordino" element={<Riordino />} />
              <Route path="/riordino/add" element={<AddRiordino />} />
              <Route path="/ordinidivendita" element={<Ordinidivendita />} />
              <Route path="/ordinidivenditaelenco" element={<Ordinidivenditalist />} />
              <Route path="/ordinidivenditaelenco2" element={<Ordinidivenditalist2 />} />
              <Route path="/documentiattivi" element={<Documentiattivi />} />
              <Route path="/documentiattiviadd" element={<AddDocumentiattivi />} />
              <Route path="/documentiattiviedit" element={<EditDocumentiattivi />} />
              <Route path="/documentopassivo/add" element={<DocumentoPassivo />} />
              <Route path="/documentopassivo" element={<DocumentoPassivoView />} />
              <Route path="/documentopassivo/Edit" element={<DocumentoPassivoEdit />} />


            </Routes>
          ) : (
            <Routes>
              <Route path="/login" element={<Login />} />
              {/* <Navigate replace to={"/login"} /> */}
              <Route path="*" element={<Navigate replace to="/login" />} />
            </Routes>
          )}

          <ToastContainer />
        </>
      )}
    </>
  );
}

export default App;
